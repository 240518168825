<template>
  <div class="content">
    <h3>{{ $t('titlePages.listOfWebServices') }}</h3>
    <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
      <base-button
        @click="AddWSShow = true"
        class="btn btn-round btn-just-icon btn-market"
      >{{ $t('buttons.createAWebService') }}</base-button>
    </div>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch2').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table :data="searchWS.slice(from, to)"  row-class-name="booking-table-rows" v-loading="$apollo.queries.clientsOgoDATA.loading">
        <el-table-column
          prop='pk_ws'
          label="REF"
          minWidth='90'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='designation'
          :label="$t('table.columns.Product')"
          minWidth='90'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            <i :class="icons[scope.row.designation.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.designation.replace(/Ã©/g,'é').toUpperCase()"></i>
          </template>
        </el-table-column>

        <el-table-column
          prop='societe'
          :label="$t('table.columns.Societe')"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='date_creation'
          :label="$t('table.columns.Creationdate')"
          minWidth='155'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='msg_retour'
          :label="$t('table.columns.returnMessage')"
          minWidth='170'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='crm'
          label="crm"
          minWidth='100'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='etat'
          :label="$t('table.columns.State')"
          minWidth='90'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            <el-tooltip
              v-if="scope.row.etat === 'valide'"
              :content="$t('labels.pauseTheWebService')"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click="ChangerStatutWS('pause', scope.row)"
                class="btn-link"
                type="success"
                size="sm"
                icon
              >
                <i class="fas fa-check icon-large"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              v-else
              :content="$t('labels.validateTheWebService')"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click="ChangerStatutWS('valide', scope.row)"
                class="btn-link"
                type="warning"
                size="sm"
                icon
              >
                <i class="fas fa-pause"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('labels.testTheWebService')"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click="TryWS(scope.row.pk_ws)"
                class="btn-link"
                type="info"
                size="sm"
                icon
                :disabled="(scope.row.crm != 'ernest') ? !scope.row.cons_key || !scope.row.cons_secret : !scope.row.cons_key"
              >
                <i class="fas fas fa-envelope icon-large"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('labels.changeTheWebService')"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click="prepareEditWS(scope.row)"
                class="btn-link"
                type="warning"
                size="sm"
                icon
              >
                <i class="fas fa-edit icon-large"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
    <!-- ADD WS modal -->
    <modal
      :show.sync="AddWSShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="AddWSShow = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
          
        </template>
        <h3 slot="header" class="modal-title">
          {{ $t('labels.addAWebService') }}
        </h3>

        <div class="col-sm-12">
          <base-input label="CRM" :error="getError('CRM')">
            <el-select class="select-primary"
              placeholder="CRM"
              filterable
              v-model="CrmSelects.Crm"
              name="CRM"
              v-validate="'required'">
              <el-option 
                v-for="option in CrmSelects.CrmList"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-sm-12">
          <base-input :label="$t('table.columns.Societe')" :error="getError($t('table.columns.Societe'))">
            <el-select class="select-primary"
              :placeholder="$t('table.columns.Societe').toUpperCase()"
              filterable
              v-model="SocieteSelects.Societe"
              :name="$t('table.columns.Societe')"
              v-validate="'required'">
              <el-option 
                v-for="option in SocieteSelects.SocieteList"
                class="select-primary"
                :value="option.fk_annonceur"
                :label="(option.societe.length > 30) ? option.societe.slice(0, 30).toUpperCase() +'...' : option.societe.toUpperCase()"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <base-input :label="$t('table.columns.Product')" class="col-sm-12">
          <el-select filterable class="select-primary"
            v-model="CategorieSelects.Categorie"
            :placeholder="$t('table.columns.Product').toUpperCase()">
            <el-option 
              v-for="option in CategorieSelects.ListCategorie"
              class="select-primary"
              :value="option.pk_categorie"
              :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
              :key="option.pk_categorie">
            </el-option>
          </el-select>
        </base-input>

        <div class="col-sm-12">
          <base-input :label="$t('table.columns.consumerKey')" v-model="cons_key"
            :name="$t('table.columns.consumerKey')"
            v-validate="'required'"
          :error="getError($t('table.columns.consumerKey'))"></base-input>
        </div>

        <div class="col-sm-12" v-if="CrmSelects.Crm != 'ernest'">
          <base-input :label="$t('table.columns.consumerSecret')" v-model="cons_secret"
            :name="$t('table.columns.consumerSecret')"
            v-validate="CrmSelects.Crm != 'ernest' ? 'required' : ''"
          :error="getError($t('table.columns.consumerSecret'))"></base-input>
        </div>

        <div class="col-sm-12" v-if="CrmSelects.Crm == 'ecilia'">
          <base-input :label="$t('table.columns.database')" v-model="database"
            :name="$t('table.columns.database')"
            v-validate="'required'"
          :error="getError($t('table.columns.database'))"></base-input>
        </div>

        <div  class="text-center">
          <base-button
            type="info"
            @click.native="addWS"
            >{{ $t('buttons.BtnAdd').toUpperCase() }}
          </base-button>
        </div>

      </card>
    </modal>
    <!-- ADD WS modal -->

    <!-- Edit WS modal -->
    <modal
      :show.sync="EditWSShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="EditWSShow = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
          
        </template>
        <h3 slot="header" class="modal-title">
          {{ $t('labels.editAWebService') }}
        </h3>
        <form @submit.prevent data-vv-scope="ScopeEdit">

          <div class="col-sm-12">
            <base-input label="CRM" :error="getError('ScopeEdit.CRM')">
              <el-select class="select-primary"
                placeholder="CRM"
                filterable
                v-model="WSEdited.crm"
                name="CRM"
                v-validate="'required'">
                <el-option 
                  v-for="option in CrmSelects.CrmList"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-sm-12">
            <base-input :label="$t('table.columns.Societe')" :error="getError('ScopeEdit.'+$t('table.columns.Societe'))">
              <el-select class="select-primary"
                :placeholder="$t('table.columns.Societe').toUpperCase()"
                filterable
                v-model="WSEdited.Society"
                :name="$t('table.columns.Societe')"
                v-validate="'required'"
                disabled>
                <el-option 
                  v-for="option in clients"
                  class="select-primary text-uppercase"
                  :value="option.fk_annonceur"
                  :label="(option.societe.length > 30) ? option.societe.slice(0, 30).toUpperCase()+'...' : option.societe.toUpperCase()"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <base-input :label="$t('table.columns.Product')" class="col-sm-12" :error="getError('ScopeEdit.' + $t('table.columns.Product'))">
            <el-select filterable class="select-primary"
              v-model="WSEdited.Product"
              :placeholder="$t('table.columns.Product').toUpperCase()"
              :name="$t('table.columns.Product')"
              v-validate="'required'"
              disabled>
              <el-option 
                v-for="option in CategorieSelects.ListCategorie"
                class="select-primary text-uppercase"
                :value="option.pk_categorie"
                :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
                :key="option.pk_categorie">
              </el-option>
            </el-select>
          </base-input>

          <div class="col-sm-12">
            <base-input :label="$t('table.columns.consumerKey')" v-model="WSEdited.cons_key"
              :name="$t('table.columns.consumerKey')"
              v-validate="'required'"
            :error="getError('ScopeEdit.' + $t('table.columns.consumerKey'))"></base-input>
          </div>

          <div class="col-sm-12" v-if="WSEdited.crm != 'ernest'">
            <base-input :label="$t('table.columns.consumerSecret')" v-model="WSEdited.cons_secret"
              :name="$t('table.columns.consumerSecret')"
              v-validate="WSEdited.crm != 'ernest' ? 'required' : ''"
            :error="getError('ScopeEdit.' + $t('table.columns.consumerSecret'))"></base-input>
          </div>

          <div class="col-sm-12" v-if="WSEdited.crm == 'ecilia'">
            <base-input :label="$t('table.columns.database')" v-model="WSEdited.database"
              :name="$t('table.columns.database')"
              v-validate="'required'"
            :error="getError('ScopeEdit.' + $t('table.columns.database'))"></base-input>
          </div>

          <div class="text-center">
            <base-button
              native-type="submit"
              type="info"
              @click.native="EditWS"
              >{{ $t('buttons.BtnModify').toUpperCase() }}
            </base-button>
          </div>
        </form>
      </card>
    </modal>
    <!-- Edit WS modal -->
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, } from 'element-ui'
import { BasePagination, Modal } from 'src/components';
import gql from 'graphql-tag'
import Fuse from 'fuse.js';
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";
import swal from 'sweetalert2'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    Modal
  },
  apollo: {
    clientsOgoDATA: {
      client: 'MARKET',
      query: gql` query {
        clientsOgoDATA {
          pk_ws
          fk_annonceur
          fk_categorie
          url
          mapping_pack
          params
          params_qq
          format_date
          val_civilite
          msg_retour
          date_creation
          date_modification
          societe
          etat
          designation
          cons_key
          cons_secret
          crm
          database
        }
      }`,
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        this.listWS = this.searchWS = data.clientsOgoDATA
        this.fuseSearch = new Fuse(this.listWS, this.options);
      }
    },
    produits: {
      client: 'MARKET',
      query: gql` query {
        produits {
          pk_categorie
          designation
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.CategorieSelects.ListCategorie = data.produits
      },
    },
    clients: {
      client: 'MARKET',
      query: gql` query {
        clients {
          fk_annonceur
          societe
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.SocieteSelects.SocieteList = data.clients
      }
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.listWS;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchWS = result;
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchWS.length
    }
  },
  data() {
    return {
      icons: Icons,
      AddWSShow: false,
      EditWSShow: false,
      searchQuery: null,
      fuseSearch: null,
      searchWS: [],
      listWS: [],
      SocieteSelects: {
        Societe: '',
        SocieteList: []
      },
      CategorieSelects: {
        Categorie: 1,
        ListCategorie: []
      },
      CrmSelects: {
        Crm: "oggodata",
        CrmList: [
          { label: "OGGODATA", value: "oggodata" },
          { label: "ECILIA", value: "ecilia" },
          { label: "COURTIGO", value: "courtigo" },
          { label: "ERNEST", value: "ernest" },
          { label: "BUBBLEIN", value: "bubblein" }
        ]
      },
      database: "",
      cons_key: '',
      cons_secret: '',
      format_date: '',
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
      WSEdited: {
        ws_id: '',
        Society: '',
        Product: '',
        cons_key: '',
        cons_secret: '',
        crm: '',
        database: ''
      },
      options : {
        threshold: 0.2,
        keys: [
          "pk_ws",
          "date_creation",
          "societe",
          "etat",
          "msg_retour",
          "crm"
        ]
      },
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async addWS() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      
      this.$apollo.mutate({
        client: 'MARKET',
        mutation: gql`mutation ($client_id: ID!, $categorie_id: ID!, $cons_key: String!,$cons_secret: String,$crm: String!,$database: String) {
          generateWSClient(client_id: $client_id, categorie_id: $categorie_id, cons_key: $cons_key,cons_secret: $cons_secret,crm: $crm,database: $database) {
            state
          }
        }`,
        variables: {
          client_id: this.SocieteSelects.Societe,
          categorie_id: this.CategorieSelects.Categorie,
          cons_key: this.cons_key,
          cons_secret: this.cons_secret,
          crm: this.CrmSelects.Crm,
          database: this.database
        },
      }).then(response => {
        if (response.data.generateWSClient.state === "WS_EXIST") {
          this.$notify({type: 'warning', duration: 5000, message: "web service exist deja" })
        }
        else if (response.data.generateWSClient.state === "CLIENT_NOT_EXIST") {
          this.$notify({type: 'warning', duration: 5000, message: "client n'exist pas" })
        }
        else if (response.data.generateWSClient.state === "WS_CREATED") {
          this.$apollo.queries.clientsOgoDATA.refetch({})
          this.$notify({type: 'success', duration: 5000, message: "web service créé avec succès" })
          this.clearFiled()
          this.AddWSShow = false
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        }
                      
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
      })

    },
    clean(string) {
      string = string.replace(/' '/g, '_');
      string = string.replace(/'-'/g, '_');
      string = string.replace(/[^A-Za-z0-9\-]/g, '');
      return string.toLowerCase();
    },
    clearFiled() {
      this.SocieteSelects.Societe = ''
      this.cons_key = ''
      this.cons_secret = ''
    },
    ChangerStatutWS(status, row) {
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: this.$t("labels.youAreChangingTheState"),
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation (
              $ws_id: ID!,
              $etat: String!
            ) {
              changeEtatWSClient(
                ws_id: $ws_id,
                etat: $etat
              ){
                state
              }
            }`,
            variables: {
              ws_id: row.pk_ws,
              etat: status
            },
          }).then(response => {
            if (response.data.changeEtatWSClient.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              row.etat = status
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    async TryWS(ws_id) {
      await this.$apollo.mutate({
        client: 'MARKET',
        fetchPolicy: 'no-cache',
        mutation: gql`mutation ($ws_id: ID!) {
          testeWSClient(ws_id: $ws_id) {
            state
          }
        }`,
        variables: {
          ws_id: ws_id
        },
      }).then(response => {
        if (response.data.testeWSClient.state) {
          this.$notify({type: 'success', message: 'test réussi' })
        }
        else {
          this.$notify({type: 'warning', message: "La clé ou le secret du consommateur n'est pas valide" })
        }
      }).catch((error) => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
      })

    },
    async prepareEditWS(row) {
      await this.$apollo.query({
        client: 'MARKET',
        fetchPolicy: 'no-cache',
        query: gql`query ($ws_id: ID) {
          getWSClient (ws_id: $ws_id) {
            pk_ws
            fk_categorie
            cons_key
            cons_secret
          }
        }`,
        variables: {
          ws_id: row.pk_ws
        },
      }).then(response => {
        this.EditWSShow = true
        this.WSEdited.ws_id = row.pk_ws
        this.WSEdited.Society = row.fk_annonceur
        this.WSEdited.crm = row.crm
        this.WSEdited.database = row.database
        this.WSEdited.Product = parseInt(response.data.getWSClient.fk_categorie)
        this.WSEdited.cons_key = response.data.getWSClient.cons_key
        this.WSEdited.cons_secret = response.data.getWSClient.cons_secret
      }).catch((error) => {
        console.error(error)
      })
    },
    async EditWS() {
      let validate
      await this.$validator.validateAll("ScopeEdit").then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      this.$apollo.mutate({
        client: 'MARKET',
        mutation: gql`mutation ($ws_id: ID!, $categorie_id: ID!, $cons_key: String!,$cons_secret: String,$crm: String!,$database: String) {
          updateWSClient(ws_id: $ws_id, categorie_id: $categorie_id, cons_key: $cons_key,cons_secret: $cons_secret,crm: $crm,database: $database) {
            state
          }
        }`,
        variables: {
          ws_id: this.WSEdited.ws_id,
          categorie_id: this.WSEdited.Product,
          cons_key: this.WSEdited.cons_key,
          cons_secret: this.WSEdited.cons_secret,
          crm: this.WSEdited.crm,
          database: this.WSEdited.database
        },
      }).then(response => {
        if (response.data.updateWSClient.state) {
          this.$apollo.queries.clientsOgoDATA.refetch({})
          this.$notify({type: 'success', duration: 5000, message: "web service modifié avec succès" })
          this.clearEditFiled()
          this.EditWSShow = false
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        }
                      
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
      })
    },
    clearEditFiled() {
      this.WSEdited.ws_id = ''
      this.WSEdited.Society = ''
      this.WSEdited.Product = ''
      this.WSEdited.cons_key = ''
      this.WSEdited.cons_secret = ''
      this.WSEdited.database = ''
    }
  },
}

</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .iconoggodata {
    background: url('/img/oggodata.ico');
    height: 40px;
    width: 40px;
    display: block;
    /* Other styles here */
  }
</style>