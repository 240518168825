<template>
  <div class="content">
    <h3>PARAMÈTRES CATEGORIE (EDITION)</h3>
    <card>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <base-input
            name="Catégorie"
            v-validate="'required'"
            label="Catégorie"
            class="col-10"
            v-model="nameCategorie"
            :error="getError('Catégorie')">
          </base-input>
          <base-input label="Statut des ventes" class="col-10">
            <el-select class="select-primary"
              name="Statut des ventes"
              v-validate="'required'"
              :error="getError('Statut des ventes')"
              v-model="Statut">
              <el-option class="select-primary" value="active" label="ACTIVÉ"></el-option>
              <el-option class="select-primary" value="pause" label="PAUSE"></el-option>
            </el-select>
          </base-input>
          <base-input
            label="Copier les paramètres depuis"
            class="col-10">
            <el-select class="select-primary"
              v-model="CopieFromCat">
              <el-option 
                v-for="option in Categories"
                class="select-primary text-uppercase" 
                :value="option.pk_categorie" 
                :label="option.designation.replace(/Ã©/g,'é')"
                :key="option.pk_categorie"
              ></el-option>
            </el-select>
          </base-input>
          <label>CHAMPS À COLLECTER </label>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
              <base-checkbox v-for="field in collected_fields" v-bind:key="field.id" v-bind:id="field.id"
                inline class="mb-3 col-md-5" v-model="field.checked" :value="field.value">{{ field.name }}
              </base-checkbox>
            </div>
            </div>
          </div>
          <label>AJOUTER DE NOUVEAUX CHAMPS POUR CETTE CATÉGORIE </label>
          <div class="row">
            <label class="col-5">CHAMPS</label>
            <label class="col-5">MAPPING</label>
          </div>
          <div v-for="(f, index) in New_fields" class="row">
            <base-input
              class="col-5"
              v-model="f.champs">
            </base-input>
            <base-input
              class="col-5"
              v-model="f.mapping">
            </base-input>
            <base-button
              v-if="index === 0"
              @click.native="New_fields.push({ champs: '', mapping: '' ,pk_qq_template: 0})"
              class="col-sm-1 edit btn-link"
              type="success"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
            <base-button
              v-else
              @click.native="New_fields.splice(index, 1);"
              class="col-sm-1 edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="card" v-for="pack in Packs">
            <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
              PARAMÈTRES DES LEADS {{ Pack_name[pack.fk_pack - 1] }}
            </span><br>
            <!-- :name="'CPL de base' + Pack_name[pack.fk_pack]" -->
            <base-input
              :name="'CPL de base ' + Pack_name[pack.fk_pack - 1]"
              v-validate="'required'"
              :error="getError('CPL de base ' + Pack_name[pack.fk_pack - 1])"
              :label="'CPL de base ' + Pack_name[pack.fk_pack - 1]"
              v-model="pack.cpl_base"
              type="number"
            >
            </base-input>
            <base-input
              label="CPL de promo"
              v-model="pack.cpl_promo"
              type="number"
            >
            </base-input>
          </div>
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
              PARAMÈTRES DE DESTOCKAGE
            </span><br>
            <base-input
              label="Prix"
              type="number"
              v-model="Prix_destockage"
            >
            </base-input>
          </div>
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
              MONTANT MINIMUM PROMO
            </span><br>
            <base-input
              type="number"
              v-model="Montant_minimum_promo"
            >
            </base-input>
          </div>
        </div>
      </div>
      <center>
        <base-button
          type="market"
          @click="updateCategory"
          :disabled="loading"
        >
          <i class="fa fa-check" ></i>SAUVEGARDER
        </base-button>
      </center>
    </card>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import gql from 'graphql-tag'

export default {
  apollo: {
    editCategory: {
      client: 'MARKET',
      query: gql` query ($id_category: ID) {
        editCategory (id_category: $id_category) {
          packs {
            pk_categorie_pack
            fk_categorie
            fk_pack
            cpl_base
            cpl_promo
          }
          categorie {
            pk_categorie
            designation
            fk_template
            statut
            geolocalisation
            cout_gold_sup
            etat
            id_ad
            prix_destockage
            montant_minimum_promo
            template {
              pk_template
              civilite
              nom
              prenom
              cp
              telephone
              ville
              departement
              email
              date_naissance
              adresse
              ip
            }
          }
          inputs {
            pk_qq_template
            fk_categorie
            champs
            mapping
            filtre
            remarque_filtre
            type
            etat
          }
          categories {
            pk_categorie
            designation
            fk_template
            statut
            geolocalisation
            cout_gold_sup
            etat
            id_ad
            prix_destockage
            montant_minimum_promo
          }
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.Packs = data.editCategory.packs
        this.New_fields = data.editCategory.inputs
        this.Categories = data.editCategory.categories
        this.nameCategorie = data.editCategory.categorie.designation
        this.Prix_destockage = data.editCategory.categorie.prix_destockage
        this.Montant_minimum_promo = data.editCategory.categorie.montant_minimum_promo
        this.Geolocalisation = data.editCategory.categorie.geolocalisation
        this.Statut = data.editCategory.categorie.statut
        this.Set_CollectedFields(data.editCategory.categorie.template)
      },
      variables () {
        return {
          id_category: this.$route.params.categorie_id
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      Pack_name: ["GOLD", "SILVER", "BRONZE"],
      Statut: 'active',
      collected_fields: [
        { value: 'civilite', name: 'CIVILITÉ', checked: false },
        { value: 'nom', name: 'NOM', checked: false },
        { value: 'prenom', name: 'PRÉNOM', checked: false },
        { value: 'email', name: 'EMAIL', checked: false },
        { value: 'telephone', name: 'TÉLÉPHONE', checked: false },
        { value: 'cp', name: 'CODE POSTAL', checked: false },
        { value: 'ville', name: 'VILLE', checked: false },
        { value: 'date_naissance', name: 'DATE NAISSANCE', checked: false },
        { value: 'adresse', name: 'ADRESSE', checked: false },
        { value: 'departement', name: 'DÉPARTEMENT', checked: false },
        { value: 'ip', name: 'IP', checked: false }
      ],
      New_fields: [],
      Packs: [],
      Categories: [],
      CopieFromCat: null,
      nameCategorie: '',
      Prix_destockage: null,
      Montant_minimum_promo: null,
      Geolocalisation: null,
      loading: false
    }
  },
  methods: {
    async updateCategory() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.loading = true

      let champs = this.New_fields.map(a => a.champs)
      let mappings = this.New_fields.map(a => a.mapping)
      let pk_qq_template = this.New_fields.map(a => a.pk_qq_template)
      let new_inputs = ""
      champs.forEach((c, index) => {
        if (c && mappings[index])
          new_inputs += pk_qq_template[index] + "||" + c + "||" + mappings[index] + ";"
      });
      let packs = ""
      this.Packs.forEach(pack => {
        packs +=  pack.fk_pack + "||" + (pack.cpl_base || 0) + "||" + (pack.cpl_promo || 0) + ";"
      });
      await this.$apollo.mutate({
        client: 'MARKET',
        mutation: require("src/graphql/leadmarket/mutation/updateCategory.gql"),
        variables: {
          id_category: this.$route.params.categorie_id,
          designation: this.nameCategorie,
          statut: this.Statut,
          geolocalisation: this.Geolocalisation,
          prix_destockage: this.Prix_destockage,
          montant_minimum_promo: this.Montant_minimum_promo,
          new_inputs: new_inputs.slice(0, -1),
          packs: packs.slice(0, -1),
          civilite: this.collected_fields.find(f => f.value === 'civilite').checked,
          nom: this.collected_fields.find(f => f.value === 'nom').checked,
          cp: this.collected_fields.find(f => f.value === 'cp').checked,
          telephone: this.collected_fields.find(f => f.value === 'telephone').checked,
          ville: this.collected_fields.find(f => f.value === 'ville').checked,
          departement: this.collected_fields.find(f => f.value === 'departement').checked,
          email: this.collected_fields.find(f => f.value === 'email').checked,
          date_naissance: this.collected_fields.find(f => f.value === 'date_naissance').checked,
          adresse: this.collected_fields.find(f => f.value === 'adresse').checked,
          prenom: this.collected_fields.find(f => f.value === 'prenom').checked,
          ip: this.collected_fields.find(f => f.value === 'ip').checked
        },
      }).then(response => {
        this.$notify({type: 'success', message: "categorie modifié avec succès" })
        this.loading = false
        this.$router.push({ name: 'Categorie LeadMarket Admin' })
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        this.loading = false
      })

    },
    Set_CollectedFields(template) {
      if (template) {
        if (template.civilite === true) {
          this.collected_fields.find(f => f.value === 'civilite').checked = true
        }
        if (template.nom === true) {
          this.collected_fields.find(f => f.value === 'nom').checked = true
        }
        if (template.prenom === true) {
          this.collected_fields.find(f => f.value === 'prenom').checked = true
        }
        if (template.cp === true) {
          this.collected_fields.find(f => f.value === 'cp').checked = true
        }
        if (template.telephone === true) {
          this.collected_fields.find(f => f.value === 'telephone').checked = true
        }
        if (template.ville === true) {
          this.collected_fields.find(f => f.value === 'ville').checked = true
        }
        if (template.departement === true) {
          this.collected_fields.find(f => f.value === 'departement').checked = true
        }
        if (template.email === true) {
          this.collected_fields.find(f => f.value === 'email').checked = true
        }
        if (template.date_naissance === true) {
          this.collected_fields.find(f => f.value === 'date_naissance').checked = true
        }
        if (template.adresse === true) {
          this.collected_fields.find(f => f.value === 'adresse').checked = true
        }
        if (template.ip === true) {
          this.collected_fields.find(f => f.value === 'ip').checked = true
        }
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    }
  },
}
</script>