<template>
  <div class="content">
    <h3 class="text-uppercase" v-if="Commande">Détails de la commande "{{ Commande.societe }}" / {{ Commande.date_commande }}</h3>
    <card>
      <div class="row">
        <base-input class="col-md-4">
          <el-date-picker v-model="StartDate" :disabled="$apollo.queries.showDetailsCommande.loading" value-format="yyyy-MM-dd" type="date" placeholder="DATE DÉBUT"></el-date-picker>
        </base-input>
        <base-input class="col-md-4">
          <el-date-picker v-model="EndnDate" :disabled="$apollo.queries.showDetailsCommande.loading" value-format="yyyy-MM-dd" type="date" placeholder="DATE FIN"></el-date-picker>
        </base-input>
      </div>
      <div class="col-xs-4 text-right">
        <base-button 
          v-if="$apollo.queries.showDetailsCommande.loading" 
          loading 
          class="btn btn-default" 
        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
        <button 
          v-else
          class="btn btn-default" 
          @click="search"
        >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
      </div>
    </card>
    <card>
      <el-table :data="details" v-loading="$apollo.queries.showDetailsCommande.loading" row-class-name="booking-table-rows" class="customtable_cmdD">
        <el-table-column
          prop='date'
          label='Date'
          minWidth='100'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='last_time'
          label='HEURE DE DERNIÈRE LIVRAISON'
          minWidth='140'
          :render-header="renderHeaderMethod"
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='produit'
          label='Produit'
          minWidth='110'
          show-overflow-tooltip
        >
        <template scope="scope">
          <i :class="icons[scope.row.produit.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.produit.replace(/Ã©/g,'é').toUpperCase()"></i>
        </template>
        </el-table-column>
        <el-table-column
          prop='gamme'
          label='Gamme'
          minWidth='105'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='leads_nbr'
          label='NOMBRE DE LEAD'
          minWidth='145'
          show-overflow-tooltip
          :render-header="renderHeaderMethod"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='cmd_promo'
          label='CA RÉALISÉ BRUT (€ HT)'
          minWidth='135'
          show-overflow-tooltip
          :render-header="renderHeaderMethod"
        >
          <template scope="scope">
            <template v-if="Commande">
              <template v-if="Commande.fk_annonceur == 62">
                {{ formatThounsends((scope.row.prix_unitaire * scope.row.leads_nbr) -  ((scope.row.prix_unitaire * scope.row.leads_nbr) * 0.1), true, 2) }}
              </template>
              <template v-else>
                {{ formatThounsends(scope.row.prix_unitaire * scope.row.leads_nbr, true, 2) }}
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='cmd_promo'
          label='CA RÉALISÉ NET (€ HT)'
          minWidth='135'
          show-overflow-tooltip
          :render-header="renderHeaderMethod"
        >
          <template scope="scope">
            <template v-if="Commande">
              {{ formatThounsends(getCa_net(scope.row), true, 2) }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui'
import { DatePicker } from 'element-ui'
import gql from 'graphql-tag'
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  apollo: {
    showDetailsCommande: {
      client: 'MARKET',
      query: gql` query ($commande_id: ID, $date_debut: String, $date_fin: String) {
        showDetailsCommande(commande_id: $commande_id, date_debut: $date_debut, date_fin: $date_fin) {
          commande {
            pk_commande
            fk_annonceur
            societe
            date_commande
          }
          details {
            leads_nbr
            prix_unitaire
            produit
            gamme
            date
            last_time
          }
          rem_ld
          dev_ld
        }
      }`,
      variables () {
        return {
          commande_id: this.$route.params.commande_id,
          date_debut: this.StartDate,
          date_fin: this.EndnDate
        }
      },
      result({ data, loader, networkStatus }) {
        this.details = data.showDetailsCommande.details
        this.Commande = data.showDetailsCommande.commande
        this.rem_ld = data.showDetailsCommande.rem_ld
        this.dev_ld = data.showDetailsCommande.dev_ld
        this.SkipQuery = true
      },
      fetchPolicy: 'network-only',
      skip () {
        return this.SkipQuery
      }
    },
  },
  data() {
    return {
      icons: Icons,
      StartDate: null,
      EndnDate: null,
      SkipQuery: true,
      details: [],
      Commande: null,
      rem_ld: 0,
      dev_ld: 0,
    }
  },
  methods: {
    search() {
      this.SkipQuery = false
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    renderHeaderMethod(h, { column, $index }) {
      return h(
        "el-popover",
        {
          props: {
            placement: "top",
            trigger: "hover",
          }
        },
        [
          column.label,
          h(
            "span",
            {
              slot: "reference",
            },
            column.label.slice(0,10)+'...'
          )
        ]
      );
    },
    getCa_net(row) {

      let ca_brut = row.prix_unitaire * row.leads_nbr
      if (this.Commande.fk_annonceur === 62) {
        ca_brut -= ca_brut * 0.1;
      }

      let ca_net = ca_brut

      if (this.Commande.fk_annonceur != 62) {
        ca_net -= this.rem_ld * row.leads_nbr;
      }
      ca_net -= this.dev_ld * row.leads_nbr;
      
      ca_net = (ca_net>0)?ca_net:0;

      return ca_net;
    }
  },
  mounted() {
    this.SkipQuery = false
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmdD .cell{
    word-break: break-word;
  }
</style>