<template>
  <div class="content">
    <h3>{{ $t("titlePages.InvalidationLeads") }}</h3>
    <card>
      <div class="row">
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="CategorieSelects.Categorie"
            :placeholder="$t('table.columns.Product').toUpperCase()"
            :disabled="$apollo.queries.leadsDevalides.loading"
            clearable>
            <el-option 
              v-for="option in CategorieSelects.ListCategorie"
              class="select-primary text-uppercase"
              :value="option.pk_categorie"
              :label="option.designation.replace(/Ã©/g,'é')"
              :key="option.pk_categorie">
            </el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="PackSelects.Pack"
            placeholder="PACK"
            :disabled="$apollo.queries.leadsDevalides.loading"
            clearable>
            <el-option 
              v-for="option in PackSelects.ListPack"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="FacturationSelects.Facturation"
            :placeholder="$t('table.columns.Billing')"
            :disabled="$apollo.queries.leadsDevalides.loading"
            clearable>
            <el-option 
              v-for="option in FacturationSelects.ListFacturation"
              class="select-primary text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="StatutSelects.Statut"
            placeholder="STATUS"
            :disabled="$apollo.queries.leadsDevalides.loading"
            clearable>
            <el-option 
              v-for="option in StatutSelects.ListStatut"
              class="select-primary text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="row">
        <base-input class="col-md-3">
          <el-date-picker v-model="StartDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()" :disabled="$apollo.queries.leadsDevalides.loading"></el-date-picker>
        </base-input>
        <base-input class="col-md-3">
          <el-date-picker v-model="EndDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()" :disabled="$apollo.queries.leadsDevalides.loading"></el-date-picker>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="ClientSelects.Client"
            placeholder="CLIENT"
            :disabled="$apollo.queries.leadsDevalides.loading"
            clearable>
            <el-option 
              v-for="option in ClientSelects.ListClient"
              class="select-primary text-uppercase"
              :value="option.fk_annonceur"
              :label="(option.societe.length > 30) ? option.societe.slice(0, 30)+'...' : option.societe"
              :key="option.id">
            </el-option>
          </el-select>
        </base-input>
        <div class="col-md-3 text-right ">
          <base-button 
            v-if="$apollo.queries.leadsDevalides.loading" 
            loading 
            class="btn btn-default" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-default" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div>
      </div>
      <div class="col-lg-12 col-12">
        <div class="row">
          <button class="btn btn-market" @click="DownloadCSV">
            <i class="tim-icons icon-cloud-download-93"></i> EXPORT CSV
          </button>
          &nbsp;
          <base-checkbox inline class="mb-2 col-sm-4" v-model="With_fields">
            {{ $t("labels.WithProductFields") }}
        </base-checkbox>
        </div>
      </div>
    </card>
    <card class="ListleadsDevalides">
      <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle" class="btn-link" type="market" size="sm" icon>
        <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
      </base-button>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch2').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <div class="row">
        &nbsp;&nbsp;&nbsp;
        <base-dropdown menu-classes="dropdown-black">
          <template slot="title-container">
            <button class="btn btn-icon btn-round btn-market">
              <i class="tim-icons el-icon-view"></i>
            </button>
          </template>
            <div class="dropdown-header">
              <base-checkbox v-model="selectallrows">
                {{ $t('table.columns.SelectAll') }}
              </base-checkbox>
            </div>
            <template v-for="row in rows">
              <div class="dropdown-header">
                <base-checkbox v-model="row.show">
                  {{ row.label }}
                </base-checkbox>
              </div>
            </template>
        </base-dropdown>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <base-button
          type='notif'
          size="sm"
          @click="ValideSelection"
        >
          <i class="far fa-check-square"></i>{{ $t('buttons.AcceptAll') }}
        </base-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <base-button
          type='danger'
          size="sm"
          @click="CancelSelection"
        >
          <i class="far fa-trash-alt"></i>{{ $t('buttons.RefuseAll') }}
        </base-button>
      </div>
      <el-table :data="searchLeads.slice(from, to)"  :default-sort = "{prop: 'date_livraison', order: 'descending'}" v-loading="$apollo.queries.leadsDevalides.loading" row-class-name="booking-table-rows" class="customtable_cmd" @selection-change="handleSelectionChange" :row-key="getRowKey">
        <el-table-column
          type="selection"
          width="55"
          :reserve-selection="true">
        </el-table-column>
        <el-table-column
          prop='date_livraison'
          :label='$t("table.columns.DeliveryDate")'
          minWidth='110'
          show-overflow-tooltip
          sortable
          v-if="rows[0].show"
        >
        </el-table-column>
        <el-table-column
          prop='pk_commande'
          :label='$t("table.columns.OrderNumber")'
          minWidth='115'
          show-overflow-tooltip
          sortable
          v-if="rows[1].show"
        >
        </el-table-column>
        <el-table-column
          prop='societe'
          label='Client'
          minWidth='140'
          show-overflow-tooltip
          sortable
          v-if="rows[2].show"
        >
        </el-table-column>
        <el-table-column
          prop='designation'
          :label='$t("table.columns.Product")'
          minWidth='100'
          show-overflow-tooltip
          sortable
          v-if="rows[3].show"
        >
          <template scope="scope">
            <i :class="icons[scope.row.designation.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.designation.replace(/Ã©/g,'é').toUpperCase()"></i>
          </template>
        </el-table-column>
        <el-table-column
          prop='nom'
          :label='$t("inputs.Lastname") +" / "+ $t("inputs.Firstname")'
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[4].show"
        >
          <template scope="scope">
            {{ scope.row.nom + ' ' + scope.row.prenom }}
          </template>
        </el-table-column>
        <el-table-column
          prop='cp'
          :label='$t("inputs.zipcode")'
          minWidth='90'
          show-overflow-tooltip
          sortable
          v-if="rows[5].show"
        >
        </el-table-column>
        <el-table-column
          prop='email'
          label='Email'
          minWidth='150'
          show-overflow-tooltip
          sortable
          v-if="rows[6].show"
        >
        </el-table-column>
        <el-table-column
          prop='telephone'
          :label='$t("table.columns.Phone")'
          minWidth='110'
          show-overflow-tooltip
          sortable
          v-if="rows[7].show"
        >
        </el-table-column>
        <el-table-column
          prop='fk_provenance'
          :label='$t("table.columns.Origin")'
          minWidth='160'
          show-overflow-tooltip
          v-if="rows[8].show"
        >
          <template scope="scope">
            <template v-if="scope.row.fk_provenance === 2">
              <template v-if="scope.row.subid">
                {{ 'Mycoreg_' + scope.row.subid }}
              </template>
              <template v-else>
                Mycoreg
              </template>
            </template>
            <template v-else-if="scope.row.subid">
              {{ scope.row.subid }}
            </template>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('table.columns.DirectoryCheck')"
          minWidth='140'
          v-if="rows[9].show"
        >
          <template scope="scope">
            <a target="_blank" :href="'http://www.118218.fr/recherche?geo_id=&distance=&category=&who=' + scope.row.nom + '&where=' + scope.row.ville">
              <base-button type="market" size="sm">118 218</base-button>
            </a>
          </template>
        </el-table-column> -->
        <el-table-column
          prop='facturation'
          :label='$t("table.columns.Billing")'
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[9].show"
        >
        </el-table-column>
        <el-table-column
          prop='statut'
          label='statut'
          minWidth='120'
          show-overflow-tooltip
          sortable
          v-if="rows[10].show"
        >
          <template scope="scope">
            <template v-if="scope.row.statut === 'ok' || scope.row.statut === 'Ok'">
              {{ $t("labels.Valide") }}
            </template>
            <template v-else-if="scope.row.statut === 'devalide'">
              {{ $t("labels.AcceptedDevalidation") }}
            </template>
            <template v-else-if="scope.row.statut === 'en cours de verification'">
              {{ $t("labels.BeingProcessed") }}
            </template>
            <template v-else-if="scope.row.statut === 'demande refusee'">
              {{ $t("labels.DevocatedDevalidation") }}
            </template>
            <template v-else>
              {{ scope.row.statut }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='motif'
          :label='$t("table.columns.ReasonInvalidation")'
          minWidth='250'
          show-overflow-tooltip
          sortable
          v-if="rows[11].show"
        >
          <template scope="scope">
            <template v-if="scope.row.motif === 'aucune demande'">
              {{ $t("labels.Donotanswer") }}
            </template>
            <template v-else-if="scope.row.motif === 'ne reponds pas'">
              {{ $t("labels.Madenorequest") }}
            </template>
            <template v-else-if="scope.row.motif === 'faux numero de telephone'">
              {{ $t("labels.Fakephone") }}
            </template>
            <template v-else-if="scope.row.motif === 'numero non attribue'">
              {{ $t("labels.Numbernotallocated") }}
            </template>
            <template v-else-if="scope.row.motif === 'refuse par ws'">
              {{ $t("labels.RefusedbyWebService") }}
              <template v-if="scope.row.retour_ws">
                {{ ' - ' + scope.row.retour_ws }}
              </template>
              <template v-else>
                {{ ' - ' + $t("labels.NoreturnWs") }}
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="Action"
          min-width="100px"
          v-if="rows[12].show"
        >
          <template scope="scope">
            <el-tooltip
              content="Confirmer la dévalidation de ce lead"
              effect="light"
              :open-delay="300"
              placement="top"
              v-if="scope.row.statut === 'en cours de verification'"
            >
              <base-button
                @click.native="ValiderLeads(scope.row)"
                class="btn-link"
                type="success"
                size="sm"
                icon
              >
                <i class="far fa-check-square"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('labels.cancelDeplinationRequestLead')"
              effect="light"
              :open-delay="300"
              placement="top"
              v-if="scope.row.statut === 'en cours de verification'"
            >
              <base-button
                @click.native="CancelLeads(scope.row)"
                class="btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="far fa-trash-alt"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, BaseDropdown } from 'src/components';
import Fuse from 'fuse.js';
import gql from 'graphql-tag'
import swal from 'sweetalert2'
import PerfectScrollbar from 'perfect-scrollbar';
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    BaseDropdown
  },
  apollo: {
    leadsDevalides: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/leadsDevalides.gql"),
      variables () {
        return {
          annonceur_id: this.ClientSelects.Client,
          categorie_id: this.CategorieSelects.Categorie,
          statut: this.StatutSelects.Statut,
          pack: this.PackSelects.Pack,
          facturation: this.FacturationSelects.Facturation,
          date_debut: this.StartDate,
          date_fin: this.EndDate,
        }
      },
      result({ data, loader, networkStatus }) {
        this.ListLeads = this.searchLeads = data.leadsDevalides
        this.fuseSearch = new Fuse(this.ListLeads, this.options);
        this.SkipQuery = true
      },
      skip () {
        return this.SkipQuery
      },
      fetchPolicy: 'network-only',
    },
    clients: {
      client: 'MARKET',
      query: gql` query {
        clients {
          fk_annonceur
          societe
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ClientSelects.ListClient = data.clients
      },
    },
    produits: {
      client: 'MARKET',
      query: gql` query {
        produits {
          pk_categorie
          designation
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.CategorieSelects.ListCategorie = data.produits
      },
    },
  },
  watch: {
    searchQuery(value) {
      let result = this.ListLeads;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchLeads = result;
    },
    selectallrows(value) {
      this.rows.forEach(element => {
        element.show = value
      });
    },
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchLeads.length
    }
  },
  data() {
    return {
      fullscreen: false,
      PerfectScrollbar: null,
      icons: Icons,
      With_fields: false,
      LeadsSelected: [],
      searchQuery: '',
      fuseSearch: null,
      searchLeads: [],
      ListLeads: [],
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [50, 100, 500, 1000, 2000],
        total: 0
      },
      CategorieSelects: {
        Categorie: '',
        ListCategorie: []
      },
      PackSelects: {
        Pack: '',
        ListPack: [
          { value: 'exclusif', label: 'EXCLUSIF' },
          { value: 'duo', label: 'DUO' },
          { value: 'trio', label: 'TRIO' },
        ]
      },
      FacturationSelects: {
        Facturation: '',
        ListFacturation: [
          { value: 'facturable', label: 'FACTURABLE' },
          { value: 'non facturable', label: 'NON FACTURABLE' }
        ]
      },
      StatutSelects: {
        Statut: 'en cours de verification',
        ListStatut: [
          { value: 'en cours de verification', label: this.$t("Filter.label.BeingVerified") },
          { value: 'devalide', label: 'DEVALIDE' },
          { value: 'demande refusee', label: this.$t("Filter.label.ApplicationRefused") },
        ]
      },
      ClientSelects: {
        Client: '',
        ListClient: []
      },
      StartDate: null,
      EndDate: null,
      SkipQuery: false,
      options : {
        threshold: 0.2,
        keys: [
          "date_livraison",
          "pk_commande",
          "societe",
          "designation",
          "nom",
          "prenom",
          "cp",
          "email",
          "telephone",
          "facturation",
          "statut",
          "motif"
        ]
      },
      selectallrows: true,
      rows: [
        { label: this.$t("table.columns.DeliveryDate"), show: true },
        { label: this.$t("table.columns.OrderNumber"), show: true },
        { label: 'Client', show: true },
        { label: this.$t("table.columns.Product"), show: true },
        { label: this.$t("inputs.Lastname") +' / '+ this.$t("inputs.Firstname"), show: true },
        { label: this.$t("inputs.zipcode"), show: true },
        { label: 'Email', show: true },
        { label: this.$t("table.columns.Phone"), show: true },
        { label: this.$t("table.columns.Origin"), show: true },
        // { label: this.$t("table.columns.DirectoryCheck"), show: true },
        { label: this.$t('table.columns.Billing'), show: true },
        { label: 'statut', show: true },
        { label: this.$t('table.columns.ReasonInvalidation'), show: true },
        { label: 'Action', show: true }
      ]
    }
  },
  methods: {
    toggle () {
      this.$fullscreen.toggle(this.$el.querySelector('.ListleadsDevalides'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
      if (fullscreen) {
        this.PerfectScrollbar = new PerfectScrollbar(".ListleadsDevalides");
      }
      else {
        this.PerfectScrollbar.destroy();
        this.PerfectScrollbar = null
      }
    },
    search() {
      this.SkipQuery = false
    },
    handleSelectionChange(val) {
      this.LeadsSelected = val;
      console.log(val.length)
      console.log(val)
    },
    ValideSelection() {
      if (this.LeadsSelected.length === 0) {
        this.$notify({type: 'warning', message: "vous devez sélectionner au minimum une ligne" })
        return
      }
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          let commande_selected = this.LeadsSelected.filter((x) => { return x.statut === 'en cours de verification'; }).map(a => a.pk_bon_commande);
          if (commande_selected === '') {
            this.$notify({type: 'warning', message: "Les leads sélectionnées sont déjà traites" })
            return
          }
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: require("src/graphql/leadmarket/mutation/accepterDevalidation.gql"),
            variables: {
              id_bon_commande_selected: commande_selected.join(',')
            },
          }).then(response => {
            if (response.data.accepterDevalidation.state === "OK") {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              this.refetchQuery()
            }
            else if (response.data.accepterDevalidation.state === 'depasse10') {
              this.$notify({type: 'success', message: "Le nombre de dévalidations maximum a été atteint"})
            } else if (response.data.accepterDevalidation.state === '') {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    CancelSelection() {
      if (this.LeadsSelected.length === 0) {
        this.$notify({type: 'warning', message: "vous devez sélectionner au minimum une ligne" })
        return
      }
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          let commande_selected = this.LeadsSelected.filter((x) => { return x.statut === 'en cours de verification'; }).map(a => a.pk_bon_commande);
          if (commande_selected === '') {
            this.$notify({type: 'warning', message: "Les leads sélectionnées sont déjà traites" })
            return
          }
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: require("src/graphql/leadmarket/mutation/refuserDevalidation.gql"),
            variables: {
              id_bon_commande_selected: commande_selected.join(',')
            },
          }).then(response => {
            if (response.data.refuserDevalidation.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              this.refetchQuery()
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    ValiderLeads(row) {
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: require("src/graphql/leadmarket/mutation/accepterDevalidation.gql"),
            variables: {
              id_bon_commande_selected: row.pk_bon_commande
            },
          }).then(response => {
            if (response.data.accepterDevalidation.state === "OK") {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              this.refetchQuery()
            }
            else if (response.data.accepterDevalidation.state === 'depasse10') {
              this.$notify({type: 'success', message: "Le nombre de dévalidations maximum a été atteint sur votre commande N° " + row.pk_bon_commande })
            } else if (response.data.accepterDevalidation.state === '') {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    CancelLeads(row) {
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: require("src/graphql/leadmarket/mutation/refuserDevalidation.gql"),
            variables: {
              id_bon_commande_selected: row.pk_bon_commande
            },
          }).then(response => {
            if (response.data.refuserDevalidation.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              this.refetchQuery()
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    refetchQuery() {
      this.SkipQuery = false
      this.$apollo.queries.leadsDevalides.refetch({
        annonceur_id: this.ClientSelects.Client,
        categorie_id: this.CategorieSelects.Categorie,
        statut: this.StatutSelects.Statut,
        pack: this.PackSelects.Pack,
        facturation: this.FacturationSelects.Facturation,
        date_debut: this.StartDate,
        date_fin: this.EndDate,
      })
    },
    DownloadCSV() {
      const url_api = "https://apileadmarket.wee-do-it.net/api/export-Leads-devalides-csv";
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('annonceur_id', this.ClientSelects.Client)
      formData.append('categorie_id', this.CategorieSelects.Categorie)
      formData.append('statut', this.StatutSelects.Statut)
      formData.append('pack', this.PackSelects.Pack)
      formData.append('facturation', this.FacturationSelects.Facturation)
      formData.append('date_debut', this.StartDate || '')
      formData.append('date_fin', this.EndDate || '')
      formData.append('with_date_collecte', '')
      formData.append('with_tag', '')
      formData.append('with_fields', this.With_fields)

      this.axios
      .post(url_api,formData , {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
        responseType: "blob"
      }).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'extract.csv');
        document.body.appendChild(link);
        link.click();
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      })
    },
    getRowKey(row) {
      return row.pk_bon_commande
    },
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>