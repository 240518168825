<template>
  <div class="content">
    <h3>SURVEILLANCE ACHAT & VENTE</h3>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <base-input class="col-md-4">
            <el-date-picker :disabled="$apollo.queries.surveillanceLeads.loading" v-model="StartDate" value-format="yyyy-MM-dd" type="date" placeholder="DATE DÉBUT"></el-date-picker>
          </base-input>
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="CategorieSelects.Categorie"
              placeholder="CATEGORIE"
              :disabled="$apollo.queries.surveillanceLeads.loading"
            >
              <el-option 
                v-for="option in CategorieSelects.ListCategorie"
                class="select-primary"
                :value="option.pk_categorie"
                :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
                :key="option.pk_categorie">
              </el-option>
            </el-select>
            </base-input>
        </div>
        <div class="col-xs-4 text-right">
          <button class="btn btn-market" @click="DownloadCSV">
            <i class="tim-icons icon-cloud-download-93"></i> EXPORT CSV
          </button>
          <base-button 
            v-if="$apollo.queries.surveillanceLeads.loading"
            loading 
            class="btn btn-default" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-default" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div>
      </div>
    </div>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="RECHERCHE"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table :data="searchLeads.slice(from, to)" v-loading="$apollo.queries.surveillanceLeads.loading" row-class-name="booking-table-rows" class="customtable_cmd">
        <el-table-column
          prop='pk_lead'
          label="Id"
          minWidth='75'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='email'
          label="Email"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='telephone'
          label="Téléphone"
          minWidth='110'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='date_collect'
          label="Date collecte"
          minWidth='110'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='lead_type'
          label="Type"
          minWidth='75'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='subid'
          label="Tag"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='societe'
          label="Societé"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='date_livraison'
          label="Date livraison"
          minWidth='120'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='pk_commande'
          label="Commande"
          minWidth='115'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='prix_vente'
          label="Prix de vente (€)"
          minWidth='100'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            <template v-if="scope.row.prix_vente === 'OK'">
              {{ scope.row.prix_vente = '' }}
            </template>
            <template v-else>
              {{ scope.row.prix_vente }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='filtre'
          label="Filtre"
          minWidth='80'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='vendu'
          label="Vendu"
          minWidth='80'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='retourws'
          label="Retour ws"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='captcha'
          label="verify Recapcha"
          minWidth='105'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        
      </el-table>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
  import { Select, Option, DatePicker } from 'element-ui'
  import { Table, TableColumn } from 'element-ui'
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'
  import Fuse from 'fuse.js';
  import XLSX from 'xlsx'

  export default {
    apollo: {
      surveillanceLeads: {
        client: 'MARKET',
        query: gql` query ($produit_id: ID!, $date: String) {
          surveillanceLeads(
            produit_id: $produit_id
            date: $date
          ){
            pk_lead
            email
            date_collect
            telephone
            lead_type
            subid
            societe
            date_livraison
            pk_commande 
            prix_vente
            filtre
            vendu
            compteur_livraison
            statut
            retourws
            captcha
          }
        }`,
        variables () {
          return {
            date: this.StartDate,
            produit_id: this.CategorieSelects.Categorie
          }
        },
        skip() {
          return this.SkipQuery
        },
        result({ data, loader, networkStatus }) {
          this.SkipQuery = true
          // default sort pk_lead
          data.surveillanceLeads.sort((a,b) => (parseInt(a.pk_lead) < parseInt(b.pk_lead)) ? 1 : ((parseInt(b.pk_lead) < parseInt(a.pk_lead)) ? -1 : 0)); 
          // default sort pk_lead
          this.Leads = this.searchLeads = data.surveillanceLeads
          this.fuseSearch = new Fuse(this.searchLeads, this.options);
        },
        fetchPolicy: 'network-only'
      },
      produits: {
        client: 'MARKET',
        query: gql` query {
          produits {
            pk_categorie
            designation
          }
          
        }`,
        result({ data, loader, networkStatus }) {
          this.CategorieSelects.ListCategorie = data.produits
        },
        update: data => data
      }
    },
    watch: {
      searchQuery(value) {
        let result = this.Leads;
        if (value != '' && this.fuseSearch) {
          result = this.fuseSearch.search(this.searchQuery);
        }
        this.searchLeads = result;
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BasePagination
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchLeads.length
      }
    },
    data() {
      return {
        fuseSearch: null,
        SkipQuery: false,
        searchLeads: [],
        Leads: [],
        searchQuery: '',
        StartDate: '',
        CategorieSelects: {
          Categorie: 1,
          ListCategorie: []
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500],
          total: 0
        },
        options : {
          threshold: 0.2,
          keys: [
            "pk_lead",
            "email",
            "telephone",
            "date_collect",
            "lead_type",
            "subid",
            "societe",
            "date_livraison",
            "pk_commande",
            "prix_vente",
            "filtre",
            "vendu",
            "retourws",
            "captcha"
          ]
        },
      }
    },
    methods: {
      search() {
        this.SkipQuery = false
      },
      DownloadCSV() {
        if (this.searchLeads.length) {
          let LeadsArray = JSON.parse(JSON.stringify(this.searchLeads));
          LeadsArray.forEach(function(v){ 
            if (v.prix_vente === 'OK') {
              v.prix_vente = ''
            }
            delete v.compteur_livraison
            delete v.statut
            delete v.retourws
            delete v.captcha
            delete v.__typename
          });
          const fileName = 'surveillanceLeads.csv'
          var Leads = XLSX.utils.json_to_sheet(LeadsArray) 
          Leads = XLSX.utils.sheet_to_csv(Leads, {FS:";"});
          
          Leads = Leads.replace("pk_lead","N° de lead")
          Leads = Leads.replace("date_collect","date collecte")
          Leads = Leads.replace("subid","tag")
          Leads = Leads.replace("date_livraison","date livraison")
          Leads = Leads.replace("lead_type","type")
          Leads = Leads.replace("pk_commande","commande")
          
          Leads = Leads.replace("prix_vente","prix de vente")
          var blob = new Blob(["\ufeff", Leads]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      }
    },
  }
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>