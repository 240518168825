<template>
    <Modal_selfEvent
      :show.sync="isModalActive"
      body-classes="p-0"
      class="manage-facture-modal modal-listbase"
      :centered="true"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">
        <base-button style="float: right;" @click.native="closeModal()" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <center>
          <h4>{{ data.Action == 'add' ? 'AJOUTER AVOIR' : `MODIFIER AVOIR N° ${facture.id}` }}</h4>
        </center>
        <hr />
        <div class="form-row">
          <base-input class="col-md-6" label="ANNEE *" :error="getError('annee')">
            <el-select
              filterable
              class="select-primary"
              v-model="PeriodeSelects.Periode.annee"
              placeholder="ANNEE"
              name="annee"
              v-validate="'required'"
            >
              <el-option
                v-for="option in PeriodeSelects.ListPeriodeAnnee"
                class="select-primary"
                :value="option"
                :label="option"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
          <base-input class="col-md-6" label="MOIS *" :error="getError('mois')">
            <el-select
              filterable
              class="select-primary"
              v-model="PeriodeSelects.Periode.mois"
              placeholder="MOIS"
              name="mois"
              v-validate="'required'"
            >
              <el-option
                v-for="option in PeriodeSelects.ListPeriodeMois"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row">
          <base-input
            label="N° Facture"
            class="col-md-6"
            name="Numero facture"
            v-model="facture.factureNumber"
          ></base-input>
          <base-input
            label="N° de client facturé"
            class="col-md-6"
            name="Numero de client facture"
            v-model="facture.clientFactureNumber"
          ></base-input>
        </div>
        <div class="form-row">
          <base-input
            class="col-md-6"
            label="CLIENT *"
            :error="getError('client')"
          >
            <el-select
              filterable class="select-primary"
              v-model="ClientSelects.Client"
              placeholder="CLIENT"
              clearable
              name="client"
              v-validate="'required'"
            >
              <el-option
                v-for="option in ClientSelects.ListClient"
                class="select-primary"
                :value="option.id"
                :label="(option.society.length > 30) ? replaceUTF_8(option.society.slice(0, 30)).toUpperCase() +'...' : replaceUTF_8(option.society).toUpperCase()"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
          <base-input
            label="QUANTITE"
            class="col-md-6"
            type="number"
            v-model="facture.quantite"
            name="quantite"
            required
            :error="getError('quantite')"
            v-validate="'required'"
          ></base-input>
        </div>
        <div class="form-row">
          <base-input
            label="PRIX"
            class="col-md-6"
            v-model="facture.prix"
            name="prix"
            required
            :error="getError('prix')"
            v-validate="'required'"
          ></base-input>
          <base-input
            class="col-md-6"
            label="PLATEFORM *"
            :error="getError('plateforme')"
          >
            <el-select
              filterable
              class="select-primary"
              placeholder="PLATFORME"
              clearable
              v-model="PlatformeSelects.Platforme"
              name="plateforme"
              v-validate="'required'"
            >
              <el-option
                v-for="option in PlatformeSelects.ListPlatform"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="form-row">
          <base-input
            label="Description"
            class="col-md-12"
          >
            <textarea
              v-model="facture.description"
              class="form-control"
              rows="15"
              style="max-height: 170px !important;"
            ></textarea>
          </base-input>
        </div>
        <center>
          <base-button
            v-if="loading"
            type="info"
            loading
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <base-button
            v-else-if="data.Action == 'add'"
            type="info"
            @click="addFacture()"
            >{{ $t("buttons.BtnAdd").toUpperCase() }}
          </base-button>
          <base-button
            v-else
            type="info"
            @click="updateFacture()"
            >{{ $t("buttons.BtnModify").toUpperCase() }}
          </base-button>
        </center>
      </card>
    </Modal_selfEvent>
</template>

<script>

import Modal_selfEvent from 'src/components/Modal_selfEvent'
import { Select, Option } from 'element-ui'
import moment from 'moment'
const DEFAULT_FACTURE_DESCRIPTION = "WEEDO MARKET :\t\t\t\n - Campagne: \t\t\t\n - Volume: \t\t\n - Rémunération:\t\t\n - Leviers:\t\t\t\n - Incentive au clic non autorisé\t\t\t\n - Incentive au remplissage non autorisé\t\t\t\n\t\t\t\n\t\t\t\t\t\nDate de diffusion:\t\t\n\t\t\t\nFrais setup (intégration, tracking, promotion) :\t\t\n\t\t\t\t\t\t\t\nLancement de la campagne après validation technique des 2 parties.\t\t\t\nClause de sortie sous 48h max apres demande ecrite\t\t\t\nFacturation sur les bases des enregistrements Weedo IT\t\t\t\nTaux de rejet maximum autorisé :"

const API_URL = "https://api-auth-plf.wee-do-it.net/api/v1"

const GET_YEARS_LIST = () => {
  const startYear = 2016
  const currentYear = moment().year()

  const yearList = []
  for (let year = startYear; year <= currentYear; year++) {
    yearList.push(year)
  }
  return yearList.reverse()
}

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal_selfEvent
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },
  data() {
    return {
      facture: {},
      loading: false,
      isModalActive: true,
      PeriodeSelects: {
        Periode: {
          mois: '',
          annee : ''
        },
        ListPeriodeAnnee: GET_YEARS_LIST(),
        ListPeriodeMois: [
          { value: 1, label: "JANVIER" },
          { value: 2, label: "FÉVRIER" },
          { value: 3, label: "MARS" },
          { value: 4, label: "AVRIL" },
          { value: 5, label: "MAI" },
          { value: 6, label: "JUIN" },
          { value: 7, label: "JUILLET" },
          { value: 8, label: "AOÛT" },
          { value: 9, label: "SEPTEMBRE" },
          { value: 10, label: "OCTOBRE" },
          { value: 11, label: "NOVEMBRE" },
          { value: 12, label: "DÉCEMBRE" }
        ]
      },
      PlatformeSelects: {
        Platforme: '',
        ListPlatform: [
          { value: "perf", label: "WEEDO PERF" },
          { value: "coreg", label: "WEEDO COREG" },
          { value: "market", label: "WEEDO MARKET" },
          { value: "mongustave", label: "MONGUSTAVE" }
        ]
      },
      ClientSelects: {
        Client: '',
        ListClient: []
      },
    }
  },
  beforeMount() {
    this.ClientSelects.ListClient = this.data.ListClient

    if(this.data.Action == 'add') {
      this.facture = {
        factureNumber: null,
        quantite: 0,
        clientFactureNumber: null,
        prix: 0,
        description: DEFAULT_FACTURE_DESCRIPTION
      }
    }
    else {
      this.facture = {
        id: this.data.Facture.id,
        factureNumber: this.data.Facture.num_facture,
        quantite: this.data.Facture.quantite,
        clientFactureNumber: this.data.Facture.num_client_facture,
        prix: this.data.Facture.prix,
        description: this.data.Facture.description || DEFAULT_FACTURE_DESCRIPTION
      }

      this.PeriodeSelects.Periode.mois = this.data.Facture.periode_mois
      this.PeriodeSelects.Periode.annee = this.data.Facture.periode_annee
      this.PlatformeSelects.Platforme = this.data.Facture.plateforme
      this.ClientSelects.Client = this.data.Facture.client_id
    }
  },
  watch: {
    isModalActive(newValue, oldValue) {
      if(!newValue) {
        this.closeModal()
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    replaceUTF_8(string) {
        if(!string)
          return ''

        for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
          string = string.replaceAll(value, key)
        }

        return string
    },
    async addFacture() {

      let isFormValid = await this.$validator.validateAll()

      if(!isFormValid) return

      const endpoint = `${API_URL}/add_facture_avoir`
      const apiToken = localStorage.getItem('token_jwt')

      let formData = this.getFactureFormData()

      this.activateFormLoading()

      this.axios.post(
        endpoint,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            'authorization': `Bearer ${apiToken}`
          }
        })
        .then(({ data }) => {
          this.disableFormLoading()
          const facture = this.formatFacture(data.response.facture_avoir)
          this.$emit('facture-added', facture)
        })
        .catch(error => {
          this.disableFormLoading()
          this.$notify({type: 'warning', message: "erreur lors de la création du facture" })
        })
    },
    async updateFacture() {

      let isFormValid = await this.$validator.validateAll()

      if(!isFormValid) return

      const endpoint = `${API_URL}/update_facture_avoir`
      const apiToken = localStorage.getItem('token_jwt')

      let formData = this.getFactureFormData()
      formData.append('id', this.facture.id)

      this.activateFormLoading()
      this.axios.post(
        endpoint,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            'authorization': `Bearer ${apiToken}`
          }
        })
        .then(({ data }) => {
          this.disableFormLoading()
          const facture = this.formatFacture(data.response.facture_avoir)
          this.$emit('facture-edited', facture)
        })
        .catch(error => {
          this.disableFormLoading()
          this.$notify({type: 'warning', message: "erreur lors de la modification du facture" })
        })
    },
    activateFormLoading() {
      this.loading = true
    },
    disableFormLoading() {
      setTimeout(() => {
        this.loading = false
      },1000)
    },
    getFactureFormData() {

      let formData = new FormData()

      const clientId = this.ClientSelects.Client
      const clientSociete = this.ClientSelects.ListClient.find(client => client.id == clientId).society

      formData.append('client_id', clientId)
      formData.append('societe_client', clientSociete)
      formData.append('plateforme', this.PlatformeSelects.Platforme)
      formData.append('periode_mois', this.PeriodeSelects.Periode.mois)
      formData.append('periode_annee', this.PeriodeSelects.Periode.annee)
      formData.append('quantite', this.facture.quantite)
      formData.append('prix', parseFloat(this.facture.prix))

      if(!!this.facture.description) {
        formData.append('description', this.facture.description)
      }

      if(this.facture.factureNumber) {
        formData.append('num_facture', this.facture.factureNumber)
      }

      if(this.facture.clientFactureNumber) {
        formData.append('num_client_facture', this.facture.clientFactureNumber)
      }

      return formData
    },
    formatFacture(facture) {
      facture.client_id = parseInt(facture.client_id)
      facture.periode_mois = parseInt(facture.periode_mois)
      facture.periode_annee = parseInt(facture.periode_annee)
      return facture
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .manage-facture-modal {
    overflow-y: auto !important;
  }

  .manage-facture-modal .modal-content {
    background-color: transparent;
  }

  .manage-facture-modal.show .modal-dialog {
    transform: none;
  }
</style>
