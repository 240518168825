<template>
  <div class="content">
    <h3>PARAMÈTRES CATEGORIE</h3>
    <card>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <base-input
            name="Catégorie"
            v-validate="'required'"
            :error="getError('Catégorie')"
            label="Catégorie"
            class="col-10"
            v-model="nameCategorie">
          </base-input>
          <base-input
            label="Statut des ventes"
            class="col-10">
            <el-select class="select-primary"
              v-model="Statut">
              <el-option class="select-primary" value="active" label="ACTIVÉ"></el-option>
              <el-option class="select-primary" value="pause" label="PAUSE"></el-option>
            </el-select>
          </base-input>
          <!-- <base-input
            label="Copier les paramètres depuis"
            class="col-10">
            <el-select class="select-primary">
              <el-option class="select-primary" value="active" label="ACTIVÉ"></el-option>
              <el-option class="select-primary" value="pause" label="PAUSE"></el-option>
            </el-select>
          </base-input> -->
          <label>CHAMPS À COLLECTER</label>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
                <base-checkbox v-for="field in collected_fields" v-bind:key="field.id" v-bind:id="field.id"
                  inline class="mb-3 col-md-5" v-model="field.checked" :value="field.value">{{ field.name }}
                </base-checkbox>
              </div>
            </div>
          </div>
          <label>AJOUTER DE NOUVEAUX CHAMPS POUR CETTE CATÉGORIE</label>
          <div class="row">
            <label class="col-5">CHAMPS</label>
            <label class="col-5">MAPPING</label>
          </div>
          <div v-for="(f, index) in New_fields" class="row">
            <base-input
              class="col-5"
              v-model="f.champs">
            </base-input>
            <base-input
              class="col-5"
              v-model="f.mapping">
            </base-input>
            <base-button
              v-if="index === 0"
              @click.native="New_fields.push({ champs: '', mapping: '' })"
              class="col-sm-1 edit btn-link"
              type="success"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
            <base-button
              v-else
              @click.native="New_fields.splice(index, 1);"
              class="col-sm-1 edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="card" v-for="pack in packs">
            <span slot="header" class="badge-pill badge-market tab-title text-uppercase" style="font-weight: bold;">
              Paramètres des leads {{ pack.designation }}
            </span><br>
            <base-input
              :name="'CPL de base ' + pack.designation"
              v-validate="'required'"
              :error="getError('CPL de base ' + pack.designation)"
              :label="'CPL de base ' + pack.designation"
              v-model="pack.prix_cpl"
              type="number"
            >
            </base-input>
          </div>
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
              PARAMÈTRES DE DESTOCKAGE
            </span><br>
            <base-input
              label="Prix"
              v-model="Prix_destockage"
              type="number"
            >
            </base-input>
          </div>
        </div>
      </div>
      <center>
        <base-button
          type="market"
          @click="addCategory"
          :disabled="loading"
        >
          <i class="fa fa-check" ></i>SAUVEGARDER
        </base-button>
      </center>
    </card>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import gql from 'graphql-tag'

export default {
  apollo: {
    packs: {
      client: 'MARKET',
      query: gql` query {
        packs{
          pk_pack
          designation
          etat
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.packs.forEach(pack => {
          pack.prix_cpl = 0
        });
      },
      fetchPolicy: 'network-only'
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      loading: false,
      Prix_destockage: null,
      nameCategorie: '',
      Statut: 'active',
      collected_fields: [
        { value: 'civilite', name: 'CIVILITÉ', checked: false },
        { value: 'nom', name: 'NOM', checked: false },
        { value: 'prenom', name: 'PRÉNOM', checked: false },
        { value: 'email', name: 'EMAIL', checked: false },
        { value: 'telephone', name: 'TÉLÉPHONE', checked: false },
        { value: 'cp', name: 'CODE POSTAL', checked: false },
        { value: 'ville', name: 'VILLE', checked: false },
        { value: 'date_naissance', name: 'DATE NAISSANCE', checked: false },
        { value: 'adresse', name: 'ADRESSE', checked: false },
        { value: 'departement', name: 'DÉPARTEMENT', checked: false },
        { value: 'ip', name: 'IP', checked: false }
      ],
      New_fields: [
        { champs: '', mapping: '' }
      ]
    }
  },
  methods: {
    async addCategory() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.loading = true

      let champs = this.New_fields.map(a => a.champs)
      let mappings = this.New_fields.map(a => a.mapping)

      // check champs & mapping

      let new_inputs = ""
      champs.forEach((c, index) => {
        new_inputs += c + "||" + mappings[index] + ";"
      });

      let packs = ""
      this.packs.forEach(pack => {
        packs +=  pack.pk_pack + "||" + (pack.prix_cpl || 0) + ";"
      });

      await this.$apollo.mutate({
        client: 'MARKET',
        mutation: require("src/graphql/leadmarket/mutation/addCategory.gql"),
        variables: {
          designation: this.nameCategorie,
          statut: this.Statut,
          geolocalisation: this.Geolocalisation,
          prix_destockage: this.Prix_destockage,
          new_inputs: new_inputs.slice(0, -1),
          packs: packs.slice(0, -1),
          civilite: this.collected_fields.find(f => f.value === 'civilite').checked,
          nom: this.collected_fields.find(f => f.value === 'nom').checked,
          cp: this.collected_fields.find(f => f.value === 'cp').checked,
          telephone: this.collected_fields.find(f => f.value === 'telephone').checked,
          ville: this.collected_fields.find(f => f.value === 'ville').checked,
          departement: this.collected_fields.find(f => f.value === 'departement').checked,
          email: this.collected_fields.find(f => f.value === 'email').checked,
          date_naissance: this.collected_fields.find(f => f.value === 'date_naissance').checked,
          adresse: this.collected_fields.find(f => f.value === 'adresse').checked,
          prenom: this.collected_fields.find(f => f.value === 'prenom').checked,
          ip: this.collected_fields.find(f => f.value === 'ip').checked
        },
      }).then(response => {
        this.$notify({type: 'success', message: "categorie créé avec succès" })
        this.loading = false
        this.$router.push({ name: 'Categorie LeadMarket Admin' })
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        this.loading = false
      })

    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    }
  },
}
</script>