<template>
  <div class="content">
    <h4 class="text-uppercase">RAPPORT CATÉGORIE "{{ this.$route.params.categorie }}"</h4>
    <card>
      <el-table :data="rapportCategory" v-loading="$apollo.queries.rapportCategory.loading" show-summary :summary-method="getSummaries" :default-sort = "{prop: 'jours', order: 'descending'}">
        <el-table-column
          prop='jours'
          label="jours"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='impressions'
          label="impressions"
          minWidth='140'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='clicks'
          label="clicks"
          minWidth='120'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='nbre_leads'
          label="nombre de leads"
          minWidth='170'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
      </el-table>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui'
import gql from 'graphql-tag'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  apollo: {
    rapportCategory: {
      client: 'MARKET',
      query: gql` query ($id_category: ID) {
        rapportCategory(id_category: $id_category) {
          jours
          impressions
          clicks
          nbre_leads
        }
      }`,
      variables () {
        return {
          id_category: this.$route.params.categorie_id
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
        let somme_imp = 0
        let somme_click = 0
        let somme_nb_leads = 0
        data.forEach(stats => {
          somme_imp += stats.impressions
          somme_click += stats.clicks
          somme_nb_leads += stats.nbre_leads
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 1) {
            sums[index] = this.formatThounsends(somme_imp);
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(somme_click);
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(somme_nb_leads);
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
    }
  },
}
</script>
