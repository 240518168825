<template>
  <div class="content">
    <h3>PARAMÈTRES COMMANDE {{ $route.params.commande_id }}</h3>
    <h4>SOLDE ACTUEL : {{ formatThounsends(solde, true, 2) }} €</h4>
    <div class="col-md-12">
      <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
        <base-button
          :disabled='disablebtn'
          @click="EditCommande()"
          class="btn btn-round btn-just-icon btn-market"
        ><i class="far fa-save"></i> SAUVEGARDER</base-button>
      </div>
    </div>
    <card>
      <div class="row justify-content-center">
        <base-input label="Nom de la commande" placeholder="NOM DE LA COMMANDE"
          class="col-sm-5"
          v-model="Commande.name_cmd"
          type="text">
        </base-input>
        <base-input label="Produit" :error="getError('Produit')" class="col-sm-5">
          <el-select class="select-primary"
            filterable
            clearable
            placeholder="PRODUIT"
            name="Produit"
            v-validate="'required'"
            v-model="Commande.Product"
            disabled>
            <el-option 
              v-for="option in ProductSelects.ListProduct"
              class="select-primary"
              :value="option.pk_categorie"
              :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
              :key="option.id">
            </el-option>
          </el-select>
        </base-input>
        <!-- v-if="Commande.Product == 1 && !Commande.Filtre" -->
        <base-input label="Lead" class="col-sm-10"  v-if="Commande.Product == 1 && !Commande.Filtre">
          <base-radio inline name="salaries" class="mb-3" v-model="Commande.options_sante">
            SALARIÉS
          </base-radio>
      
          <base-radio inline name="seniors" class="mb-3" v-model="Commande.options_sante">
            SÉNIORS
          </base-radio>
        </base-input>
        <base-input label="Département" class="col-sm-5">
          <el-select class="select-primary"
            filterable
            multiple
            collapse-tags
            placeholder="DÉPARTEMENT"
            @change="changeDepartSelect"
            v-model="Commande.Departement">
            <el-option 
              class="select-primary"
              value="ALL_SELECT"
              label="TOUT SÉLECTIONNER"
            >
            </el-option>
            <hr/>
            <el-option 
              v-for="option in DepartementSelects.ListDepartement"
              class="select-primary"
              :value="option.nom"
              :label="option.nom.toUpperCase()"
              :key="option.pk_departement">
            </el-option>
          </el-select>
        </base-input>
        <base-input label="" class="col-sm-5">
          <base-input class="mt-lg-4">
            <base-radio inline name="inclure"  v-model="Commande.exclure">
              INCLURE
            </base-radio>
        
            <base-radio inline name="exclure" v-model="Commande.exclure">
              EXCLURE
            </base-radio>
          </base-input>
        </base-input>

        <base-input label="Filtre" class="col-sm-5">
          <el-select class="select-primary"
            filterable
            clearable
            placeholder="FILTRE"
            v-model="Commande.Filtre">
            <el-option 
              v-for="option in FiltreSelects.ListFiltre"
              class="select-primary"
              :value="option.pk_filtre"
              :label="option.nom.replace(/Ã©/g,'é')"
              :key="option.id">
            </el-option>
          </el-select>
        </base-input>

        <div class="col-sm-5">
        </div>
        
        <base-input label="Volume de leads Trio" placeholder="VOLUME DE LEADS TRIO"
          v-model="Commande.quantite_lead_trio"
          class="col-sm-3"
          type="number">
        </base-input>

        <base-input label="Volume de leads Duo" placeholder="VOLUME DE LEADS DUO"
          v-model="Commande.quantite_lead_duo"
          class="col-sm-3"
          type="number">
        </base-input>

        <base-input label="Volume de leads Exclusif" placeholder="VOLUME DE LEADS EXCLUSIF"
          v-model="Commande.quantite_lead_exclusif"
          class="col-sm-4"
          type="number">
        </base-input>

        <base-input label="Mode de livraison" class="col-sm-5">
          <base-input class="col-sm-12">
            <base-radio inline name="export" class="mb-3" v-model="Commande.mode_livraison">
              EXPORT FICHIER CSV
            </base-radio>
        
            <base-radio inline name="ws" class="mb-3" v-model="Commande.mode_livraison">
              WEBSERVICE
            </base-radio>
          </base-input>
        </base-input>

        <div class="col-sm-5">

        </div>

        <base-input label="Délai de livraison des leads" class="col-sm-5">
          <base-input class="col-sm-12">
            <base-radio inline name="immediat" class="mb-3" v-model="Commande.delai_livraison">
              IMMÉDIAT
            </base-radio>
        
            <base-radio inline name="etale" class="mb-3" v-model="Commande.delai_livraison">
              ETALÉ SUR PLUSIEURS JOURS
            </base-radio>
          </base-input>
        </base-input>
        <div class="col-sm-5">
        </div>

        <template v-if="Commande.delai_livraison == 'etale'">
          <div class="col-sm-5">
            <base-input label="Nombre de leads par jour et par gamme" placeholder="NOMBRE DE LEADS"
              v-model="Commande.lead_par_jour"
              :error="getError('Nombre de leads')"
              name="Nombre de leads"
              v-validate="(Commande.Product == 12) ? check_Emp : check"
              type="number">
            </base-input>
          </div>

          <base-input class="col-sm-5" label="Date début livraison">
            <el-date-picker 
              v-model="Commande.date_debut_livraison_client" 
              value-format="yyyy-MM-dd" 
              type="date" 
              placeholder="YYYY-MM-DD">
            </el-date-picker>
          </base-input>

          <base-input label="Jours de livraison :" class="col-sm-10">
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.lundi">
              LUNDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.mardi">
              MARDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.mercredi">
              MERCREDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.jeudi">
              JEUDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.vendredi">
              VENDREDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.samedi">
              SAMEDI
            </base-checkbox>
            <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.dimanche">
              DIMANCHE
            </base-checkbox>
          </base-input>

          <label class="col-sm-10">HORAIRE DE LIVRAISON : </label>
          <base-input :error="getError('Heure début')" class="col-sm-5">
            <el-time-select
              name="Heure début"
              v-validate="'required'"
              v-model="Commande.heure_debut_livraison"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '23:59'
              }"
              placeholder="HEURE DÉBUT">
            </el-time-select>
          </base-input>

          <base-input class="col-sm-5">
            <el-time-select
              name="Heure fin"
              v-model="Commande.heure_fin_livraison"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '23:59'
              }"
              placeholder="HEURE FIN">
            </el-time-select>
          </base-input>
          
        </template>

        <base-input label="Notification par E-mail :" class="col-sm-5">
          <base-checkbox inline class="mb-3" v-model="Commande.email_notification">
            ÊTRE NOTIFIÉ POUR CHAQUE LEAD REÇU
          </base-checkbox>
        </base-input>

        <base-input label="COMMANDE EN PROMO :" class="col-sm-5">
          <base-checkbox inline class="mb-3" v-model="Commande.commande_promo">
            COMMANDE EN PROMO
          </base-checkbox>
        </base-input>

        <base-input 
          disabled
          label="Prix unitaire du lead Trio" 
          placeholder="PRIX UNITAIRE DU LEAD TRIO"
          v-bind:value="Commande.prix_unitaire_lead_trio"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input 
          disabled
          label="Prix unitaire du lead Duo" 
          placeholder="PRIX UNITAIRE DU LEAD DUO"
          v-bind:value="Commande.prix_unitaire_lead_duo"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input 
          disabled
          label="Prix unitaire du lead Exclusif" 
          placeholder="PRIX UNITAIRE DU LEAD EXCLUSIF"
          v-bind:value="Commande.prix_unitaire_lead_exclusif"
          class="col-sm-4"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input label="Montant HT" placeholder=""
          disabled
          v-bind:value="SUMtotal"
          class="col-sm-5"
          addonRightIcon="fas fa-euro-sign"
          type="number">
        </base-input>

      </div>
    </card>
    <div class="col-md-12">
      <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
        <base-button
          :disabled='disablebtn'
          @click="EditCommande()"
          class="btn btn-round btn-just-icon btn-market"
        ><i class="far fa-save"></i> SAUVEGARDER</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker, TimeSelect } from 'element-ui'
import { BaseRadio } from 'src/components/index'
import gql from 'graphql-tag'

export default {
  watch: {
    'Commande.prix_unitaire_lead_trio'(val) {
      this.montant_lead_trio = this.Commande.prix_unitaire_lead_trio * this.Commande.quantite_lead_trio
    },
    'Commande.prix_unitaire_lead_duo'(val) {
      this.montant_lead_duo = this.Commande.prix_unitaire_lead_duo * this.Commande.quantite_lead_duo
    },
    'Commande.prix_unitaire_lead_exclusif'(val) {
      this.montant_lead_exclusif = this.Commande.prix_unitaire_lead_exclusif * this.Commande.quantite_lead_exclusif
    },
    'Commande.quantite_lead_trio'(val) {
      this.montant_lead_trio = this.Commande.prix_unitaire_lead_trio * this.Commande.quantite_lead_trio
    },
    'Commande.quantite_lead_duo'(val) {
      this.montant_lead_duo = this.Commande.prix_unitaire_lead_duo * this.Commande.quantite_lead_duo
    },
    'Commande.quantite_lead_exclusif'(val) {
      this.montant_lead_exclusif = this.Commande.prix_unitaire_lead_exclusif * this.Commande.quantite_lead_exclusif
    }
  },
  apollo: {
    editCommande : {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/editCommande.gql"),
      variables() {
        return {
          pk_commande: this.$route.params.commande_id
        }
      },
      result({ data, loader, networkStatus }) {
        this.solde = data.editCommande.solde
        this.utilisateur_id = data.editCommande.utilisateur_id
        this.DepartementSelects.ListDepartement = data.editCommande.departements
        this.ProductSelects.ListProduct = data.editCommande.categories
        this.FiltreSelects.ListFiltre = data.editCommande.filtres
        this.presetField(data.editCommande.commande, data.editCommande.ligne_commande)
      },
      fetchPolicy: 'network-only'
    },
  },
  computed: {
    SUMtotal() {
      let total = 0
      total = this.montant_lead_trio + this.montant_lead_duo + this.montant_lead_exclusif
      total = total.toFixed(2)
      this.Commande.montant = total
      return total
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    BaseRadio
  },
  data() {
    return {
      departallselect: false,
      disablebtn: false,
      old_amount: 0,
      utilisateur_id: null,
      Commande: {
        name_cmd: '',
        Product: '',
        options_sante: '',
        Departement: '',
        Departement_parse: 'tout',
        exclure: 'inclure',
        Filtre: null,
        quantite_lead_trio: null,
        quantite_lead_duo: null,
        quantite_lead_exclusif: null,
        mode_livraison: 'export',
        WS_File: null,
        delai_livraison: 'immediat',
        date_debut_livraison_client: null,
        lead_par_jour: null,
        jours_livraison: {
          lundi: true,
          mardi: false,
          mercredi: false,
          jeudi: false,
          vendredi: false,
          samedi: false,
          dimanche: false
        },
        heure_debut_livraison: '',
        heure_fin_livraison: '',
        email_notification: false,
        commande_promo: false,
        prix_unitaire_lead_trio: null,
        prix_unitaire_lead_duo: null,
        prix_unitaire_lead_exclusif: null,
        montant: null
      },
      ProductSelects: {
        ListProduct: []
      },
      DepartementSelects: {
        ListDepartement: []
      },
      FiltreSelects: {
        ListFiltre: []
      },
      montant_lead_trio: 0,
      montant_lead_duo: 0,
      montant_lead_exclusif: 0,
      solde: 0,
      check: {
        required: true,
        min_value: 10
      },
      check_Emp: {
        required: true,
        min_value: 5
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    changeDepartSelect(value) {
      const allValues = []
      for (const item of this.DepartementSelects.ListDepartement) {
        allValues.push(item.nom)
      }

      if (value.includes('ALL_SELECT')) {
        if (this.departallselect = !this.departallselect)
          this.Commande.Departement = allValues;
        else
          this.Commande.Departement = []
      }
    },
    async EditCommande() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return false

      if (!this.Commande.quantite_lead_trio && !this.Commande.quantite_lead_duo && !this.Commande.quantite_lead_exclusif) {
        this.$notify({type: 'warning', message: "Vous n'avez pas renseigné le volume de votre commande !" })
        return false
      }

      let lead_exclusif = this.Commande.quantite_lead_exclusif ? parseInt(this.Commande.quantite_lead_exclusif) : 0
      let lead_duo = this.Commande.quantite_lead_duo ? parseInt(this.Commande.quantite_lead_duo) : 0
      let lead_trio = this.Commande.quantite_lead_trio ? parseInt(this.Commande.quantite_lead_trio) : 0

      if (this.Commande.delai_livraison == 'etale') {
        if (!this.Commande.jours_livraison.lundi && !this.Commande.jours_livraison.mardi && !this.Commande.jours_livraison.mercredi && !this.Commande.jours_livraison.jeudi && !this.Commande.jours_livraison.vendredi && !this.Commande.jours_livraison.samedi && !this.Commande.jours_livraison.dimanche) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné un jour de livraison" })
          return false
        }
        if ((lead_exclusif + lead_duo + lead_trio) < this.Commande.lead_par_jour) {
          this.$notify({type: 'warning', message: "Le volume à livrer est en conflit avec le volume commandé. Veuillez corriger." })
          return false
        }
      }

      if (this.Commande.Product === 1 && !this.Commande.Filtre) {
        if (!this.Commande.options_sante) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné le type de lead (SALARIÉS / SÉNIORS)" })
          return false
        }
      }

      let check_solde = false
      await this.$apollo.query({
        client: 'MARKET',
        query: gql` query ($id_utilisateur: ID, $montant: Float, $ancien_total: Float) {
          soldeSuffisant(id_utilisateur: $id_utilisateur, montant: $montant, ancien_total: $ancien_total) {
              state
            }
          }`,
          variables: {
            id_utilisateur: this.utilisateur_id,
            montant: this.Commande.montant,
            ancien_total: this.old_amount
          },
          fetchPolicy: 'network-only'
        }).then(data => {
          check_solde = data.data.soldeSuffisant.state
        }).catch((error) => {
          check_solde = false
        })
        if (!check_solde) {
          this.$notify({type: 'warning', message: "Solde insuffisant !" })
          return false
        }

      if (this.Commande.Filtre) {
        this.Commande.options_sante = ""
      }
      
      let jours_livraison = '' 
      // joure de livraison
      if (this.Commande.delai_livraison === 'etale') {
        if (this.Commande.jours_livraison.lundi)
          jours_livraison += "Lundi,"
        if (this.Commande.jours_livraison.mardi)
          jours_livraison += "Mardi,"
        if (this.Commande.jours_livraison.mercredi)
          jours_livraison += "Mercredi,"
        if (this.Commande.jours_livraison.jeudi)
          jours_livraison += "Jeudi,"
        if (this.Commande.jours_livraison.vendredi)
          jours_livraison += "Vendredi,"
        if (this.Commande.jours_livraison.samedi)
          jours_livraison += "Samedi,"
        if (this.Commande.jours_livraison.dimanche)
          jours_livraison += "Dimanche,"

        jours_livraison = jours_livraison.slice(0, -1)
      }

      // departement
      if (this.Commande.Departement.length == 106) {
        this.Commande.Departement_parse = 'tout'
      }
      else {
        this.Commande.Departement_parse = ''
        this.Commande.Departement.forEach(element => {
          this.Commande.Departement_parse += element + ','
        });
      }
      this.disablebtn = true
      await this.$apollo.mutate({
        client: 'MARKET',
        mutation: require("src/graphql/leadmarket/mutation/updateCommande.gql"),
        variables: {
          nom: this.Commande.name_cmd,
          description: '',
          commande_id: this.$route.params.commande_id,
          filtre_id: this.Commande.Filtre,
          delai_livraison: this.Commande.delai_livraison,
          mode_livraison: this.Commande.mode_livraison,
          options_sante: this.Commande.options_sante,
          departement: this.Commande.Departement_parse,
          heure_debut_livraison: this.Commande.heure_debut_livraison,
          heure_fin_livraison: this.Commande.heure_fin_livraison,
          email_notification: this.Commande.email_notification,
          fin_commande_notification: null,
          web_service: null,
          lead_par_jour: this.Commande.lead_par_jour,
          exclure: this.Commande.exclure,
          jours_livraison: jours_livraison,
          date_debut_livraison_client: this.Commande.date_debut_livraison_client,
          quantite_exclusif: this.Commande.quantite_lead_exclusif,
          quantite_duo: this.Commande.quantite_lead_duo,
          quantite_trio: this.Commande.quantite_lead_trio,
          prix_exclusif: this.Commande.prix_unitaire_lead_exclusif,
          prix_duo: this.Commande.prix_unitaire_lead_duo,
          prix_trio: this.Commande.prix_unitaire_lead_trio,
          ancien_total: this.old_amount
        }
      }).then(response => {
        this.disablebtn = false
        this.$notify({type: 'success', message: "commande modifié avec succès" })
        this.$router.push({ name: 'Commandes LeadMarket Admin' })
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        this.disablebtn = false
      })
    },
    async presetField(commande, ligne_commande) {
      if (!commande)
        return;
        
      this.Commande.name_cmd = this.replaceUTF_8(commande.nom)
      this.Commande.Product = parseInt(commande.fk_categorie)
      this.Commande.options_sante = commande.options
      if (commande.geolocalisation) {
        this.Commande.Departement = this.replaceUTF_8(commande.geolocalisation.slice(0, -1)).split(",")
      }
      this.Commande.Filtre = commande.fk_filtre

      this.Commande.exclure = (commande.exclure) ? 'exclure' : 'inclure'
      // set packs tarif
      await this.$apollo.query({
        client: 'MARKET',
        query: gql` 
          query ($categorie_id: ID) {
            getPackTarif(categorie_id: $categorie_id) {
              packs{
                fk_categorie
                fk_pack
                cpl_base
                cpl_promo
              }
            }
          }`,
        variables: {
          categorie_id: this.Commande.Product
        },
        fetchPolicy: 'network-only'
      }).then(data => {
        data.data.getPackTarif.packs.forEach(element => {
          if (element.fk_pack == "1") {
            this.Commande.prix_unitaire_lead_exclusif = element.cpl_base
          }
          else if (element.fk_pack == "4") {
            this.Commande.prix_unitaire_lead_duo = element.cpl_base
          }
          else if (element.fk_pack == "2") {
            this.Commande.prix_unitaire_lead_trio = element.cpl_base
          }
        });
      }).catch((error) => {
        console.error(error)
      })
      // ligne de commande
      if(ligne_commande) {
        ligne_commande.forEach(element => {
          if (element.fk_pack == "1") {
            this.Commande.quantite_lead_exclusif = element.quantite
            this.Commande.prix_unitaire_lead_exclusif = element.prix_unitaire
          }
          else if (element.fk_pack == "4") {
            this.Commande.quantite_lead_duo = element.quantite
            this.Commande.prix_unitaire_lead_duo = element.prix_unitaire
          }
          else if (element.fk_pack == "2") {
            this.Commande.quantite_lead_trio = element.quantite
            this.Commande.prix_unitaire_lead_trio = element.prix_unitaire
          }
        });
      }

      // calculate old amount

      this.old_amount = (this.Commande.prix_unitaire_lead_trio * this.Commande.quantite_lead_trio) +
                        (this.montant_lead_duo = this.Commande.prix_unitaire_lead_duo * this.Commande.quantite_lead_duo) +
                        (this.montant_lead_exclusif = this.Commande.prix_unitaire_lead_exclusif * this.Commande.quantite_lead_exclusif) 
      
      this.Commande.mode_livraison = commande.mode_livraison
      this.Commande.delai_livraison = commande.delai_livraison
      if(this.Commande.delai_livraison === 'etale') {
        this.Commande.lead_par_jour = commande.lead_par_jour
          this.Commande.date_debut_livraison_client = commande.date_debut_livraison_client
        if(commande.jours) {
          this.Commande.jours_livraison.lundi = false
          commande.jours.split(',').forEach(j => {
            if (j.toLowerCase() == 'lundi') {
              this.Commande.jours_livraison.lundi = true
            }
            else if (j.toLowerCase() == 'mardi') {
              this.Commande.jours_livraison.mardi = true
            }
            else if (j.toLowerCase() == 'mercredi') {
              this.Commande.jours_livraison.mercredi = true
            }
            else if (j.toLowerCase() == 'jeudi') {
              this.Commande.jours_livraison.jeudi = true
            }
            else if (j.toLowerCase() == 'vendredi') {
              this.Commande.jours_livraison.vendredi = true
            }
            else if (j.toLowerCase() == 'samedi') {
              this.Commande.jours_livraison.samedi = true
            }
            else if (j.toLowerCase() == 'dimanche') {
              this.Commande.jours_livraison.dimanche = true
            }
          });
        }
        this.Commande.heure_debut_livraison = commande.heure_debut_livraison
        this.Commande.heure_fin_livraison = commande.heure_fin_livraison
      }

      this.Commande.email_notification = commande.email_notification ? true : false
      this.Commande.commande_promo = commande.cmd_promo ? true : false
    },
    replaceUTF_8(string) {
      if(!string) 
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }
      
      return string
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
}
</script>