<template>
  <div class="content">
    <h3>CLIENTS</h3>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <base-input class="col-md-4">
            <el-date-picker :disabled="$apollo.queries.statClients.loading" v-model="StartDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-4">
            <el-date-picker :disabled="$apollo.queries.statClients.loading" v-model="EndnDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="ProvenanceSelects.Provenance"
              :placeholder="$t('table.columns.Origin').toUpperCase()"
              :disabled="$apollo.queries.statClients.loading"
              clearable>
              <el-option 
                v-for="option in ProvenanceSelects.ListProvenance"
                class="select-primary"
                :value="option.pk_provenance_client"
                :label="option.nom.toUpperCase()"
                :key="option.pk_provenance_client">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="row">
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="ClientSelects.Client"
              placeholder="CLIENT"
              :disabled="$apollo.queries.statClients.loading"
              clearable>
              <el-option 
                v-for="option in ClientSelects.ListClient"
                class="select-primary"
                  :value="option.fk_annonceur"
                  :label="(option.societe.length > 30) ? replaceUTF_8(option.societe.slice(0, 30)).toUpperCase() +'...' : replaceUTF_8(option.societe).toUpperCase()"
                  :key="option.id">
              </el-option>
            </el-select>
          </base-input>
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="CategorieSelects.Categorie"
              placeholder="CATEGORIE"
              :disabled="$apollo.queries.statClients.loading"
              clearable>
              <el-option 
                v-for="option in CategorieSelects.ListCategorie"
                class="select-primary"
                :value="option.pk_categorie"
                :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
                :key="option.pk_categorie">
              </el-option>
            </el-select>
          </base-input>
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="CASelects.CA"
              :placeholder="$t('table.columns.BusinessCharges').toUpperCase()"
              :disabled="$apollo.queries.statClients.loading"
              clearable>
              <el-option 
                v-for="option in CASelects.ListCA"
                class="select-primary"
                :value="option.pk_charge_affaire"
                :label="option.nom.toUpperCase()"
                :key="option.pk_charge_affaire">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xs-4 text-right">
          <button class="btn btn-market" @click="DownloadCSV">
            <i class="tim-icons icon-cloud-download-93"></i> EXPORT CSV
          </button>
          <base-button 
            v-if="$apollo.queries.statClients.loading" 
            loading 
            class="btn btn-default" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-default" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div>
      </div>
    </div>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <base-dropdown menu-classes="dropdown-black">
        <template slot="title-container">
          <button class="btn btn-icon btn-round btn-market">
            <i class="tim-icons el-icon-view"></i>
          </button>
        </template>
          <div class="dropdown-header">
            <base-checkbox v-model="selectallrows">
              Sélectionne tout
            </base-checkbox>
          </div>
          <template v-for="row in rows">
            <div class="dropdown-header">
              <base-checkbox v-model="row.show">
                {{ row.label }}
              </base-checkbox>
            </div>
          </template>
      </base-dropdown>
      <el-table :data="searchClients.slice(from, to)" v-loading="$apollo.queries.statClients.loading" row-class-name="booking-table-rows" show-summary :summary-method="getSummaries" class="customtable_cmd"  :default-sort = "{prop: 'date_derniere_cmd', order: 'descending'}">
        <el-table-column
          prop='client'
          label="Client"
          minWidth='150'
          show-overflow-tooltip
          sortable
          v-if="rows[0].show"
        >
          <template scope="scope">
            {{ replaceUTF_8(scope.row.client) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='date_inscription'
          :label="$t('table.columns.RegistrationDate')"
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[1].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_inscription">
              {{ scope.row.date_inscription.split(' ')[0] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='leads_commandes'
          :label="$t('table.columns.LeadsOrdered')"
          minWidth='120'
          show-overflow-tooltip
          sortable
          v-if="rows[2].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.leads_commandes) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='leads_livres'
          :label="$t('table.columns.LeadsOrdered')"
          minWidth='100'
          show-overflow-tooltip
          sortable
          v-if="rows[3].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.leads_livres) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='solde'
          :label="$t('table.columns.CurrentBalance') +' (€ HT)'"
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[4].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.solde, true, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='date_dernier_prepay'
          :label="$t('table.columns.LastCreditDate')"
          minWidth='120'
          show-overflow-tooltip
          sortable
          v-if="rows[5].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_dernier_prepay">
              {{ scope.row.date_dernier_prepay.split(' ')[0] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='montant'
          :label="$t('table.columns.MTLastCredit') + ' (€ TTC)'"
          minWidth='145'
          show-overflow-tooltip
          sortable
          v-if="rows[6].show"
          
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.montant) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='nbre_cmd'
          :label="$t('table.columns.NumberOfOrders')"
          minWidth='120'
          show-overflow-tooltip
          sortable
          v-if="rows[7].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.nbre_cmd) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='date_premiere_cmd'
          :label="$t('table.columns.DateFirstOrder')"
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[8].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_premiere_cmd">
              {{ scope.row.date_premiere_cmd.split(' ')[0] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='date_derniere_cmd'
          :label="$t('table.columns.DateLastOrder')"
          minWidth='125'
          show-overflow-tooltip
          sortable
          v-if="rows[9].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_derniere_cmd">
              {{ scope.row.date_derniere_cmd.split(' ')[0] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop=''
          label="% dévalidation"
          minWidth='145'
          show-overflow-tooltip
          sortable
          v-if="rows[10].show"
        >
          <template scope="scope">
            <template v-if="scope.row.leads_livres != 0">
              {{ formatThounsends((scope.row.leads_devalides * 100)/scope.row.leads_livres, true, 2) + ' %' }}
            </template>
            <template v-else>
              0 %
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='ca'
          :label="$t('table.columns.CAOrdered') + ' (€ HT)'"
          minWidth='130'
          show-overflow-tooltip
          sortable
          v-if="rows[11].show"
          
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca, true, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='ca_promo'
          :label="$t('table.columns.caRealizedInCodePromo') + ' (€ HT)'"
          minWidth='155'
          show-overflow-tooltip
          sortable
          v-if="rows[12].show"
          
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_promo, true, 2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop='ca_realise'
          :label="$t('table.columns.caRealizedGross') + ' (€ HT)'"
          minWidth='140'
          show-overflow-tooltip
          sortable
          v-if="rows[13].show"
          
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_realise, true, 2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop='ca_realise_net'
          :label="$t('table.columns.caRealizedNet') + ' (€ HT)'"
          minWidth='140'
          show-overflow-tooltip
          sortable
          v-if="rows[14].show"
          
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_realise_net, true, 2) }}
          </template>
        </el-table-column>
        
        <el-table-column
          prop='provenance'
          :label="$t('table.columns.Origin')"
          minWidth='150'
          show-overflow-tooltip
          sortable
          v-if="rows[15].show"
        >
        </el-table-column>
        
        <el-table-column
          prop='region'
          label="region"
          minWidth='150'
          show-overflow-tooltip
          sortable
          v-if="rows[16].show"
        >
        </el-table-column>

        <!-- <el-table-column
          prop='client'
          label="Région"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column> -->

        <!-- <el-table-column
          prop='Détails'
          label="Détails"
          minWidth='85'
        >
          <template scope="scope">
            <el-tooltip
              content="Voir les détails"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                class="btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="fas fa-info-circle"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, BaseDropdown } from 'src/components';
import gql from 'graphql-tag'
import Fuse from 'fuse.js';

export default {
  apollo: {
    statClients: {
      client: 'MARKET',
      query: gql` query (
        $date_debut: String
        $date_fin: String
        $annonceur_id: ID
        $produit_id: ID
        $charge_id: ID
        $provenance_id: ID
      ){
        
        statClients(
          date_debut: $date_debut
          date_fin: $date_fin
          annonceur_id: $annonceur_id
          produit_id: $produit_id
          charge_id: $charge_id
          provenance_id: $provenance_id
        ){
          annonceur_id
          client
          provenance
          date_inscription
          ne_pas_contacter
          date_dernier_prepay
          montant
          solde
          nbre_cmd
          ca_promo
          ca_realise_net
          date_premiere_cmd
          date_derniere_cmd
          leads_commandes
          leads_livres
          leads_devalides
          region
          ca
          ca_realise
        }
      }`,
      variables () {
        return {
          date_debut: this.StartDate,
          date_fin: this.EndnDate,
          annonceur_id: this.ClientSelects.Client,
          produit_id: this.CategorieSelects.Categorie,
          charge_id: this.CASelects.CA,
          provenance_id: this.ProvenanceSelects.Provenance
        }
      },
      result({ data, loader, networkStatus }) {
        this.SkipQuery = true
        // default sort date_derniere_cmd
        data.statClients.sort((a,b) => (a.date_derniere_cmd < b.date_derniere_cmd) ? 1 : ((b.date_derniere_cmd < a.date_derniere_cmd) ? -1 : 0));   
        // default sort date_derniere_cmd
        this.statClients = this.searchClients = data.statClients
        this.fuseSearch = new Fuse(this.statClients, this.options);
      },
      skip() {
        return this.SkipQuery
      },
      fetchPolicy: 'network-only'
    },
    Filters: {
      client: 'MARKET',
      query: gql` query {
        produits {
          pk_categorie
          designation
        }
        clients {
          fk_annonceur
          societe
        }
        provenances {
          pk_provenance_client
          nom
        }
        chargesAffaires {
          pk_charge_affaire
          nom
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.CategorieSelects.ListCategorie = data.produits
        this.ClientSelects.ListClient = data.clients
        this.ProvenanceSelects.ListProvenance = data.provenances
        this.CASelects.ListCA = data.chargesAffaires
      },
      update: data => data
    }
  },
  watch: {
    searchQuery(value) {
      console.log(value)
      let result = this.statClients;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchClients = result;
    },
    selectallrows(value) {
      this.rows.forEach(element => {
        element.show = value
      });
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    BaseDropdown
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchClients.length
    }
  },
  data() {
    return {
      selectallrows: true,
      fuseSearch: null,
      SkipQuery: false,
      searchQuery: '',
      statClients: [],
      searchClients: [],
      StartDate: '',
      EndnDate: '',
      CategorieSelects: {
        Categorie: '',
        ListCategorie: []
      },
      ClientSelects: {
        Client: '',
        ListClient: []
      },
      ProvenanceSelects: {
        Provenance: '',
        ListProvenance: []
      },
      CASelects: {
        CA: '',
        ListCA: []
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
      options : {
        threshold: 0.2,
        keys: [
          "client",
          "date_inscription"
        ]
      },
      rows: [
        { label: 'Client', show: true },
        { label: this.$t('table.columns.RegistrationDate'), show: true },
        { label: this.$t('table.columns.LeadsOrdered'), show: true },
        { label: this.$t('table.columns.LeadsDelivered'), show: true },
        { label: this.$t('table.columns.CurrentBalance') + ' (€ HT)', show: true },
        { label: this.$t('table.columns.LastCreditDate'), show: true },
        { label: this.$t('table.columns.MTLastCredit') + ' (€ TTC)', show: true },
        { label: this.$t('table.columns.NumberOfOrders'), show: true },
        { label: this.$t('table.columns.DateFirstOrder'), show: true },
        { label: this.$t('table.columns.DateLastOrder'), show: true },
        { label: '% dévalidation', show: true },
        { label: this.$t('table.columns.CAOrdered') + ' (€ HT)', show: true },
        { label: this.$t('table.columns.caRealizedInCodePromo') + ' (€ HT)', show: true },
        { label: this.$t('table.columns.caRealizedGross') + ' (€ HT)', show: true },
        { label: this.$t('table.columns.caRealizedNet') + ' (€ HT)', show: true },
        { label: this.$t('table.columns.Origin'), show: true },
        { label: 'region', show: true }
      ]
    }
  },
  methods: {
    search() {
      this.SkipQuery = false
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
        const sums = [];
        let Sum_leads_commandes = 0
        let Sum_leads_livres = 0
        let Sum_solde = 0
        let Sum_nbre_cmd = 0
        let Sum_leads_devalides = 0
        let Sum_CA_cmd = 0
        let Sum_CA_promo = 0
        let Sum_CA_realise = 0
        let Sum_CA_realise_net = 0
        this.searchClients.forEach(stat => {
          Sum_leads_commandes += stat.leads_commandes
          Sum_leads_livres += stat.leads_livres
          Sum_solde += stat.solde
          Sum_nbre_cmd += stat.nbre_cmd
          Sum_leads_devalides += stat.leads_devalides
          Sum_CA_cmd += stat.ca
          Sum_CA_promo += stat.ca_promo
          Sum_CA_realise += stat.ca_realise
          Sum_CA_realise_net += stat.ca_realise_net
        });
        
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 2 && this.rows[2].show) {
            sums[index] = this.formatThounsends(Sum_leads_commandes);
            return;
          } else if (index === 3 && this.rows[3].show) {
            sums[index] = this.formatThounsends(Sum_leads_livres);
            return;
          } else if (index === 4 && this.rows[4].show) {
            sums[index] = this.formatThounsends(Sum_solde, true, 2) + ' €';
            return;
          } else if (index === 7 && this.rows[7].show) {
            sums[index] = this.formatThounsends(Sum_nbre_cmd);
            return;
          } else if (index === 10  && this.rows[10].show) {
            sums[index] = (Sum_leads_livres != 0) ? this.formatThounsends((Sum_leads_devalides*100)/Sum_leads_livres, true, 2) + ' %' : '0 %';
            return;
          } else if (index === 11  && this.rows[11].show) {
            sums[index] = this.formatThounsends(Sum_CA_cmd, true, 2) + ' €';
            return;
          } else if (index === 12 && this.rows[12].show) {
            sums[index] = this.formatThounsends(Sum_CA_promo, true, 2) + ' €';
            return;
          } else if (index === 13 && this.rows[13].show) {
            sums[index] = this.formatThounsends(Sum_CA_realise, true, 2) + ' €';
            return;
          } else if (index === 14 && this.rows[14].show) {
            sums[index] = this.formatThounsends(Sum_CA_realise_net, true, 2) + ' €';
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
    },
    replaceUTF_8(string) {
      if(!string) 
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }
      
      return string
    },
    DownloadCSV() {
      const url_api = "https://apileadmarket.wee-do-it.net/api/export-stats_clients-csv";
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('date_debut', this.StartDate || '')
      formData.append('date_fin', this.EndnDate || '')
      formData.append('annonceur_id', this.ClientSelects.Client || '')
      formData.append('produit_id', this.CategorieSelects.Categorie || '')
      formData.append('charge_id', this.CASelects.CA || '')
      formData.append('provenance_id', this.ProvenanceSelects.Provenance || '')

      this.axios
      .post(url_api,formData , {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
        responseType: "blob"
      }).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'extract.csv');
        document.body.appendChild(link);
        link.click();
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
</style>