<template>
  <div class="content">
    <h3>{{ $t("titlePages.listOfCouponCodes") }}</h3>
    <!-- <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="ClientSelects.Client"
              placeholder="CLIENT"
              :disabled="$apollo.queries.statClients.loading"
              clearable>
              <el-option 
                v-for="option in ClientSelects.ListClient"
                class="select-primary"
                  :value="option.fk_annonceur"
                  :label="(option.societe.length > 30) ? option.societe.slice(0, 30).toUpperCase() +'...' : option.societe.toUpperCase()"
                  :key="option.id">
              </el-option>
            </el-select>
          </base-input> -->
    <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
      <base-button
        @click="ShowAddCode = true"
        class="btn btn-round btn-just-icon btn-market"
      >{{ $t("buttons.createANewCouponCode") }}</base-button>
    </div>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="RECHERCHE"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table :data="searchPromoCodes.slice(from, to)" v-loading="$apollo.queries.codesPromoList.loading" row-class-name="booking-table-rows" class="customtable_cmd" :default-sort = "{prop: 'date_debut', order: 'descending'}">
        <el-table-column
          prop='code'
          label="Code promo"
          minWidth='170'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='montant'
          :label="$t('table.columns.Amount') + ' (€ HT)'"
          minWidth='110'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.montant, true, 2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop='quantite'
          :label="$t('table.columns.amountOfUsableCodes')"
          minWidth='120'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='date_creation'
          :label="$t('table.columns.Creationdate')"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='date_debut'
          :label="$t('table.columns.Startdate')"
          minWidth='110'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='date_fin'
          :label="$t('table.columns.Enddate')"
          minWidth='100'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          label="Status"
          minWidth='140'
          show-overflow-tooltip
        >
          <template scope="scope">
            <template v-if="scope.row.annonceurs">
              {{ $t('labels.usedBy') + ' : ' + scope.row.annonceurs }}
            </template>
            <template v-else>
              {{ $t('labels.notUsed') }}
            </template>
          </template>
        </el-table-column>

        <el-table-column
          label="Action"
          minWidth='85'
        >
          <template scope="scope">

            <el-tooltip
              :disabled="$t('buttons.BtnImg.BtnRemove')"
              effect="light"
              :open-delay="300"
              placement="top"
              v-if="scope.row.etat == 'valide'"
            >
              <base-button
                @click="changeStatusPromo(scope.row, 'supprime')"
                class="btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="far fa-trash-alt"></i>
              </base-button>
            </el-tooltip>

            <el-tooltip
              content="Activer"
              effect="light"
              :open-delay="300"
              placement="top"
              v-else
            >
              <base-button
                @click="changeStatusPromo(scope.row, 'valide')"
                class="btn-link"
                type="success"
                size="sm"
                icon
              >
                <i class="far fa-check-square"></i>
              </base-button>
            </el-tooltip>

          </template>
        </el-table-column>

      </el-table>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>

     <!-- ADD PROMO CODE modal -->
     <modal
      :show.sync="ShowAddCode"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="ShowAddCode = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
          
        </template>
        <h3 slot="header" class="modal-title">
          {{ $t('table.columns.listOfCouponCodes') }}
        </h3>

        <div class="col-sm-12">
          <base-input :label="$t('labels.clientList')" :error="getError('Client')">
            <el-select class="select-primary"
              :placeholder="$t('labels.clientList')"
              filterable
              v-model="ClientSelects.Client"
              name="Client"
              v-validate="'required'">
              <el-option 
                v-for="option in ClientSelects.ListClient"
                class="select-primary"
                :value="replaceUTF_8(option.societe)"
                :label="(option.societe.length > 30) ? replaceUTF_8(option.societe.slice(0, 30).toUpperCase()) +'...' : replaceUTF_8(option.societe).toUpperCase()"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-sm-12">
          <base-input label="NOM DU CODE PROMO" :value="ClientSelects.Client+'-'+name_promocode" :disabled='true'></base-input>
        </div>

        <div class="col-sm-12">
          <base-input :label="$t('labels.amountCreditGranted') + ' (€ HT)'" v-model="amount_promocode"
            :name="$t('labels.amountCreditGranted')"
            v-validate="'required'"
            type="number"
          :error="getError($t('labels.amountCreditGranted'))"></base-input>
        </div>

        <div class="col-sm-12">
          <base-input :label="$t('labels.amountOfUsefulCouponCodes')" v-model="amount_used"
            :name="$t('labels.amountOfUsefulCouponCodes')"
            v-validate="'required'"
            type="number"
          :error="getError($t('labels.amountOfUsefulCouponCodes'))"></base-input>
        </div>

        <base-input label="date début" class="col-sm-12">
          <el-date-picker v-model="StartDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
        </base-input>

        <base-input label="date fin" class="col-sm-12">
          <el-date-picker v-model="EndnDate" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
        </base-input>

        <div  class="text-center">
          <base-button
            type="info"
            @click.native="addPromoCode"
            >{{ $t('buttons.BtnAdd').toUpperCase() }}
          </base-button>
        </div>


      </card>
    </modal>
    <!-- ADD PROMO CODE modal -->
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, Modal } from 'src/components';
import gql from 'graphql-tag'
import Fuse from 'fuse.js';
import swal from 'sweetalert2'

let today = new Date()

export default {
  apollo: {
    codesPromoList: {
      client: 'MARKET',
      query: gql` query {        
        codesPromoList {
          codepromo_id
          annonceur_id
          code
          montant
          date_creation
          date_limite
          date_debut
          date_fin
          quantite
          etat
          annonceurs
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.SkipQuery = true
        // default sort date_debut
        data.codesPromoList.sort((a,b) => (a.date_debut < b.date_debut) ? 1 : ((b.date_debut < a.date_debut) ? -1 : 0));   
        // default sort date_debut
        this.PromoCodes = this.searchPromoCodes = data.codesPromoList
        this.fuseSearch = new Fuse(this.PromoCodes, this.options);
      },
      skip() {
        return this.SkipQuery
      },
      fetchPolicy: 'network-only'
    },
    Filters: {
      client: 'MARKET',
      query: gql` query {
        clients {
          fk_annonceur
          societe
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ClientSelects.ListClient = data.clients
      },
      update: data => data
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.PromoCodes;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchPromoCodes = result;
    },
    ShowAddCode(value) {
      if (value) {
        const key = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const length = 7
        this.name_promocode = this.str_shuffle(key).substring(0, length)
        this.name_promocode += "-" + today.getFullYear()
      }
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    Modal
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchPromoCodes.length
    }
  },
  data() {
    return {
      ShowAddCode: false,
      fuseSearch: null,
      SkipQuery: false,
      searchQuery: '',
      PromoCodes: [],
      searchPromoCodes: [],
      StartDate: '',
      EndnDate: '',
      name_promocode: '',
      amount_promocode: '',
      amount_used: 1,
      ClientSelects: {
        Client: '',
        ListClient: []
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
      options : {
        threshold: 0.2,
        keys: [
          "code",
          "montant",
          "quantite",
          "date_creation",
          "date_debut",
          "date_fin",
          "annonceurs"
        ]
      }
    }
  },
  methods: {
    search() {
      this.SkipQuery = false
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async addPromoCode() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return
      
      if (!this.StartDate) {
        this.$notify({type: 'warning', message: "vous n'avez pas renseigner la date de début" })
        return
      }

      if (!this.EndnDate) {
        this.$notify({type: 'warning', message: "vous n'avez pas renseigner la date fin" })
        return
      }

      this.$apollo.mutate({
        client: 'MARKET',
        mutation: gql`mutation ($date_debut: String!, $date_fin: String!, $montant: Float!, $code: String!, $quantite: Int!) {
          addCodePromo(date_debut: $date_debut, date_fin: $date_fin, montant: $montant, code: $code, quantite: $quantite) {
            state
          }
        }`,
        variables: {
          date_debut: this.StartDate,
          date_fin: this.EndnDate,
          montant: this.amount_promocode,
          code: this.ClientSelects.Client+'-'+this.name_promocode,
          quantite: this.amount_used,
        },
      }).then(response => {
        if (response.data.addCodePromo.state) {
          this.$notify({type: 'success', message: "code promo créé avec succès" })
          this.SkipQuery = false
          this.$apollo.queries.codesPromoList.refetch({})
          this.ShowAddCode = false
          this.ClientSelects.Client = ''
          this.amount_promocode = ''
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        }
                      
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
      })
      
    },
    str_shuffle(string) {
      var parts = string.split('');
      for (var i = parts.length; i > 0;) {
        var random = parseInt(Math.random() * i);
        var temp = parts[--i];
        parts[i] = parts[random];
        parts[random] = temp;
      }
      return parts.join('');
    },
    appendLeadingZeroes(n){
      if(n <= 9){
        return "0" + n;
      }
      return n
    },
    replaceUTF_8(string) {
      if(!string) 
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }
      
      return string
    },
    changeStatusPromo(row, status) {
      swal.fire({
        title: this.$t("labels.areYouSure"),
        text: this.$t("labels.youAreChangingTheState"),
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: this.$t("buttons.BtnModify"),
        cancelButtonText: this.$t("buttons.BtnCancel")
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation (
              $code_promo_id: ID!
              $etat: String!
            ) {
              deleteCodePromo(
                code_promo_id: $code_promo_id
                etat: $etat
              ){
                state
              }
            }`,
            variables: {
              code_promo_id: row.codepromo_id,
              etat: status
            },
          }).then(response => {
            if (response.data.deleteCodePromo.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              row.etat = status
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    }
  },
  created() {
    this.StartDate = today.getFullYear()+"-"+this.appendLeadingZeroes(today.getMonth() + 1)+"-"+this.appendLeadingZeroes(today.getDate())
    this.EndnDate = today.getFullYear()+"-"+this.appendLeadingZeroes(today.getMonth() + 1)+"-"+this.appendLeadingZeroes(today.getDate())
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
</style>