<template>
  <div class="factures-avoir col-12 background-repoort">
    <div class="row" style="margin-top: 80px;">
      <div class="col-12">
        <div class="col-sm-12 d-flex justify-content-between my-4">
          <h2 class="card-title mb-0 pt-2">
            LES FACTURES D'AVOIR
          </h2>
          <base-button
            class="btn btn-round btn-just-icon btn-info"
            @click.native="setupManageFactureData('add')"
          >{{ "Ajouter Avoir".toUpperCase()}}</base-button>
        </div>
        <div class="col-md-12">
          <card>
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-8 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input class="col-md-3">
                      <el-select filterable class="select-primary"
                        v-model="PeriodeSelects.Periode.annee"
                        placeholder="ANNEE"
                        clearable
                      >
                        <el-option
                            v-for="option in PeriodeSelects.ListPeriodeAnnee"
                            class="select-primary"
                            :value="option"
                            :label="option"
                            :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-3">
                      <el-select filterable class="select-primary"
                        v-model="PeriodeSelects.Periode.mois"
                        placeholder="MOIS"
                        clearable
                      >
                        <el-option
                          v-for="option in PeriodeSelects.ListPeriodeMois"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-3">
                      <el-select filterable class="select-primary"
                        v-model="ClientSelects.Client"
                        placeholder="CLIENT"
                        :disabled="Loading.listClient"
                        clearable>
                        <el-option
                          v-for="option in ClientSelects.ListClient"
                          class="select-primary"
                          :value="option.id"
                          :label="(option.society.length > 30) ? replaceUTF_8(option.society.slice(0, 30)).toUpperCase() +'...' : replaceUTF_8(option.society).toUpperCase()"
                          :key="option.id"
                          >
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-3">
                      <el-select filterable class="select-primary"
                        placeholder="PLATEFORME"
                        clearable
                        v-model="PlatformeSelects.Platforme">
                        <el-option
                          v-for="option in PlatformeSelects.ListPlatform"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                        </el-option>
                      </el-select>
                   </base-input>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-12 order-xl-1 order-3">
                  <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                    <button
                      @click="ResetSearch()"
                      class="btn btn-round btn-just-icon btn-primary mt-0 pull-right"
                    >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                    <button
                      v-if="!Loading.search"
                      @click="Search()"
                      class="btn btn-round btn-just-icon btn-primary mt-0"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                    <base-button
                      v-else
                      loading
                      round
                      type="primary"
                      class="btn btn-round btn-just-icon btn-primary mt-0"
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card>
            <div class="row">
              <div class="col-xl-1 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-8 col-sm-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
              <base-input class="col-xl-3 col-sm-6">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="RECHERCHE"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="searchListFactures.slice(from, to)" row-class-name="booking-table-rows" show-summary :summary-method="getSummaries">
              <el-table-column
                prop='id'
                label="N°"
                minWidth='90'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='societe_client'
                label="Client"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prix'
                label="Montant ( € HT )"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThousands((scope.row.prix * scope.row.quantite), true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                prop='periode'
                label="Période"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatPeriode(scope.row.periode_mois, scope.row.periode_annee) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='plateforme'
                label="Plateforme"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
               <template scope="scope">
                  {{ getPlateformeName(scope.row.plateforme) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Action"
                minWidth="100"
              >
              <template scope="scope">
                <el-tooltip
                  content="Télécharger"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    class="btn-link"
                    type="info"
                    size="sm"
                    @click="DownloadAvoir(scope.row)"
                    icon
                  >
                    <i class="fas fa-cloud-download-alt"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Modifier"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    @click.native="setupManageFactureData('edit',scope.row)"
                    :disabled="!!scope.row.send"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Envoyer"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :disabled="!!scope.row.send"
                    class="btn-link"
                    type="success"
                    size="sm"
                    @click="SendAvoir(scope.row)"
                    icon
                  >
                    <i class="fas fa-paper-plane"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                type="market"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>

    <manage-facture-avoir-modal
      v-if="showManageFactureModal"
      :data="manageFactureData"
      @close="resetManageFacture()"
      @facture-added="AddFactureToFront"
      @facture-edited="EditFactureOnFront"
    />
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination } from 'src/components'
import ManageFactureAvoirModal from './ManageFactureAvoirModal.vue'
import Fuse from 'fuse.js';
import moment from 'moment'


const GET_YEARS_LIST = () => {
  const startYear = 2016
  const currentYear = moment().year()

  const yearList = []
  for (let year = startYear; year <= currentYear; year++) {
    yearList.push(year)
  }
  return yearList.reverse()
}

const API_URL = "https://api-auth-plf.wee-do-it.net/api/v1"

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    ManageFactureAvoirModal
  },
  data() {
    return {
      showManageFactureModal: false,
      manageFactureData: {},
      Loading: {
        listClient: true,
        search: false
      },
      searchQuery: '',
      fuseSearch: null,
      fuseOptions : {
        threshold: 0.2,
        keys: [
          "societe_client",
          "periode_mois",
          "periode_annee",
          "num_facture",
          "num_client_facture",
          "prix",
          "plateforme"
        ]
      },
      ClientSelects: {
        Client: '',
        ListClient: []
      },
      PeriodeSelects: {
        Periode: {
          mois: '',
          annee : ''
        },
        ListPeriodeAnnee: GET_YEARS_LIST(),
        ListPeriodeMois: [
          { value: 1, label: "JANVIER" },
          { value: 2, label: "FÉVRIER" },
          { value: 3, label: "MARS" },
          { value: 4, label: "AVRIL" },
          { value: 5, label: "MAI" },
          { value: 6, label: "JUIN" },
          { value: 7, label: "JUILLET" },
          { value: 8, label: "AOÛT" },
          { value: 9, label: "SEPTEMBRE" },
          { value: 10, label: "OCTOBRE" },
          { value: 11, label: "NOVEMBRE" },
          { value: 12, label: "DÉCEMBRE" }
        ]
      },
      PlatformeSelects: {
        Platforme: '',
        ListPlatform: [
          { value: "perf", label: "WEEDO PERF" },
          { value: "coreg", label: "WEEDO COREG" },
          { value: "market", label: "WEEDO MARKET" },
          { value: "mongustave", label: "MONGUSTAVE" }
        ]
      },
      ListFactures: [],
      searchListFactures: [],
      total_montant_ht: 0,
      pagination: {
        perPage: 250,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500, 1000],
        total: 0
      }
    }
  },
  created() {
    this.getFacturesList()
    this.getClientsList()
  },
  watch: {
    searchQuery(value) {
      let result = this.ListFactures
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchListFactures = result
    },
    searchListFactures(newList, oldList) {
      this.total_montant_ht = 0
      newList.forEach(facture => {
        this.total_montant_ht += (facture.prix * facture.quantite)
      })
    },
    ListFactures(newList, oldList) {
      this.searchListFactures = newList
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.ListFactures.length
    }
  },
  methods: {
    getFacturesList() {
      const endpoint = `${API_URL}/list_facture_avoir`
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.post(
        endpoint,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`,
          }
        }
      )
        .then(({ data }) => {
          this.ListFactures = data.response.facture_avoir
          this.fuseSearch = new Fuse(this.ListFactures, this.fuseOptions)
        })
        .catch(error => {
          console.log('get list facture error')
        })
    },
    getClientsList() {
      const endpoint = `${API_URL}/list_all_annonceurs`
      const apiToken = localStorage.getItem('token_jwt')

      this.axios.post(
        endpoint,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${apiToken}`,
          }
        }
      )
        .then(({ data }) => {
          this.ClientSelects.ListClient = data.response.Annonceurs
          this.Loading.listClient = false
        })
        .catch(error => {
          console.log('get clients list error')
          this.Loading.listClient = false
        })
    },
    setupManageFactureData(Action, facture = null) {
      this.manageFactureData = {
        Action,
        ListClient: [...this.ClientSelects.ListClient],
      }

      if(facture) {
        this.manageFactureData.Facture = {...facture}
      }

      this.showManageFactureModal = true
    },
    resetManageFacture() {
      this.showManageFactureModal = false
      this.manageFactureData = null
    },
    AddFactureToFront(facture) {
      this.resetManageFacture()
      this.ListFactures = [...this.ListFactures, facture]

      this.ResetSearch()
      this.clearSearchQuery()

      this.$notify({type: 'success', message: "facture créé avec succés"})
    },
    EditFactureOnFront(facture) {
      this.resetManageFacture()

      const modifiedFactureIndex = this.ListFactures.findIndex(f => f.id == facture.id)
      this.ListFactures.splice(modifiedFactureIndex, 1, facture)

      this.ResetSearch()
      this.clearSearchQuery()

      this.$notify({type: 'success', message: "facture modifiée avec succés"})
    },
    clearSearchQuery() {
      this.searchQuery = ''
    },
    replaceUTF_8(string) {
      if(!string)
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }

      return string
    },
    Search() {

      this.searchListFactures = []

      this.Loading.search = true

      const searchBy = {
        periode_annee: this.PeriodeSelects.Periode.annee,
        periode_mois: this.PeriodeSelects.Periode.mois,
        client_id: this.ClientSelects.Client,
        plateforme: this.PlatformeSelects.Platforme
      }
      const searchByKeys = Object.keys(searchBy)

      this.searchListFactures = this.ListFactures.filter(facture => {

        let keepFacture = true

        searchByKeys.forEach(key => {
          if(searchBy[key] != '' && searchBy[key] != facture[key]){
            keepFacture = false
          }
        })

        return keepFacture
      })

      setTimeout(() => {
        this.Loading.search = false
      },500)
    },
    ResetSearch() {
      this.PeriodeSelects.Periode.annee = ''
      this.PeriodeSelects.Periode.mois = ''
      this.ClientSelects.Client = ''
      this.PlatformeSelects.Platforme = ''

      this.Search()
    },
    formatThousands: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    formatPeriode(moisInt, annee) {
      let mois = this.PeriodeSelects.ListPeriodeMois.find(el => el.value == moisInt)
      return mois ? mois.label+" "+annee : moisInt+"/"+annee
    },
    getPlateformeName(plateforme) {
      return this.PlatformeSelects.ListPlatform.find(el => el.value == plateforme).label
    },
    DownloadAvoir(row) {
      const url_api =  `${API_URL}/pdf_facture_avoir`
      let accessToken = localStorage.getItem("token_jwt")
      let formData = new FormData()
      let periode = this.appendLeadingZeroes(row.periode_mois)+"-"+row.periode_annee
      formData.append('id', row.id)
      this.axios.post(url_api,formData , {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ${accessToken}`
        },
        responseType: "blob"
      }).then(function(response) {
        const filename = "facture-" + periode + "-"+row.client_id+".pdf"
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", filename)
        document.body.appendChild(fileLink)
        fileLink.click()

      }.bind(this))
      .catch(function (error) {
        console.log(error.message)
      })
    },
    SendAvoir(row) {
      const url_api = `${API_URL}/send_facture_avoir`
      //const url_api = "http://localhost/api-auth-plf-weedoit/public/api/v1/send_facture_avoir"

      let accessToken = localStorage.getItem("token_jwt")

      let formData = new FormData()
      formData.append('id', row.id)

      this.axios.post(url_api, formData ,{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: `Bearer ${accessToken}`
        }
      })
      .then(({ data }) => {
        const message = data.message
        if(message && message == "Le mail est bien envoyé") {
          row.send = 1
        }
        this.$notify({type: 'success', message: 'Facture est envoyée avec succès' })
      })
      .catch(error => {
        this.$notify({type: 'warning', message: "l'envoi a échoué" })
      })
    },
    appendLeadingZeroes(n){
      if(n <= 9){
        return "0" + n
      }
      return n
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 2) {
          sums[index] = this.formatThousands(this.total_montant_ht, true, 2) + " €";
          return;
        } else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    }
  }
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }

  .factures-avoir .fixedBtn {
    position: fixed;
    bottom: 35px;
    right: 35px;
    z-index: 99999;
  }
</style>
