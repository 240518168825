<template>
  <div class="content">
    <h3>{{ $t("titlePages.CustomerOrdersDetails") }}</h3>
    <card>
      <div class="row">
        <base-input class="col-md-3">
          <el-date-picker v-model="StartDate" :disabled="$apollo.queries.commandesList.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
        </base-input>
        <base-input class="col-md-3">
          <el-date-picker v-model="EndnDate" :disabled="$apollo.queries.commandesList.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="CategorieSelects.Categorie"
            :disabled="$apollo.queries.commandesList.loading"
            :placeholder="$t('table.columns.Product').toUpperCase()"
            clearable>
            <el-option 
              v-for="option in CategorieSelects.ListCategorie"
              class="select-primary text-uppercase"
              :value="option.pk_categorie"
              :label="option.designation.replace(/Ã©/g,'é')"
              :key="option.pk_categorie">
            </el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="Etat"
            :disabled="$apollo.queries.commandesList.loading"
            :placeholder="$t('table.columns.DeliveryState')"
            clearable
          >
            <el-option class="select-primary" value="encours" :label="$t('labels.InProgress')"></el-option>
            <el-option class="select-primary" value="pause" label="EN PAUSE"></el-option>
            <el-option class="select-primary" value="termine" :label="$t('labels.Completed')"></el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="DaysLivred"
            :disabled="$apollo.queries.commandesList.loading"
            placeholder="JOURS DE LIVRAISON"
            clearable
            multiple
            >
            <el-option 
              v-for="day in Dayslist"
              class="select-primary text-uppercase"
              :value="day"
              :label="day"
              :key="day">
            </el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="Type_sante"
            :disabled="$apollo.queries.commandesList.loading"
            placeholder="TYPE"
            clearable
          >
            <el-option class="select-primary" value="salaries" label="SALARIES"></el-option>
            <el-option class="select-primary" value="seniors" label="SENIORS"></el-option>
          </el-select>
        </base-input>
        <base-input class="col-md-3">
          <el-select filterable class="select-primary"
            v-model="Capping_complete"
            :disabled="$apollo.queries.commandesList.loading"
            placeholder="CAPPING DU JOUR"
            clearable
          >
            <el-option class="select-primary" value="oui" label="OUI"></el-option>
            <el-option class="select-primary" value="non" label="NON"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-xs-4 text-right">
        <base-button 
          v-if="$apollo.queries.commandesList.loading" 
          loading 
          class="btn btn-default" 
        >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
        <button 
          v-else
          class="btn btn-default" 
          @click="search"
        >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
      </div>
    </card>
    <card class="listcommande">
      <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle" class="btn-link" type="market" size="sm" icon>
        <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
      </base-button>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch2').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <!-- <el-tooltip
        content="Valider"
        effect="light"
        :open-delay="300"
        placement="top"
      > -->
        <!-- <base-button
          @click="ValiderCmdPromo()"
          style="margin-left: 250px;margin-top: -15px;position: absolute;"
          type="notif"
          size="sm"
        >
        <i class="fas fa-check-square icon-large"></i> VALIDER
        </base-button> -->
        <base-dropdown menu-classes="dropdown-black">
          <template slot="title-container">
            <button class="btn btn-icon btn-round btn-market">
              <i class="tim-icons el-icon-view"></i>
            </button>
          </template>
            <div class="dropdown-header">
              <base-checkbox v-model="selectallrows">
                {{ $t('labels.SelectAll') }}
              </base-checkbox>
            </div>
            <template v-for="row in rows">
              <div class="dropdown-header">
                <base-checkbox v-model="row.show">
                  {{ row.label }}
                </base-checkbox>
              </div>
            </template>
        </base-dropdown>
      <!-- </el-tooltip> -->
      <el-table :data="filterCommandes" v-loading="$apollo.queries.commandesList.loading" row-class-name="booking-table-rows" class="customtable_cmd" show-summary :summary-method="getSummaries">
        
        <el-table-column
          width="80"
        >
          <template scope="scope">            
            <div class="status_photo mr-2 float-left">
              <img alt="EXCLUSIF" v-if="scope.row.ligne_commande[0].pack == 'exclusif'" class="img-fluid" src="/img/lead_exclusif.png" />
              <img alt="DUO" v-if="scope.row.ligne_commande[0].pack == 'duo'" class="img-fluid" src="/img/lead_duo.png" />
              <img alt="TRIO" v-if="scope.row.ligne_commande[0].pack == 'trio'" class="img-fluid" src="/img/lead_trio.png" />
            </div>
          </template>
        </el-table-column>

        <el-table-column type="expand">
          <template scope="scope">
            <template v-for="(item, index) in scope.row.ligne_commande">
              <p class="text-uppercase" v-if="item.pack">{{ item.quantite + " leads " + item.pack + "  au prix de  " + item.prix_unitaire + " €" }}</p>
              <p class="text-uppercase" v-if="item.pack">{{ "Taux dévalidation " + item.pack + " : " + formatThounsends((item.nbre_devalide / item.quantite) * 100 , true, 2) + " %" }}</p>
            </template>
            <p class="text-uppercase" v-if="scope.row.jours">JOURS: {{ scope.row.jours }}</p>
            
            <p class="text-uppercase" v-if="scope.row.heure_debut_livraison">Heure livraison de : {{ scope.row.heure_debut_livraison }} à {{ scope.row.heure_fin_livraison }}</p>
            <p class="text-uppercase" v-if="scope.row.db_livraison"> à partir de : {{ scope.row.db_livraison }} </p>
            <p class="text-uppercase" v-if="scope.row.filtre"><p class="text-uppercase" v-html="'FILTRE : '+replaceUTF_8(scope.row.filtre)"></p> </p>
          </template>
        </el-table-column>

        <el-table-column
          prop='pk_commande'
          label='N°'
          minWidth='70'
          show-overflow-tooltip
          sortable
          v-if="rows[0].show"
        >
        </el-table-column>
        <el-table-column
          prop='date_commande'
          label='Date'
          minWidth='105'
          show-overflow-tooltip
          sortable
          v-if="rows[1].show"
        >
         <!-- <template scope="scope">
          {{ scope.row.date_commande }}
          <template>
            {{ scope.row.delai_livraison }}
            {{ scope.row.date_fin_commande }}
          </template>
         </template> -->
        </el-table-column>
        <el-table-column
          prop='cmd_promo'
          label='promo'
          minWidth='85'
          show-overflow-tooltip
          sortable
          v-if="rows[2].show"
        >
          <template scope="scope">
            <base-switch :value="scope.row.cmd_promo" @input="CheckSwitchPromo(scope.row)">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </base-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop='societe'
          label='Client'
          minWidth='155'
          show-overflow-tooltip
          sortable
          v-if="rows[3].show"
        >
        <template scope="scope">
          <template v-if="scope.row.cmd_promo">
            <span style="color:#FF9800; font-size: 17px;">
              <i class="fa fa-star" title="Commande en promo"></i>
            </span>
          </template>
          {{ scope.row.societe }}
         </template>
        </el-table-column>
        <el-table-column
          prop='montant'
          :label='$t("table.columns.AmountOrder") + " (€ HT)"'
          minWidth='130'
          show-overflow-tooltip
          sortable
          v-if="rows[4].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.montant, true, 2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='designation'
          :label='$t("table.columns.Product")'
          minWidth='100'
          show-overflow-tooltip
          sortable
          v-if="rows[5].show"
        >
          <template scope="scope">
            <i :class="icons[scope.row.designation.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.designation.replace(/Ã©/g,'é').toUpperCase()"></i>
          </template>
        </el-table-column>
        <el-table-column
          prop='quantite'
          :label='$t("table.columns.TotalQuantity") + " (LEADS)"'
          minWidth='100'
          show-overflow-tooltip
          sortable
          v-if="rows[6].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.quantite) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='tt_qte_restant'
          :label='$t("table.columns.RemainingQuantity") + " (LEADS)"'
          minWidth='110'
          show-overflow-tooltip
          sortable
          v-if="rows[7].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.tt_qte_restant) }}
          </template>
        </el-table-column>
        <el-table-column
          prop='nbre_devalide'
          :label='"% "+ $t("table.columns.Invalidation")'
          minWidth='145'
          show-overflow-tooltip
          sortable
          v-if="rows[8].show"
        >
          <template scope="scope">
            <template v-if="scope.row.nbre_devalide > 0 && scope.row.nbre_valide > 0">
              {{ ( (scope.row.nbre_devalide / scope.row.nbre_valide * 100) <= 15 ) ? formatThounsends(scope.row.nbre_devalide / scope.row.nbre_valide * 100, true, 2) + ' %' : '15 %' }}
            </template>
            <template v-else>
              0 %
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop=''
          :label='$t("table.columns.State")'
          minWidth='80'
          sortable
          v-if="rows[9].show"
        >
          <template scope="scope">
            <template v-if="scope.row.etat || scope.row.date_fin_commande">
              <base-button
                class="btn-link"
                type="notif"
                size="sm"
                icon
              >
                <i class="fas fa-check icon-large" :title="$t('labels.Ended')"></i>
              </base-button>
            </template>
            <template v-else>
              <template v-if="scope.row.statut === 'pause'">
                <!-- <a style="cursor:pointer" @click="ChangerStatutCommande('relance', scope.row)"><span style="color:green"><i class="fa fa-play" title="Relancer la commande"></i></span></a>
                &nbsp;&nbsp;En pause -->
                <base-button
                  @click="ChangerStatutCommande('relance', scope.row)"
                  class="btn-link"
                  type="success"
                  size="sm"
                  icon
                >
                  <i class="fas fa-play icon-large" :title="$t('labels.RelaunchOrder')"></i>
                </base-button>
                <br>
              </template>
              <template v-else>
                <!-- <a style="cursor:pointer" @click="ChangerStatutCommande('pause', scope.row)"><span style="color:red"><i class="fa fa-pause" title="Mettre en pause la commande"></i></span></a>
                &nbsp;&nbsp;En cours -->
                <base-button
                  @click="ChangerStatutCommande('pause', scope.row)"
                  class="btn-link"
                  type="warning"
                  size="sm"
                  icon
                >
                  <i class="fas fa-pause icon-large" :title="$t('labels.PauseOrder')"></i>
                </base-button>
                <br>
              </template>
              <!-- <a style="cursor:pointer" @click="ChangerStatutCommande('annuler', scope.row)"><span style="color:red"><i class="fa fa-times-circle" title="Annuler la commande"></i></span></a>
              &nbsp;&nbsp;Annuler<br> -->
              <base-button
                @click="ChangerStatutCommande('annuler', scope.row)"
                class="btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="far fa-window-close icon-large" :title="$t('labels.CancelOrder')"></i>
              </base-button>
              <br>
              <template v-if="!scope.row.destockage">
                <!-- <a style="cursor:pointer" @click="openEdit(scope.row.pk_commande)"><span style="color:blue"><i class="fa fa-edit" title="Modifier la commande"></i></span></a>&nbsp;&nbsp;Modifier<br> -->
                <base-button
                  @click="openEdit(scope.row.pk_commande)"
                  class="btn-link"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="fas fa-edit icon-large" :title="$t('labels.ModifyOrder')"></i>
                </base-button>
                <br>
              </template>
              <!-- <a @click="retraitPause(scope.row)" style="cursor:pointer">
                <i class="fa fa-ban" style="color:black"></i>&nbsp;&nbsp;
                <span>{{ (!scope.row.retrait_pause) ? 'Retrait mise en pause et capping' : 'Ajout mise en pause et capping' }}</span>
              </a> -->
              <base-button
                @click="retraitPause(scope.row)"
                class="btn-link"
                :type="(scope.row.retrait_pause == 1) ? 'warning' : 'success'"
                size="sm"
                icon
              >
                <i class="fas fa-ban icon-large" :title="(scope.row.retrait_pause == 1) ? 'Retrait mise en pause et capping' : 'Ajout mise en pause et capping'"></i>
              </base-button>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='lead_par_jour'
          label='Capping'
          minWidth='110'
          show-overflow-tooltip
          sortable
          v-if="rows[10].show"
        >
          <template scope="scope">
            <template v-if="!scope.row.date_fin_commande || scope.row.lead_par_jour">
              <template v-if="scope.row.date_fin_commande">
                <base-input :value="scope.row.lead_par_jour" disabled></base-input>
              </template>
              <template v-else>
                <base-input :value="scope.row.lead_par_jour" type="number" @keyup.enter.native="changeCapping($event, scope.row)"></base-input>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='capping_livre'
          :label='$t("table.columns.DayCappingDelivery")'
          minWidth='110'
          show-overflow-tooltip
          sortable
          v-if="rows[11].show"
        >
          <template scope="scope">
            <template v-if="scope.row.lead_par_jour">
              <template v-if="scope.row.capping_livre >= scope.row.lead_par_jour">
                <label style="color: green">
                  {{ scope.row.capping_livre }}
                </label>
              </template>
              <template v-else>
                <label style="color: rgb(192, 35, 35)">
                  {{ scope.row.capping_livre }}
                </label>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='date_estime'
          :label='$t("table.columns.DayCappingDelivery")'
          minWidth='135'
          show-overflow-tooltip
          sortable
          v-if="rows[12].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_estime != ''">
              {{ 'Sous ' + scope.row.date_estime }}
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='date_fin_commande'
          :label='$t("table.columns.Enddate")'
          minWidth='100'
          show-overflow-tooltip
          sortable
          v-if="rows[13].show"
        >
          <template scope="scope">
            <template v-if="scope.row.date_fin_commande">
              {{ scope.row.date_fin_commande.split(' ')[0] }}
            </template>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop='capping_livre'
          label='Ca estime du jour'
          minWidth='120'
          v-if="rows[14].show"
          sortable
          :sort-method="CustomSort_CaEstime"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.lead_par_jour * scope.row.ligne_commande[0].prix_unitaire, true, 2) + ' €' }}
          </template>
        </el-table-column>
        <el-table-column
          prop='capping_livre'
          label='CA réel du jour'
          minWidth='130'
          v-if="rows[15].show"
          sortable
          :sort-method="CustomSort_CaReel"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.capping_livre * scope.row.ligne_commande[0].prix_unitaire, true, 2) + ' €' }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop='ca_estimer_globale'
          label='CA estime'
          minWidth='130'
          sortable
          v-if="rows[15].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_estimer_globale, true, 2) + ' €' }}
          </template>
        </el-table-column>
        <el-table-column
          prop='ca_reel_globale'
          label='CA réel'
          minWidth='130'
          sortable
          v-if="rows[15].show"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_reel_globale, true, 2) + ' €' }}
          </template>
        </el-table-column>
        <el-table-column
          prop='delai_livraison'
          :label='$t("table.columns.DeliveryTime")'
          minWidth='140'
          show-overflow-tooltip
          sortable
          v-if="rows[16].show"
        >
          <template scope="scope">
            <template v-if="scope.row.delai_livraison === 'immediat'">
              Immédiat
            </template>
            <template v-else-if="scope.row.lead_par_jour === null">
              Illimité
            </template>
            <template v-else-if="scope.row.delai_livraison === 'etale'">
              {{ 'Etalé / ' + scope.row.lead_par_jour + ' leads par jour ' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='geolocalisation'
          :label='$t("table.columns.Geolocation")'
          minWidth='200'
          show-overflow-tooltip
          sortable
          v-if="rows[17].show"
        >
          <template scope="scope">
            <template v-if="scope.row.geolocalisation">
              {{ 'Departements '+ ((scope.row.exclure)?'exclus: ':'choisis: ') + scope.row.geolocalisation }} <br>
            </template>
            <template v-if="scope.row.fk_categorie === '1' && scope.row.options">
              {{ 'option choisi : ' + scope.row.options }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop=''
          label='Détails'
          minWidth='90'
          v-if="rows[18].show"
        >
          <template scope="scope">
            <el-tooltip
              content="Détails"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click="openDetails(scope.row.pk_commande)"
                class="btn-link"
                type="market"
                size="sm"
                icon
              >
                <i class="fas fa-info-circle icon-large"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
        
      </el-table>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, BaseSwitch, BaseDropdown } from 'src/components';
import StatsCard from 'src/components/Cards/StatsCard';
import Fuse from 'fuse.js';
import gql from 'graphql-tag'
import swal from 'sweetalert2'
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";
import PerfectScrollbar from 'perfect-scrollbar';
import moment from 'moment';
moment.locale('fr');


export default {
  apollo: {
    produits: {
      client: 'MARKET',
      query: gql` query {
        produits {
          pk_categorie
          designation
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.CategorieSelects.ListCategorie = data.produits
      }
    },
    commandesList: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/commandesList.gql"),
      variables () {
        return {
          date_debut: this.StartDate,
          date_fin: this.EndnDate,
          etat: this.Etat,
          categorie_id: this.CategorieSelects.Categorie
        }
      },
      result({ data, loader, networkStatus }) {
        this.ListCommande = this.searchCommande = data.commandesList.commandes
        this.fuseSearch = new Fuse(this.ListCommande, this.options);
        this.ListCommande.forEach(cmd => {
          cmd.ca_estimer_globale = this.calculateCaEstimer(cmd);
          if (!this.StartDate && !this.EndnDate) {
            cmd.ca_reel_globale = cmd.capping_livre * cmd.ligne_commande[0].prix_unitaire
          } else {
            cmd.ca_reel_globale = cmd.details[0] ? cmd.details[0].ca : 0
          }
        });
        this.SkipQuery = true
      },
      fetchPolicy: 'network-only',
      skip () {
        return this.SkipQuery
      }
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.ListCommande;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchCommande = result;
    },
    selectallrows(value) {
      this.rows.forEach(element => {
        element.show = value
      });
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StatsCard,
    BasePagination,
    BaseSwitch,
    BaseDropdown
  },
  data() {
    return {
      total: 0,
      Dayslist: [
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
        "dimanche"
      ],
      Type_sante: "",
      Capping_complete: "",
      DaysLivred: [moment().format('dddd')],
      fullscreen: false,
      PerfectScrollbar: null,
      icons: Icons,
      Sum_montant: 0,
      Sum_quantite: 0,
      sum_quantite_rst: 0,
      sum_capping: 0,
      sum_capping_livred: 0,
      sum_ca_estimer_par_jour: 0,
      sum_ca_temp_reel: 0,
      SkipQuery: true,
      searchCommande: [],
      ListCommande: [],
      StartDate: null,
      EndnDate: null,
      fuseSearch: null,
      searchQuery: '',
      CategorieSelects: {
        Categorie: '',
        ListCategorie: []
      },
      Etat: 'encours',
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
      options : {
        threshold: 0.2,
        keys: [
          "pk_commande",
          "date_commande",
          "societe",
          "montant",
          "designation",
          "quantite",
          "tt_qte_restant",
          "nbre_devalide",
          "statut",
          "date_estime",
          "date_fin_commande",
          "delai_livraison",
          "geolocalisation"
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      selectallrows: false,
      rows: [
        { label: 'N°', show: false },
        { label: 'Date', show: true },
        { label: 'Commande en promo', show: true },
        { label: 'Client', show: true },
        { label: this.$t("table.columns.AmountOrder") + ' (€ HT)', show: true },
        { label: this.$t("table.columns.Product"), show: true },
        { label: this.$t("table.columns.TotalQuantity") + ' (leads)', show: true },
        { label: this.$t("table.columns.RemainingQuantity") + ' (leads)', show: true },
        { label: '% ' + this.$t("table.columns.Invalidation"), show: false },
        { label: this.$t("table.columns.State"), show: true },
        { label: 'Capping', show: true },
        { label: this.$t("table.columns.DayCappingDelivery"), show: true },
        { label: this.$t("table.columns.CommunicatedDeliveryDate"), show: false },
        { label: this.$t("table.columns.OrderEndDate"), show: false },
        { label: "Ca estime du jour", show: true },
        { label: "CA réel du jour", show: true },
        { label: this.$t("table.columns.DeliveryTime"), show: true },
        { label: this.$t("table.columns.Geolocation"), show: true },
        { label: 'Détails', show: true }
      ]
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    filterCommandes() {
      var list = null;
      try {
        list = this.searchCommande.filter(cmd => {
          return (
            ((cmd.jours+'').toLowerCase().match(this.DaysLivred) || cmd.jours === null) &&
            (cmd.options+'').match(this.Type_sante) &&
            ( 
              this.Capping_complete ?
              (
                this.Capping_complete == "oui" ? 
                cmd.capping_livre >= cmd.lead_par_jour
                : cmd.capping_livre < cmd.lead_par_jour
              )
              : true
            )
          );
        });
        // sommme : 
        this.Sum_montant = 0
        this.Sum_quantite = 0
        this.sum_quantite_rst = 0
        this.sum_capping = 0
        this.sum_capping_livred = 0
        this.sum_ca_estimer_par_jour = 0
        this.sum_ca_temp_reel = 0
        list.forEach(cmd => {
          this.Sum_montant += cmd.montant
          this.Sum_quantite += cmd.quantite
          this.sum_quantite_rst += cmd.tt_qte_restant
          this.sum_capping += cmd.lead_par_jour
          this.sum_capping_livred += parseInt(cmd.capping_livre)
          this.sum_ca_estimer_par_jour += cmd.ca_estimer_globale
          this.sum_ca_temp_reel += cmd.ca_reel_globale
        });
        this.total = list.length;
        if(list != null) {
          return list.slice(this.from, this.to);
        }
        else
          return null
      } catch (error) {
        console.log(error)
        return null;
      }
    },
  },
  methods: {
    toggle () {
      this.$fullscreen.toggle(this.$el.querySelector('.listcommande'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
      if (fullscreen) {
        this.PerfectScrollbar = new PerfectScrollbar(".listcommande");
      }
      else {
        this.PerfectScrollbar.destroy();
        this.PerfectScrollbar = null
      }
    },
    search() {
      this.SkipQuery = false
    },
    changeStatus(row, newstatus) {

    },
    retraitPause(row) {
      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier l'Etat!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation ($pk_commande: Int) {
              retraitPauseCommande( pk_commande: $pk_commande) {
                state
              }
            }`,
            variables: {
              pk_commande: row.pk_commande
            },
          }).then(response => {
            if (response.data.retraitPauseCommande.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              if (row.retrait_pause == 1) {
                row.retrait_pause = 0
              }
              else if (row.retrait_pause == 0) {
                row.retrait_pause = 1
              }
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    ChangerStatutCommande (status, row) {
      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier l'Etat!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation (
              $id_utilisateur: Int!,
              $ref: Int!,
              $statut: String!
            ) {
              changerStatutCommande(
                id_utilisateur: $id_utilisateur,
                ref: $ref,
                statut: $statut
              ){
                state
              }
            }`,
            variables: {
              id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
              ref: row.pk_commande,
              statut: status
            },
          }).then(response => {
            if (response.data.changerStatutCommande.state) {
              this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
              if (status === 'relance') {
                row.statut = null
              }
              else if (status === 'pause') {
                row.statut = 'pause'
              }
              else if (status === 'annuler') {
                row.statut = 'annuler'
                row.etat = true
              }
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
                          
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })
    },
    changeCapping(e, row){
      let newvalue = e.target.value;

      if(newvalue == '') {
        return
      }
      newvalue = parseInt(newvalue)

      
      if (JSON.parse(localStorage.getItem('utilisateur')).id != 253) {
        if (newvalue < 10) {
          this.$notify({type: 'warning', message: "Capping doit avoir une valeur de 10 ou plus" })
          return
        }
      }

      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier le Capping!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation (
              $id_utilisateur: Int!,
              $ref: Int!,
              $column: String!,
              $value: Int!,
              $ancien_val: Int!
            ) {
              changerPrioVolumeCommande(
                id_utilisateur: $id_utilisateur,
                ref: $ref,
                column: $column,
                value: $value,
                ancien_val: $ancien_val
              ){
                state
              }
            }`,
            variables: {
              id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
              ref: row.pk_commande,
              column: "capping",
              value: newvalue,
              ancien_val: row.lead_par_jour
            },
          }).then(response => {
            if (response.data.changerPrioVolumeCommande.state) {
              this.$notify({type: 'success', message: "Capping a été bien modifié." }) 
              row.lead_par_jour = newvalue
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      })

    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    openDetails(id) {
      let routeData = this.$router.resolve({ name:'Détails Commande LeadMarket Admin', params: {commande_id: id} });
      window.open(routeData.href, '_blank');
    },
    openEdit(id) {
      let routeData = this.$router.resolve({ name:'edit Commande LeadMarket Admin', params: {commande_id: id} });
      window.open(routeData.href, '_blank');
    },
    // ValiderCmdPromo() {
    //   let cmds_promo_true = ''
    //   let cmds_promo_false = ''
    //   swal.fire({
    //     title: 'Êtes-vous sûr?',
    //     text: "",
    //     width: 400,
    //     showCancelButton: true,
    //     confirmButtonColor: '#988fc3',
    //     cancelButtonColor: '#27293D',
    //     confirmButtonText: 'Modifier',
    //     cancelButtonText: 'Annuler'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.ListCommande.forEach(cmd => {
    //         if (cmd.cmd_promo == true && cmd.CheckSwitchPromo) {
    //           cmds_promo_true += cmd.pk_commande + ","
    //         }
    //         else if (cmd.cmd_promo == false && cmd.CheckSwitchPromo) {
    //           cmds_promo_false += cmd.pk_commande + ","
    //         }
    //       });

    //       // cmd_promo: true 
    //       if (cmds_promo_true) {
    //         this.$apollo.mutate({
    //           client: 'MARKET',
    //           mutation: gql`mutation ($id_selected: String) {
    //             valideCommandePromo($id_selected: $id_selected, cmd_promo: true) {
    //               state
    //             }
    //           }`,
    //           variables: {
    //             id_selected: cmds_promo_true.slice(0, -1)
    //           },
    //         }).then(response => {
    //           if (response.data.changerStatutCommande.state) {
    //             this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
    //           }
    //           else {
    //             this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
    //           }           
    //         }).catch(error => {
    //           this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
    //         })
    //       }
    //       // cmd_promo: false 
    //       if (cmds_promo_false) {
    //         this.$apollo.mutate({
    //           client: 'MARKET',
    //           mutation: gql`mutation ($id_selected: String) {
    //             valideCommandePromo($id_selected: $id_selected, cmd_promo: false) {
    //               state
    //             }
    //           }`,
    //           variables: {
    //             id_selected: cmds_promo_false.slice(0, -1)
    //           },
    //         }).then(response => {
    //           if (response.data.changerStatutCommande.state) {
    //             this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
    //           }
    //           else {
    //             this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
    //           }           
    //         }).catch(error => {
    //           this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
    //         })
    //       }
    //     }      
    //   })
    // },
    CheckSwitchPromo(row) {
      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
          client: 'MARKET',
          mutation: gql`mutation ($id_selected: String, $cmd_promo: Boolean) {
            valideCommandePromo(id_selected: $id_selected, cmd_promo: $cmd_promo) {
              state
            }
          }`,
          variables: {
            id_selected: row.pk_commande,
            cmd_promo: !row.cmd_promo
          },
        }).then(response => {
          if (response.data.valideCommandePromo.state) {
            this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
            row.cmd_promo = !row.cmd_promo
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }           
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
        }
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 5 && this.rows[4].show) {
          sums[index] = this.formatThounsends(this.Sum_montant, true, 2);
          return;
        } else if (index === 7 && this.rows[6].show) {
          sums[index] = this.formatThounsends(this.Sum_quantite);
          return;
        } else if (index === 8 && this.rows[7].show) {
          sums[index] = this.formatThounsends(this.sum_quantite_rst);
          return;
        } else if (index === 10 && this.rows[10].show) {
          sums[index] = this.formatThounsends(this.sum_capping);
          return;
        } else if (index === 11 && this.rows[11].show) {
          sums[index] = this.formatThounsends(this.sum_capping_livred);
          return;
        } else if (index === 12 && this.rows[14].show) {
          sums[index] = this.formatThounsends(this.sum_ca_estimer_par_jour, true, 2) + ' €';
          return;
        } else if (index === 13 && this.rows[15].show) {
          sums[index] = this.formatThounsends(this.sum_ca_temp_reel, true, 2) + ' €';
          return;
        } else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    },
    replaceUTF_8(string) {
      if(!string) 
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }
      
      return string
    },
    calculateCaEstimer(cmd) {
      let days_week_livre = 0
      let startDate = moment(this.StartDate || new Date())
      const endDate = moment(this.EndnDate || new Date())
      const days_cmd = cmd.jours
      const db_livraison = moment(cmd.db_livraison || new Date())
      const prix_lead = cmd.ligne_commande[0].prix_unitaire || 0
      const capping = cmd.lead_par_jour || 0

      if (db_livraison.diff(startDate, 'days') > 0) {
        startDate = db_livraison;
      }
        

      if (days_cmd) {
        for (var m = startDate; m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
          if (days_cmd.toLowerCase().includes(m.locale("fr").format('dddd'))) {
            days_week_livre++ 
          }
        }
      }
      else {
        days_week_livre = startDate.diff(endDate, 'days') + 1
      }
      
      return days_week_livre * capping * prix_lead;
    },
    CustomSort_CaEstime(a, b) {
      return (a.lead_par_jour * a.ligne_commande[0].prix_unitaire) - (b.lead_par_jour * b.ligne_commande[0].prix_unitaire)
    },
    CustomSort_CaReel(a, b) {
      return (a.capping_livre * a.ligne_commande[0].prix_unitaire) - (b.capping_livre * b.ligne_commande[0].prix_unitaire)
    }
  },
  mounted() {
    this.SkipQuery = false
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>