<template>
  <div class="content">
    <h3>DÉTAILS TRANSACTIONS CLIENTS</h3>
  </div>
</template>

<script>
  import {Select, Option, DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'

  export default {
    
  }
</script>