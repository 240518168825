<template>
  <div class="content">
    <h3>{{ $t("titlePages.followUpOfProfitability") }}</h3>
    <card>
      <div class="col-md-12">
      <div class="row">
        <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
            <div class="row">
              <base-input class="col-md-4">
                <el-select filterable class="select-primary"
                  v-model="CASelects.CA"
                  :placeholder="$t('table.columns.BusinessCharges')"
                  :disabled="$apollo.queries.suiviRentabilite.loading"
                  clearable>
                  <el-option 
                    v-for="option in CASelects.ListCA"
                    class="select-primary text-uppercase"
                    :value="option.pk_charge_affaire"
                    :label="option.nom"
                    :key="option.pk_charge_affaire">
                  </el-option>
                </el-select>
              </base-input>
              <base-input class="col-md-4">
                <el-select filterable class="select-primary"
                  v-model="YearSelects.Year"
                  :placeholder="$t('table.columns.Year')"
                  :disabled="$apollo.queries.suiviRentabilite.loading"
                  clearable>
                  <el-option 
                    v-for="option in YearSelects.ListYear"
                    class="select-primary"
                    :value="option.annee"
                    :label="option.annee"
                    :key="option.annee">
                  </el-option>
                </el-select>
              </base-input>
            </div>
        </div>
        <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
          <base-button 
            v-if="$apollo.queries.suiviRentabilite.loading" 
            loading 
            class="btn btn-just-icon btn-default d-block" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-just-icon btn-default d-block" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div>
      </div>
    </div>
    </card>
    <card>
      <el-table :data="suiviRentabilite" v-loading="$apollo.queries.suiviRentabilite.loading" row-class-name="booking-table-rows" show-summary :summary-method="getSummaries">
        <el-table-column
          prop='periode'
          :label="$t('buttons.BtnSearch') + ' / ' + $t('table.columns.Year')"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='inscris'
          :label=" $t('table.columns.numberOfRegisteredClients')"
          minWidth='150'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
        </el-table-column>

        <el-table-column
          prop='new_inscris'
          :label="$t('table.columns.numberOfNewRegisteredCustomers')"
          minWidth='150'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
        </el-table-column>

        <el-table-column
          prop='actifs'
          :label="$t('table.columns.numberOfActiveCustomers')"
          minWidth='150'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
        </el-table-column>

        <el-table-column
          prop='ca_realise_net'
          label="CA NET"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.ca_realise_net, true, 2) + ' €' }}
          </template>
        </el-table-column>

        <el-table-column
          prop='recharges'
          :label="$t('table.columns.rechargedAccountRates')"
          minWidth='140'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
          <template scope="scope">
            {{ formatThounsends((scope.row.recharges / scope.row.total_inscris) * 100, true, 2) + ' %' }}
          </template>
        </el-table-column>

        <el-table-column
          prop='recharges'
          :label="$t('table.columns.refillAverage')"
          minWidth='145'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
          <template scope="scope">
            <template v-if="scope.row.recharges != 0">
              {{ formatThounsends(scope.row.montant/scope.row.recharges, true, 2) + ' €' }}
            </template>
            <template v-else>
              0 €
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop='montant'
          :label="$t('table.columns.totalTransactions')"
          minWidth='145'
          show-overflow-tooltip
          sortable
          :render-header="renderHeaderMethod"
        >
          <template scope="scope">
            {{ formatThounsends(scope.row.montant) + ' €' }}

          </template>
        </el-table-column>

      </el-table>
    </card>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import gql from 'graphql-tag'

export default {
  apollo: {
    Filter: {
      client: 'MARKET',
      query: gql `
        query {
          suiviRenatbiliteYears{
            annee
          }

          chargesAffaires{
            pk_charge_affaire
            nom
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        this.CASelects.ListCA = data.chargesAffaires
        this.YearSelects.ListYear = data.suiviRenatbiliteYears
      },
      update: data => data.suiviRenatbiliteYears,
    },
    suiviRentabilite: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/suiviRentabilite.gql"),
      variables () {
        return {
          charge_affaire_id: this.CASelects.CA,
          annee: this.YearSelects.Year
        }
      },
      skip () {
        return this.SkipQuery
      },
      result({ data, loader, networkStatus }) {
        this.SkipQuery = true
      }
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      SkipQuery: true,
      CASelects: {
        CA: "",
        ListCA: []
      },
      YearSelects: {
        Year: "",
        ListYear: []
      }
    }
  },
  methods: {
    search() {
      this.SkipQuery = false
    },
    renderHeaderMethod(h, { column, $index }) {
      return h(
        "el-popover",
        {
          props: {
            placement: "top",
            trigger: "hover",
          }
        },
        [
          column.label,
          h(
            "span",
            {
              slot: "reference",
            },
            column.label.slice(0,8)+'...'
          )
        ]
      );
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
      let somme_new_inscris = 0
      let somme_actifs = 0
      let somme_ca_realise_net = 0
      let somme_recharges = 0
      let somme_total_inscris = 0
      let somme_montant = 0
      let total_inscris = 0
      data.forEach(stats => {
        somme_new_inscris += stats.new_inscris
        somme_actifs += stats.actifs
        somme_ca_realise_net += stats.ca_realise_net
        somme_recharges += stats.recharges
        somme_total_inscris += stats.total_inscris
        total_inscris = stats.total_inscris
        somme_montant += stats.montant
      });
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        }
        else if (index === 1) {
          sums[index] = this.formatThounsends(total_inscris);
          return;
        } 
        else if (index === 2) {
          sums[index] = this.formatThounsends(somme_new_inscris);
          return;
        } 
        else if (index === 3) {
          sums[index] = this.formatThounsends(somme_actifs);
          return;
        } 
        else if (index === 4) {
          sums[index] = this.formatThounsends(somme_ca_realise_net, true, 2) + ' €';
          return;
        } 
        else if (index === 5) {
          sums[index] = somme_total_inscris != 0 ? this.formatThounsends((somme_recharges / total_inscris) * 100, true, 2) + ' %' : '0 %';
          return;
        } 
        else if (index === 6) {
          sums[index] = somme_recharges != 0 ? this.formatThounsends(somme_montant / somme_recharges, true, 2) + ' €' : '0 €';
          return;
        } 
        else if (index === 7) {
          sums[index] = this.formatThounsends(somme_montant, true, 2) + ' €';
          return;
        }
        else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    }
  },
}
</script>

<style>
  .el-table thead.is-group th {
    background: transparent;
  }
</style>