<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3 v-if="law == 'ad'">STATISTIQUES MON GUSTAVE PAR CLIENTS</h3>
        <h3 v-else>STATISTIQUES MON GUSTAVE</h3>
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <base-button
            @click="GeneratePDF"
            class="btn-link"
            type="market"
            size="sm"
            icon
          >
            <i class="far fa-file-pdf icon-large" title="TÉLÉCHARGER EN PDF"></i>
          </base-button>
        </div>
      </div>
      <div class="col-md-12" id="downloadStatsPDF">
        <div id="logo_statsMG" style="display: none;">
          <br/>
          <img src="img/missingprofile.jpg">
          <br/>
          <center><h2>STATISTIQUES MON GUSTAVE</h2></center>
          <br/>
          <br/>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input label="Clients" class="col-md-3 mb-2" v-if="law == 'ad'">
                      <el-select
                        class="select-primary"
                        filterable
                        placeholder="CHOISISSEZ"
                        v-model="ClientSelects.Client"
                        :disabled="$apollo.queries.getCotation.loading">
                        <el-option
                          v-for="option in ClientSelects.ListClient"
                          class="select-primary"
                          :value="option.fk_annonceur"
                          :label="option.societe.toUpperCase()"
                          :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input label="Type" class="col-md-3 mb-2">
                      <el-select filterable class="select-primary"
                        placeholder="CHOISISSEZ"
                        v-model="TypeSelects.Type"
                        :disabled="$apollo.queries.getCotation.loading">
                        <el-option
                          v-for="option in TypeSelects.ListType"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <!-- <base-input label="PACK" class="col-md-3 mb-2" v-if="law == 'ad'">
                      <el-select filterable class="select-primary"
                        placeholder="PACK"
                        clearable
                        v-model="PackSelects.Pack"
                        :disabled="$apollo.queries.getCotation.loading">
                        <el-option
                          v-for="option in PackSelects.ListPack"
                          class="select-primary"
                          :value="option"
                          :label="option.toUpperCase()"
                          :key="option">
                        </el-option>
                      </el-select>
                    </base-input> -->
                    <base-input label="année" class="col-md-3 mb-2">
                      <el-select
                        class="select-primary"
                        filterable
                        placeholder="CHOISISSEZ"
                        v-model="Mois"
                        :disabled="$apollo.queries.getCotation.loading">
                        <el-option
                          v-for="option in listYear"
                          class="select-primary"
                          :value="option"
                          :label="option"
                          :key="option">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <div class="row">
                    <base-button
                      v-if="$apollo.queries.getCotation.loading"
                      loading
                      class="btn btn-just-icon btn-default mt-4 d-block"
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                    <button
                      v-else
                      class="btn btn-just-icon btn-default mt-4 d-block"
                      @click="search"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                COTATIONS
              </span>
              <el-table row-class-name="booking-table-rows-statsMGclient" show-summary :summary-method="getSummariesCotations" :data="getCotation" v-loading='$apollo.queries.getCotation.loading'>
                <el-table-column
                  label="Année"
                  prop="annee"
                ></el-table-column>
                <el-table-column
                  label="Mois"
                  prop="mois"
                >
                  <template scope="scope">
                    {{ Mounths[scope.row.mois-1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="COTATIONS"
                  prop="cotations"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.cotations) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                AFFICHAGE
              </span>
              <el-table row-class-name="booking-table-rows-statsMGclient" :data="getCotation" show-summary :summary-method="getSummariesAffichage" v-loading='$apollo.queries.getCotation.loading'>
                <el-table-column
                  label="Année"
                  prop='annee'
                >
                </el-table-column>
                <el-table-column
                  label="Mois"
                  prop="mois"
                >
                  <template scope="scope">
                    {{ Mounths[scope.row.mois-1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="AFFICHAGE"
                  prop="affichage"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.affichage) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                EMPREINTE
              </span>
              <el-table row-class-name="booking-table-rows-statsMGclient" :data="getCotation" show-summary :summary-method="getSummariesEmpreinte" v-loading='$apollo.queries.getCotation.loading'>
                <el-table-column
                  label="Année"
                  prop="annee"
                ></el-table-column>
                <el-table-column
                  label="Mois"
                  prop="mois"
                >
                  <template scope="scope">
                    {{ Mounths[scope.row.mois-1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="EMPREINTE"
                  prop="empreinte"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.empreinte * 100,true,2) + ' %' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="card">
          <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
            EVOLUTION TAUX D'EMPREINTE VS NOMBRE D'AFFICHAGE
          </span>
          <br/>
          <bar-chart
            :chart-data="dataChartAffichage_vs_Empreinte"
            :extra-options="barChart2.extraOptions"
            :height="450"
            v-if="renderChart_Affichage_vs_Empreinte"
          >
          </bar-chart>
        </div>

        <div class="card">
          <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
            RÉPARTITION DES MEILLEURES APPARITIONS PAR RANG
          </span>
          <el-table row-class-name="booking-table-rows-statsMGclient" :data="affichages_by_rang" show-summary :summary-method="getSummariesRang" v-loading='$apollo.queries.getCotation.loading'>
            <el-table-column
              v-for="column in tableRangColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :formatter="Rangeformatter"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>

        <h3>FOCUS MER</h3>

        <div class="row">

          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                MER BRUTES
              </span>
              <el-table :data="getCotation" row-class-name="booking-table-rows-statsMGclient" v-loading='$apollo.queries.getCotation.loading' show-summary :summary-method="getSummaries_MerDouble">
                <el-table-column
                  label="Année"
                  prop="annee"
                  minWidth='80'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="Mois"
                  prop="mois"
                  minWidth='80'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ Mounths[scope.row.mois-1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="# MER vendues brutes"
                  prop="mer"
                  minWidth='200'
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                TAUX DE MER
              </span>
              <el-table :data="getCotation" row-class-name="booking-table-rows-statsMGclient" v-loading='$apollo.queries.getCotation.loading' show-summary :summary-method="getSummaries_TauxMer">
                <el-table-column
                  label="Année"
                  prop="annee"
                  minWidth='80'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="Mois"
                  prop="mois"
                  minWidth='80'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ Mounths[scope.row.mois-1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="% Taux de MER brute / AFFICHAGE"
                  prop="mer"
                  minWidth='250'
                >
                  <template scope="scope">
                    <template v-if="scope.row.affichage != 0">
                      {{ formatThounsends((scope.row.mer / scope.row.affichage) * 100, true, 2)  }} %
                    </template>
                    <template v-else>
                      0.00 %
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </div>

        <div class="card">
          <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
            MER BRUTES
          </span>
          <br/>
          <bar-chart
            :chart-data="dataChartFocus_MER"
            :extra-options="barChart_mer.extraOptions"
            :height="300"
            v-if="renderChart_Focus_MER"
          >
          </bar-chart>
        </div>

        <h3>FOCUS PRODUITS</h3>

        <div class="row">

          <div class="col-md-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                AFFICHAGE PAR PRODUIT
              </span>
              <el-table row-class-name="booking-table-rows-statsMGclient" :data="affichage_by_product2" show-summary sum-text="TOTAL" v-loading='$apollo.queries.getCotation.loading'>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                  :formatter="Productformatter"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                MER VENDUES PAR PRODUIT
              </span>
              <el-table row-class-name="booking-table-rows-statsMGclient" :data="mer_by_product2" show-summary sum-text="TOTAL" v-loading='$apollo.queries.getCotation.loading'>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                  :formatter="Productformatter"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                RÉPARTITION DES AFFICHAGES PAR PRODUIT
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartAffichage_by_product"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Affichage_by_product"
              >
              </pie-chart>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
                RÉPARTITION DES MER PAR PRODUIT
              </span>
              <br/>
              <pie-chart
                :chart-data="dataChartMer_by_product"
                :extra-options="pieChart.extraOptions"
                :height="200"
                v-if="renderChart_Mer_by_product"
              >
              </pie-chart>
            </div>
          </div>

        </div>

        <Footer id="footer_statsMG" style="display: none;" />
      </div>
    </div>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui'
  import PieChart from 'src/components/Charts/PieChart';
  import BarChart from 'src/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import gql from 'graphql-tag'
  import moment from 'moment'
  import html2PDF from 'jspdf-html2canvas';
  import Footer from '../../../Layout/ContentFooter';

  export default {
    apollo: {
      getMongustaveUsers : {
        client: 'MARKET',
        query: gql `
          query {
            getMongustaveUsers {
              fk_annonceur
              societe
            }
          }
        `,
        result({ data, loader, networkStatus }) {
          this.ClientSelects.ListClient = data.getMongustaveUsers
          // MRH client
          // if (!this.client_push) {
          //   this.client_push = true
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13139",societe: "Luko"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13138",societe: "Homebrella"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13141",societe: "AcommeAssure"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13145",societe: "Lovys"})
          //   // Animaux
          //   this.ClientSelects.ListClient.push({fk_annonceur: "515",societe: "Assuropoil"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13137",societe: "CARREFOUR"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13526",societe: "Dalma"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "928",societe: "Anim O mut"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13142",societe: "Sollyazar"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "3043",societe: "Otherwise"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "1",societe: "DEMO"})
          //   // AUTO
          //   this.ClientSelects.ListClient.push({fk_annonceur: "13098",societe: "LEOCARE"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "149",societe: "REVITAL"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "147",societe: "NETVOX"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "151",societe: "MIEUX ASSURE"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "148",societe: "SOS Malus"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "146",societe: "ELSASSUR"})
          //   // EMPRUNTEUR
          //   this.ClientSelects.ListClient.push({fk_annonceur: "15003",societe: "Wedou"})
          //   this.ClientSelects.ListClient.push({fk_annonceur: "15002",societe: "Meilleur Taux"})
          //   // CREDIT
          //   this.ClientSelects.ListClient.push({fk_annonceur: "16000",societe: "Cofidis"})



          //   this.ClientSelects.ListClient = this.ClientSelects.ListClient.sort(function (a, b) {
          //     return a.societe.toUpperCase().localeCompare(b.societe.toUpperCase());
          //   })
          // }
        },
        fetchPolicy: 'network-only',
        skip() {
          return this.law != 'ad'
        }
      },
      getDateFiltre: {
        client: 'MARKET',
        query: gql `
          query ($id_utilisateur: ID) {
            getDateFiltre(id_utilisateur: $id_utilisateur) {
              year
              month
            }
          }
        `,
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
          this.listYear = [ ...new Set(data.getDateFiltre.map(a => a.year))];
          this.Mois = data.getDateFiltre[0].year
        }
      },
      getCotation: {
        client: 'MARKET',
        query: gql `
          query ($annee: Int!, $type: Int!, $id_client: Int,$utilisateur_id: Int!, $gamme: String) {
            getCotation(annee: $annee, type: $type, id_client: $id_client,utilisateur_id: $utilisateur_id , gamme: $gamme) {
              id_produit
              id_client
              cotations
              affichage
              empreinte
              mer
              rappel
              devis
              souscrire
              agenda
              mois
              annee
              produit
              produit_mer
              rang
            }
          }
        `,
        variables () {
          return {
            annee: this.Mois,
            type: this.TypeSelects.Type,
            gamme: this.PackSelects.Pack || "standard",
            id_client: this.ClientSelects.Client,
            utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        skip() {
          return this.SkipQuery_CotationsAndAffichage
        },
        result({ data, loader, networkStatus }) {
          this.SkipQuery_CotationsAndAffichage = true
          this.dataChartAffichage_vs_Empreinte = {
            labels: data.getCotation.map(x => this.Mounths[x.mois-1]+" "+x.annee),
            datasets: [
              {
                label: 'Empreinte',
                type: "line",
                yAxisID: 'Empreinte',
                fill: true,
                hoverBackgroundColor: "#DD4E01",
                borderColor: "#DD4E01",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data.getCotation.map(x => (x.empreinte*100).toFixed(2))
              },
              {
                label: 'Cotation',
                fill: true,
                backgroundColor: "#1B61F7",
                yAxisID: 'Cotation',
                hoverBackgroundColor: "#1B61F7",
                borderColor: "#1B61F7",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data.getCotation.map(x => x.affichage)
              }
            ]
          }
          this.renderChart_Affichage_vs_Empreinte = true
          // chart Focus MER
          this.dataChartFocus_MER = {
            labels: data.getCotation.map(x => this.Mounths[x.mois-1] + " " + x.annee),
            datasets: [
              {
                label: 'Rappel',
                fill: true,
                backgroundColor: "#1B61F7",
                hoverBackgroundColor: "#1B61F7",
                borderColor: "#1B61F7",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data.getCotation.map(x => x.rappel)
              },
              {
                label: 'Devis',
                fill: true,
                backgroundColor: "#DD4E01",
                hoverBackgroundColor: "#DD4E01",
                borderColor: "#DD4E01",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data.getCotation.map(x => x.devis)
              },
              {
                label: 'Souscrire',
                fill: true,
                backgroundColor: "#088A08",
                hoverBackgroundColor: "#088A08",
                borderColor: "#088A08",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data:  data.getCotation.map(x => x.souscrire)
              } ,
              {
                label: 'RDV',
                fill: true,
                backgroundColor: "#7225BE",
                hoverBackgroundColor: "#7225BE",
                borderColor: "#7225BE",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data:  data.getCotation.map(x => x.agenda)
              }
            ]
          }



          this.renderChart_Focus_MER = true

          // AFFICHAGE PAR PRODUIT
          let array
          let array_mer
          let array_rang
          let GlobalArray = []
          this.tableColumns = [
            {
              prop: 'frm',
              label: 'Mois / Formule',
              minWidth: 220
            },
          ]
          this.tableRangColumns = [
            {
              prop: 'rang',
              label: 'Rang Prix - Rang',
              minWidth: 160
            },
          ]
          let max = []
          let max_mer = []
          let max_rang = []

          let group_array_cotation = []
          let group_array_mer = []

          data.getCotation.forEach(element => {
            array = JSON.parse(element.produit)
            array_mer = JSON.parse(element.produit_mer)
            group_array_cotation = group_array_cotation.concat(array.map(a => a.frm))
            group_array_mer = group_array_mer.concat(array_mer.map(a => a.frm))
          })

          let unique_form_cotation = this.unite(group_array_cotation);
          let unique_form_mer = this.unite(group_array_mer);

          unique_form_cotation.forEach(form => {
            max.push({frm: form,mois1: 0,mois2: 0,mois3: 0,mois4: 0,mois5: 0,mois6: 0,mois7: 0,mois8: 0,mois9: 0,mois10: 0,mois11: 0,mois12: 0})
          })

          unique_form_mer.forEach(form => {
            max_mer.push({frm: form,mois1: 0,mois2: 0,mois3: 0,mois4: 0,mois5: 0,mois6: 0,mois7: 0,mois8: 0,mois9: 0,mois10: 0,mois11: 0,mois12: 0})
          })

          data.getCotation.forEach(element => {
            array = JSON.parse(element.produit)
            array_mer = JSON.parse(element.produit_mer)

            array_rang = JSON.parse(element.rang)
            max_rang = array_rang.length > max_rang.length ? array_rang : max_rang
            this.tableColumns.push(
              {
                prop: "mois"+element.mois,
                label: this.Mounths[element.mois-1],
                minWidth: 110
              }
            )
            this.tableRangColumns.push(
              {
                prop: "mois"+element.mois,
                label: this.Mounths[element.mois-1],
                minWidth: 110
              }
            )
          });
          data.getCotation.forEach(element => {
            let sum = 0
            array = JSON.parse(element.produit)
            array_mer = JSON.parse(element.produit_mer)
            max_mer.forEach(element3 => {
              let find = array_mer.find(x => x.frm === element3.frm)
              if (find) {
                eval("element3.mois"+element.mois+" = find.nbr");
                if (!element3.sum) {
                  element3.sum = 0
                }
                element3.sum += find.nbr
              }
            });


            max.forEach(element2 => {
              let find = array.find(x => x.frm === element2.frm)
              if (find) {
                eval("element2.mois"+element.mois+" = find.nbr");
                if (!element2.sum) {
                  element2.sum = 0
                }
                element2.sum += find.nbr
              }
            })

            array_rang = JSON.parse(element.rang)
            max_rang.forEach(element4 => {
              let find = array_rang.find(x => x.rang === element4.rang)
              if (find) {
                eval("element4.mois"+element.mois+" = find.nbr");
              }
              else {
                eval("element4.mois"+element.mois+" = 0");
              }
            });

          })
          this.affichage_by_product2 = max
          this.mer_by_product2 = max_mer
          this.affichages_by_rang = max_rang

          let affichage_by_product2_filtre = this.affichage_by_product2;

          if (this.affichage_by_product2.length > 10) {
            affichage_by_product2_filtre = this.affichage_by_product2.sort((a, b) => b.sum - a.sum).slice(0, 10);
            let sum = 0;
            for (let index = 11; index < this.affichage_by_product2.length; index++) {
              sum += this.affichage_by_product2[index].sum;
            }
            affichage_by_product2_filtre.push({ frm: "Auter", sum: sum })
          }

          // chart formule par produit
          this.dataChartAffichage_by_product = {
            labels: affichage_by_product2_filtre.map(a => a.frm),
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#1B61F7', '#DD4E01', '#088A08', '#ff0040', '#772e7f', '#fa7a47', '#9143df', '#dde02e', '#75ff42', '#FF0000', '#000', '#ffffa3', '#ffa6a3', '#b3908f', '#47fa80', '#601010', "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA", "#FFDADA" ],
                borderWidth: 0,
                data: affichage_by_product2_filtre.map(a => a.sum)
              }
            ]
          }
          this.renderChart_Affichage_by_product = true

          let mer_by_product2_filtre = this.mer_by_product2;

          if (this.mer_by_product2.length > 10) {
            mer_by_product2_filtre = this.mer_by_product2.sort((a, b) => b.sum - a.sum).slice(0, 10);
            let sum = 0;
            for (let index = 11; index < this.mer_by_product2.length; index++) {
              sum += this.mer_by_product2[index].sum;
            }
            mer_by_product2_filtre.push({ frm: "Auter", sum: sum })
          }

          // chart formule par mer
          this.dataChartMer_by_product = {
            labels: mer_by_product2_filtre.map(a => a.frm),
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#1B61F7', '#DD4E01', '#088A08', '#ff0040', '#772e7f', '#fa7a47', '#9143df', '#dde02e', '#75ff42', '#FF0000', '#000', '#ffffa3', '#ffa6a3', '#b3908f', '#47fa80', '#601010', "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA", "#FFDADA" ],
                borderWidth: 0,
                data: mer_by_product2_filtre.map(a => a.sum)
              }
            ]
          }
          this.renderChart_Mer_by_product = true

        },
        fetchPolicy: 'network-only'
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PieChart,
      BarChart,
      Footer
    },
    data() {
      return {
        law: JSON.parse(localStorage.getItem("utilisateur")).law,
        listYear: [],
        SkipQuery_CotationsAndAffichage: true,
        Mois: null,
        affichages_by_rang: [],
        affichage_by_product: [],
        dataChartAffichage_by_product: null,
        dataChartMer_by_product: null,
        dataChartFocus_MER: null,
        dataChartAffichage_vs_Empreinte: null,
        renderChart_Affichage_by_product: false,
        renderChart_Mer_by_product: false,
        renderChart_Focus_MER: false,
        renderChart_Affichage_vs_Empreinte: false,
        ClientSelects: {
          Client: '513',
          ListClient: []
        },
        client_push: false,
        TypeSelects: {
          Type: 2,
          ListType: [
            { value: 2, label: 'ANIMAUX' },
            { value: 4, label: 'AUTO' },
            { value: 6, label: 'CRÉDIT CONSO' },
            { value: 5, label: 'EMPRUNTEUR' },
            { value: 3, label: 'MRH' },
            { value: 7, label: 'ASSURANCE VIE' },
            { value: 1, label: 'SANTÉ' },
          ]
        },
        PackSelects: {
          Pack: '',
          ListPack: ["salaries", "seniors", "standard", "tns"]
        },
        pieChart: {
          chartData: {},
          extraOptions: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                fontSize: 12
              }
            },
            cutoutPercentage: 60,
            tooltips: {
              callbacks: {
                title: function(tooltipItem, data) {
                  return data['labels'][tooltipItem[0]['index']];
                },
                label: function(tooltipItem, data) {
                  return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                },
                afterLabel: function(tooltipItem, data) {
                  var dataset = data['datasets'][0];

                  var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
                  return '(' + percent + '%)';
                }
              },
              backgroundColor: '#FFF',
              titleFontSize: 16,
              titleFontColor: '#0066ff',
              bodyFontColor: '#000',
              bodyFontSize: 14,
              displayColors: false
            },
            "hover": {
              "animationDuration": 0
            },
            animation: {
              duration : 0,
              onComplete : function() {
                var ctx = this.chart.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset) {
                  for (var i = 0; i < dataset.data.length; i++) {
                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                      total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                      mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                      start_angle = model.startAngle,
                      end_angle = model.endAngle,
                      mid_angle = start_angle + (end_angle - start_angle)/2;

                    var x = mid_radius * Math.cos(mid_angle);
                    var y = mid_radius * Math.sin(mid_angle);

                    ctx.fillStyle = '#FFF';
                    // if (i == 3){ // Darker text color for lighter background
                    //   ctx.fillStyle = '#FFF';
                    // }
                    var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                    //Don't Display If Legend is hide or value is 0
                    // if(dataset.data[i] != 0 && dataset._meta[0].data[i].hidden != true) {
                      ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                      // Display percent in another line, line break doesn't work for fillText
                      // ctx.fillText(percent, model.x + x, model.y + y + 15);
                    // }
                  }
                });

              }
            },
          }
        },
        barChart_mer: {
          chartData: {},
          extraOptions: {
            ...chartConfigs.barChartOptionsGradient,
            scales: {
              yAxes: [
                {
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  }
                }
              ]
            },
            legend: {
              display: true,
              position: 'bottom',
              align: 'center',
              labels: {
                boxWidth: 15,
                fontSize: 12
              }
            },
            tooltips: {
              callbacks: {
                title: function(tooltipItem, data) {
                  // console.log(data.datasets[tooltipItem[0].index].label)
                  return data.datasets[tooltipItem[0].datasetIndex].label;
                },
                label: function(tooltipItem, data) {
                  return tooltipItem.value;
                },
                afterLabel: function(tooltipItem, data) {
                  const total = (data.datasets[0].data[tooltipItem.index] || 0) + (data.datasets[1].data[tooltipItem.index] || 0) + (data.datasets[2].data[tooltipItem.index] || 0)
                  const value = parseInt(tooltipItem.value)
                  if (tooltipItem.datasetIndex == 0) {
                    return '(' + ((value * 100) / total).toFixed(2) + ' %)';
                  }
                  else  if (tooltipItem.datasetIndex == 1) {
                    return '(' + ((value * 100) / total).toFixed(2) + ' %)';
                  }
                  else  if (tooltipItem.datasetIndex == 2) {
                    return '(' + ((value * 100) / total).toFixed(2) + ' %)';
                  }
                  return '';
                }
              },
              backgroundColor: '#FFF',
              titleFontSize: 16,
              titleFontColor: '#0066ff',
              bodyFontColor: '#000',
              bodyFontSize: 14,
              displayColors: false
            },
            "hover": {
              "animationDuration": 0
            },
            animation: {
              duration : 0,
              onComplete : function() {
                var chartInstance = this.chart,
                ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(16, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
                gradient.addColorStop("1", "#a0a0a0");
                ctx.fillStyle = gradient;
                this.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  if(screen.width > 550)
                    meta.data.forEach(function(bar, index) {
                      var data = dataset.data[index].toLocaleString();
                      ctx.fillText(data, bar._model.x, bar._model.y - 3);
                    });
                });
              }
            },
          }
        },
        barChart2: {
          chartData: {},
          extraOptions: {
            ...chartConfigs.barChartOptionsGradient,
            scales: {
              yAxes: [
                {
                  id: 'Empreinte',
                  position: 'right',
                },
                {
                  id: 'Cotation',
                  position: 'left',
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  }
                },
              ]
            },
            legend: {
              display: true,
              position: 'bottom',
              align: 'center',
              labels: {
                boxWidth: 15,
                fontSize: 12
              }
            },
            "hover": {
              "animationDuration": 0
            },
            animation: {
              duration : 0,
              onComplete : function() {
                var chartInstance = this.chart,
                ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(16, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
                gradient.addColorStop("1", "#bebebe");
                ctx.fillStyle = gradient;
                this.data.datasets.forEach(function(dataset, i) {
                  if (i == 1) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    if(screen.width > 550)
                      meta.data.forEach(function(bar, index) {
                        var data = dataset.data[index].toLocaleString();
                        if (bar._datasetIndex == 0) {
                          data += " %";
                        }
                        ctx.fillText(data, bar._model.x, bar._model.y - 3);
                    });
                  }
                });
              }
            },
          }
        },
        Mounths: [
          "JANVIER",
          "FÉVRIER",
          "MARS",
          "AVRIL",
          "MAI",
          "JUIN",
          "JUILLET",
          "AOUT",
          "SEPTEMBRE",
          "OCTOBRE",
          "NOVEMBRE",
          "DÉCEMBRE"
        ],
        affichage_by_product2: [],
        mer_by_product2: [],
        tableColumns: [],
        tableRangColumns: [],
      }
    },
    methods: {
      Rangeformatter(row, column, cellValue) {
        if (column.property === "rang")
          return cellValue;
        else
          return this.formatThounsends(cellValue, true, 2) + " %";
      },
      Productformatter(row, column, cellValue) {
        if (column.property === "frm")
          return cellValue;
        else
          return this.formatThounsends(cellValue, true);
      },
      search() {
        this.SkipQuery_CotationsAndAffichage = false
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value :value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      getSummaries_MerDouble(param) {
        const { columns, data } = param;
        let somme_mer = 0
        data.forEach(stats => {
          somme_mer += stats.mer
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 2) {
            sums[index] = this.formatThounsends(somme_mer);
            return;
          }
          else if (index === 0) {
            sums[index] = "TOTAL";
            return;
          }
        })
        return sums;
      },
      getSummaries_TauxMer(param) {
        const { columns, data } = param;
        let somme_nbr = 0
        data.forEach(stats => {
          somme_nbr +=  (stats.affichage != 0) ? (stats.mer / stats.affichage) * 100 : 0
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 2) {
            sums[index] = this.formatThounsends(somme_nbr / data.length, true, 2) + ' %';
            return;
          }
          else if (index === 0){
            sums[index] = "TOTAL";
            return;
          }
        })
        return sums;
      },
      getFrDate(date) {
        return moment(date).format('DD/MM/YYYY')
      },
      GeneratePDF() {
        let logo = document.getElementById("logo_statsMG");
        let footer = document.getElementById("footer_statsMG");

        logo.style.display = "";
        footer.style.display = "";

        let pages = document.getElementById('downloadStatsPDF');
        pages.style.backgroundColor = "#1e1e2f";
        html2PDF(pages, {
          output: 'STATISTIQUES_MON_GUSTAVE.pdf',
        });
        pages.style.backgroundColor = "";
        logo.style.display = "none";
        footer.style.display = "none";
      },
      getSummariesCotations(param) {
        const { columns, data } = param;
        const sums = [];
        let Sum_Cotations = 0
        data.forEach(element => {
          Sum_Cotations += element.cotations
        });

        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(Sum_Cotations);
            return;
          }
        });

        return sums;
      },
      unite(grp) {
        return grp.filter(function(elem, index, self) {
          return self.indexOf(elem) === index;
        });
      },
      getSummariesAffichage(param) {
        const { columns, data } = param;
        const sums = [];
        let Sum_Affichage = 0
        data.forEach(element => {
          Sum_Affichage += element.affichage
        });

        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(Sum_Affichage);
            return;
          }
        });

        return sums;
      },
      getSummariesEmpreinte(param) {
        const { columns, data } = param;
        const sums = [];
        let Sum_Empreinte = 0
        data.forEach(element => {
          Sum_Empreinte += element.empreinte * 100
        });

        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(Sum_Empreinte/data.length,true,2) + ' %';
            return;
          }
        });

        return sums;
      },
      getSummariesRang(param) {
        const { columns, data } = param;
        const sums = [];
        let sum = 0

        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else {
            sum = this.affichages_by_rang.reduce((total, obj) => eval("obj."+column.property) + total,0)
            sums[index] = this.formatThounsends(sum, true, 2) + ' %';
            return;
          }
        });

        return sums;
      },
    },
  }
</script>

<style>

  .booking-table-rows-statsMGclient {
    text-transform: uppercase;
    font-family: Arial;
  }

</style>
