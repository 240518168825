<template>
  <div class="col-12 background-repoort">
    <div class="row" style="margin-top: 80px;">
      <div class="col-12">
        <div class="col-sm-12 text-left">
          <h2 class="card-title text-center"> 
            LES FACTURES WEEDO MARKET
          </h2>
        </div>
        <div class="col-md-12">
          <card>
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input class="col-md-4">
                      <!-- multiple 
                      collapse-tags -->
                      <el-select filterable class="select-primary"
                        v-model="PeriodeSelects.Periode.annee"
                        placeholder="ANNEE"
                        :disabled="$apollo.queries.comptabilites.loading"
                      >
                        <el-option 
                            v-for="option in PeriodeSelects.ListPeriodeAnnee"
                            class="select-primary"
                            :value="option.annee"
                            :label="option.annee"
                            :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-4">
                      <!-- multiple 
                      collapse-tags -->
                      <el-select filterable class="select-primary"
                        v-model="PeriodeSelects.Periode.mois"
                        placeholder="MOIS"
                        :disabled="$apollo.queries.comptabilites.loading"
                      >
                        <el-option 
                          v-for="option in PeriodeSelects.ListPeriodeMois"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-4">
                      <el-select filterable class="select-primary"
                        v-model="ClientSelects.Client"
                        placeholder="CLIENT"
                        :disabled="$apollo.queries.comptabilites.loading"
                        clearable>
                        <el-option 
                          v-for="option in ClientSelects.ListClient"
                          class="select-primary"
                            :value="option.pk_annonceur"
                            :label="(option.societe.length > 30) ? replaceUTF_8(option.societe.slice(0, 30)).toUpperCase() +'...' : replaceUTF_8(option.societe).toUpperCase()"
                            :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <base-button 
                    v-if="$apollo.queries.comptabilites.loading"
                    loading
                    class="btn btn-just-icon btn-default d-block" 
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <button 
                    v-else
                    class="btn btn-just-icon btn-default d-block" 
                    @click="search"
                  >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </card>
          <card>
            <div class="row">
              <div class="col-xl-1 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-8 col-sm-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
              <base-input class="col-xl-3 col-sm-6">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="RECHERCHE"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="searchComptabilites.slice(from, to)" row-class-name="booking-table-rows" show-summary :summary-method="getSummaries">
              <el-table-column
                prop='pk_facture'
                label="N° Facture"
                minWidth='130'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='societe'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                <el-tooltip
                content="crédit virtuel"
                 effect="light" 
                 :open-delay="300" 
                 placement="top"
                 v-if="scope.row.credit == 'credit_virtuelle'" > 
                  <i class="fa-solid fa-triangle-exclamation" style="color: red;" alt="crédit virtuel"></i>
                </el-tooltip>

               {{ scope.row.societe }}

              </template>
              </el-table-column>
              <el-table-column
                prop='total_ht'
                label="Montant ( € HT )"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends((scope.row.total_ht - scope.row.remise - scope.row.remboursement_devalidations), true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                prop='total_ttc'
                label="Montant ( € TTC )"
                minWidth='170'
                show-overflow-tooltip
                sortable
              > 
                <template scope="scope">
                  {{ formatThounsends(scope.row.total_ttc, true, 2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                prop='etat'
                label="Etat"
                minWidth='130'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                label="Action"
                minWidth="100"
              >
              <template scope="scope">
                <el-tooltip
                    content="Télécharger facture"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      class="btn-link"
                      type="info"
                      size="sm"
                      @click="DownloadFactureMarket(scope.row)"
                      icon
                    >
                      <i class="fas fa-cloud-download-alt"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                    
                    content="valider facture"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      :disabled="scope.row.etat == 'valide'"
                      class="btn-link"
                      type="success"
                      size="sm"
                      @click="ValidationFactureMarket(scope.row)"
                      icon
                    >
                      <i class="fas fa-paper-plane"></i>
                    </base-button>
                  </el-tooltip>
              </template>
            </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                type="market"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui'
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'
  import Fuse from 'fuse.js';
  import Swal from 'sweetalert2'
  import moment from 'moment'

  export default { 
    apollo: {
      periodes_comptabilites: {
        client: 'MARKET',
        query: gql` query {
          periodes_comptabilites {
            annee
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.PeriodeSelects.ListPeriodeAnnee = data.periodes_comptabilites
        },
      },
      attente_facture: {
        client: 'MARKET',
        query: gql` query {
          attente_facture 
        }`,
        fetchPolicy: 'network-only'
      },
      comptabilites: {
        client: 'MARKET',
        query: gql` query ($client: Int, $periodes: String) {
          getFacturesMarket (client: $client, periodes: $periodes) {
            mois
            annee
            societe
            pk_annonceur
            total_ht
            remise
            remboursement_devalidations
            etat
            total_ttc
            montant_tva
            pk_facture
            credit
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.SkipQuery = true
          this.Comptabilites = this.searchComptabilites = data.getFacturesMarket
          this.fuseSearch = new Fuse(this.Comptabilites, this.options);
          this.ClientSelects.ListClient = data.getFacturesMarket.map(obj => ({societe: obj.societe, pk_annonceur:obj.pk_annonceur}))
          this.montant_ht = 0
          data.getFacturesMarket.forEach(element => {
            this.montant_ht += (element.total_ht - element.remise - element.remboursement_devalidations)
            this.montant_ttc += (element.total_ttc)
          });
        },
        update: data => data.getFacturesMarket,
        variables () {
          return {
            client: this.ClientSelects.Client,
            periodes:(this.PeriodeSelects.Periode.mois+'-'+this.PeriodeSelects.Periode.annee)
          }
        },
        skip() {
          return this.SkipQuery
        },
        fetchPolicy: 'network-only'
      },
      Filters: {
        client: 'MARKET',
        query: gql` query {
          clients {
            fk_annonceur
            societe
          }
        }`,
        result({ data, loader, networkStatus }) {
          /*this.ClientSelects.ListClient = data.clients*/
        },
        update: data => data
      }
    },
    watch: {
      searchQuery(value) {
        let result = this.Comptabilites;
        if (value !== '' && this.fuseSearch) {
          result = this.fuseSearch.search(this.searchQuery);
        }
        this.searchComptabilites = result;
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BasePagination
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchComptabilites.length
      }
    },
    data() {
      return {
        fuseSearch: null,
        SkipQuery: false,
        searchQuery: '',
        montant_ht: 0,
        montant_ttc:0,
        comptabilites: [],
        ClientSelects: {
          Client: '',
          ListClient: []
        },
        PeriodeSelects: {
          Periode: {
            mois: moment().subtract(1, 'months').format('MM'),
            annee : moment().subtract(1, 'months').format('YYYY')
          },
          ListPeriode: [],
          ListPeriodeMois: [
            { value: "01", label: "JANVIER" },
            { value: "02", label: "FÉVRIER" },
            { value: "03", label: "MARS" },
            { value: "04", label: "AVRIL" },
            { value: "05", label: "MAI" },
            { value: "06", label: "JUIN" },
            { value: "07", label: "JUILLET" },
            { value: "08", label: "AOÛT" },
            { value: "09", label: "SEPTEMBRE" },
            { value: "10", label: "OCTOBRE" },
            { value: "11", label: "NOVEMBRE" },
            { value: "12", label: "DÉCEMBRE" }
          ]
        },
        Comptabilites: [],
        searchComptabilites: [],
        pagination: {
          perPage: 250,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500, 1000],
          total: 0
        },
        options : {
          threshold: 0.2,
          keys: [
            "mois",
            "annee",
            "societe",
            "total_ht",
            "etat"
          ]
        },
      }
    },
    methods: {
      replaceUTF_8(string) {
        if(!string) 
          return ''

        for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
          string = string.replaceAll(value, key)
        }
        
        return string
      },
      search() {
        
          if(this.PeriodeSelects.Periode.mois > moment().subtract(1, 'months').format('M') && this.PeriodeSelects.Periode.annee == moment().subtract(1, 'months').format('YYYY')) {
            this.$notify({type: 'warning', message: 'les factures ne sont pas disponibles pour ce mois!!' })
          } else {
            this.SkipQuery = false
          }
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      CustomSort_periode(a, b) {
        return (a.mois + '-' + a.annee).localeCompare(b.mois + '-' + b.annee)
      },
      DownloadFactureMarket(row) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/generate-facture-pdf";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        let periode = this.appendLeadingZeroes(row.mois)+"-"+row.annee 
        formData.append('periode', periode)
        formData.append('id_utilisateur_leadmarket', row.pk_annonceur)
        this.axios.post(url_api,formData , {
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            authorization: `Bearer ${accessToken}` 
          },
          responseType: "blob"
        }).then(function(response) {
          const filename = "facture-" + periode + "-" + row.pk_annonceur +".pdf";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
      },
      ValidationFactureMarket(row) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/validation_facture_market";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('mois', row.mois)
        formData.append('annee', row.annee)
        formData.append('id_annonceur', row.pk_annonceur)
        this.axios.post(url_api,formData , {
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            authorization: `Bearer ${accessToken}` 
          },
          responseType: "blob"
        }).then(function(response) {
          row.etat = 'valide'
          this.$notify({type: 'success', message: 'Facture est validée avec succès' })
        }.bind(this))
        .catch(function (error) {
          this.$notify({type: 'warning', message: 'la validation a échoué' })
        })
      },
      appendLeadingZeroes(n){
        if(n <= 9){
          return "0" + n;
        }
        return n
      },
      async ValideFactures() {
        let result = await Swal.fire({
          title: '',
          text: "VOULEZ-VOUS VRAIEMENT VALIDER LES FACTURES ?",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'VALIDER',
          cancelButtonText: 'ANNULER'
        })

        if (result.value) {
          console.log(result)
        }
        console.log(result)
      },
      getSummaries(param) {
        const { columns, data } = param;

        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(this.montant_ht, true, 2) + " €";
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(this.montant_ttc, true, 2) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
    }
  }
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>