<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t("titlePages.statisticsMyGustave") }}</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input class="col-md-4 mb-2" :label="$t('table.columns.Startdate')">
                      <el-date-picker v-model="StartDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input>
                    <base-input class="col-md-4 mb-2" :label="$t('table.columns.Enddate')">
                      <el-date-picker v-model="EndnDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <div class="row">
                    <base-button 
                      v-if="$apollo.queries.getStatisticsMyGustaveProducts.loading || 
                            $apollo.queries.getStatisticsMyGustaveAnimalsClients.loading ||
                            $apollo.queries.getStatisticsMyGustaveHealthClients.loading" 
                      loading 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                    <button 
                      v-else
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                      @click="search"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="card ListGlobalTable">
          <div class="card-body">
            <base-button v-if="$fullscreen.support" @click="toggle('.ListGlobalTable')" style="float: right;" class="btn-link" type="market" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
            <div>
              <div class="row">
                <div class="col-xl-1 col-sm-6">
                  <base-input>
                    <el-select
                      class="select-primary pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-8 col-sm-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
                <!-- <base-input class="col-xl-3 col-sm-6">
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Rechercher"
                    aria-controls="datatables"
                    v-model="searchQuery"
                  >
                  </el-input>
                </base-input> -->
              </div>
              <el-table :data="GlobalTable" v-loading='$apollo.queries.getStatisticsMyGustaveProducts.loading' show-summary :summary-method="getSummaries">
                <el-table-column
                  prop='product'
                  :label="$t('table.columns.Product')"
                  minWidth='150'
                  show-overflow-tooltip
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop='nb_cotation'
                  :label="$t('table.columns.numberOfQuotations')"
                  minWidth='205'
                  show-overflow-tooltip
                  sortable
                >
                  <template scope="scope">
                    <label style="cursor: pointer;" @click="getExtract(scope.row.product, 'cotations')">
                      <u>
                        {{ formatThounsends(scope.row.nb_cotation) }}
                      </u>
                    </label>
                  </template>
                </el-table-column>
                <el-table-column
                  label="MER"
                  prop="mer"
                  min-width="90"
                  show-overflow-tooltip
                  sortable
                >
                  <template scope="scope">
                    <label style="cursor: pointer;" @click="getExtract(scope.row.product, 'mer')">
                      <u>
                        {{ formatThounsends(scope.row.mer) }}
                      </u>
                    </label>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('table.columns.MerRate')"
                  prop="taux_mer"
                  min-width="140"
                  show-overflow-tooltip
                  sortable
                >
                  <template scope="scope">
                    <label :style="(scope.row.taux_mer < 30) ? 'color: red' : 'color: green'">
                      {{ formatThounsends(scope.row.taux_mer) + " %" }}
                    </label>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('table.columns.nameOfLeadsSuiteAbandon')"
                  prop="nb_leads_abandonment"
                  min-width="270"
                  show-overflow-tooltip
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.nb_leads_abandonment) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <!-- <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p> -->
              </div>
              <base-pagination
                type="market"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </div>
        <div class="card ListAnimauxTable">
          <span slot="header" class="badge-pill badge-market tab-title" @click="toggle('.ListAnimauxTable')" style="text-transform: uppercase;font-weight: bold;">
            <i class="fas fa-paw icon-large"></i>
            {{ $t('labels.Animals') }}
            <base-button v-if="$fullscreen.support" style="float: right;" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="AnimauxTable" v-loading='$apollo.queries.getStatisticsMyGustaveAnimalsClients.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                {{ $t('labels.Animals') }}
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop='force'
                label="Total MER force"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.force) }}
                </template>
              </el-table-column> -->
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="Forcage MER"
                minWidth='120'
              > -->
                <!-- <template scope="scope">
                  <div class="row" v-if="scope.row.id_client === '515'">
                    <base-input type="number" v-model="scope.row.force" class="col-4"></base-input>
                    <base-input type="number" v-model="scope.row.interval" class="col-4"></base-input>
                    <el-tooltip
                      content="Activer ou Désactiver"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-checkbox :value="scope.row.status==1" @change.native="statusAnnimauxChange($event, scope.row)" class="mb-2 col-1"></base-checkbox>
                    </el-tooltip>
                    <el-tooltip
                      content="validé"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="ChangeForceAnimaux(scope.row)"
                        class="btn-link col-2"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-check-2"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </template> -->
              <!-- </el-table-column> -->
            </el-table>
          </div>
        </div>
        <div class="card ListSanteTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="fas fa-heartbeat icon-large"></i>
            {{ $t('labels.Health') }}
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListSanteTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="SanteTable" v-loading='$apollo.queries.getStatisticsMyGustaveHealthClients.loading' show-summary :summary-method="getSummaries_Sante">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
                :sort-method="CustomSort_nameClient"
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                {{ $t('labels.Health') }}
              </el-table-column>
              <el-table-column
                prop='type'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ scope.row.prix_lead +  " €"}}
                </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mer"
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                  <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                    {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                  </label>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop='force'
                label="Total MER force"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer) }}
                </template>
              </el-table-column> -->
              <el-table-column
                prop='ca'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.ca, true, 2) + " €" }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="Forcage MER"
                minWidth='120'
              > -->
              <!-- minWidth='380' -->
                <!-- <template scope="scope">
                  <div class="row" v-if="scope.row.id_client === '12868' || scope.row.id_client === '517' || scope.row.id_client === '76' || scope.row.id_client === '190' || scope.row.id_client === '654'">
                    <base-input type="number" v-model="scope.row.force" class="col-4"></base-input>
                    <base-input type="number" v-model="scope.row.interval" class="col-4"></base-input>
                    <el-tooltip
                      content="Activer ou Désactiver"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-checkbox :value="scope.row.status==1" @change.native="statusSanteChange($event, scope.row)" class="mb-2 col-1"></base-checkbox>
                    </el-tooltip>
                    <el-tooltip
                      content="validé"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="ChangeForceSante(scope.row)"
                        class="btn-link col-2"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-check-2"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </template> -->
              <!-- </el-table-column> -->
            </el-table>
          </div>
        </div>
        <div class="card ListMrhTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="fas fa-home icon-large"></i>
            HABITATION
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListMrhTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="MrhTable" v-loading='$apollo.queries.getStatisticsMyGustaveMRH.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                HABITATION
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card ListAutoTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="fas fa-car icon-large"></i>
            AUTO
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListAutoTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="AutoTable" v-loading='$apollo.queries.getStatisticsMyGustaveAUTO.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                AUTO
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card ListEmprunteurTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="tim-icons icon-bank icon-large"></i>
            EMPRUNTEUR
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListEmprunteurTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="EmprunteurTable" v-loading='$apollo.queries.getStatisticsMyGustaveEmprunteur.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                EMPRUNTEUR
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card ListCreditConsoTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="fas fa-credit-card"></i>
            CRÉDIT CONSO
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListCreditConsoTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="CreditConsoTable" v-loading='$apollo.queries.getStatisticsMyGustaveCreditConso.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                CRÉDIT CONSO
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card ListPerTable">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <i class="fa-solid fa-coins"></i>
            ASSURANCE VIE
            <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle('.ListPerTable')" class="btn-link" size="sm" icon>
            <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
            </base-button>
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="PerTable" v-loading='$apollo.queries.getStatisticsMyGustavePer.loading' show-summary :summary-method="getSummaries_Animaux">
              <el-table-column
                prop='name_client'
                label="Client"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.Product')"
                minWidth='90'
                show-overflow-tooltip
              >
                ASSURANCE VIE
              </el-table-column>
              <el-table-column
                prop='product'
                label="Type"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                STANDARD
              </el-table-column>
              <el-table-column
                prop='prix_lead'
                :label="$t('table.columns.advertiserPrice')"
                minWidth='170'
                show-overflow-tooltip
                sortable
              >
              <template scope="scope">
                {{ scope.row.prix_lead + " €" }}
              </template>
              </el-table-column>
              <el-table-column
                prop='imp'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='205'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.imp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="MER"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number) }}
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                :label="$t('table.columns.MerRate')"
                minWidth='140'
                show-overflow-tooltip
                :sort-method="CustomSort_tauxmer_sante"
                sortable
              >
                <template scope="scope">
                <label :style="((scope.row.mer_number / scope.row.imp) * 100 < 30 || scope.row.imp==0 ) ? 'color: red' : 'color: green'">
                  {{  (scope.row.imp != 0) ? (( scope.row.mer_number / scope.row.imp) * 100).toFixed(2) : (0).toFixed(2) }} %
                </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='mer_number'
                label="CA Net"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.mer_number * scope.row.prix_lead, true, 2) + " €" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination, BaseCheckbox } from 'src/components';
  import gql from 'graphql-tag'
  import PerfectScrollbar from 'perfect-scrollbar';

  let today = new Date()

  export default {
    apollo: {
      getStatisticsMyGustaveProducts: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveProducts(date_debut: $date_debut, date_fin: $date_fin) {
              affichage_sante
              affichage_animaux
              mer_sante
              mer_animaux
              ab_sante
              ab_animaux
              affichage_mrh
              mer_mrh    
              ab_mrh
              affichage_auto
              mer_auto
              ab_auto
              affichage_emprunteur
              mer_emprunteur
              ab_emprunteur
              affichage_creditconso
              ab_creditconso
              mer_creditconso
              affichage_per
              ab_per
              mer_per
            }
          }
        `,
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Global
        },
        result({ data, loader, networkStatus }) {
          this.GlobalTable = [
            {
              product: 'ASSURANCE ANIMAUX', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_animaux, 
              mer: data.getStatisticsMyGustaveProducts.mer_animaux, 
              taux_mer: ((data.getStatisticsMyGustaveProducts.mer_animaux / data.getStatisticsMyGustaveProducts.affichage_animaux) * 100).toFixed(2), 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_animaux
            },
            {
              product: 'ASSURANCE AUTO', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_auto, 
              mer: data.getStatisticsMyGustaveProducts.mer_auto, 
              taux_mer: data.getStatisticsMyGustaveProducts.affichage_auto !=0 ? ((data.getStatisticsMyGustaveProducts.mer_auto / data.getStatisticsMyGustaveProducts.affichage_auto) * 100).toFixed(2) : 0, 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_auto
            },
            {
              product: 'ASSURANCE EMPRUNTEUR', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_emprunteur, 
              mer: data.getStatisticsMyGustaveProducts.mer_emprunteur, 
              taux_mer: data.getStatisticsMyGustaveProducts.affichage_emprunteur !=0 ? ((data.getStatisticsMyGustaveProducts.mer_emprunteur / data.getStatisticsMyGustaveProducts.affichage_emprunteur) * 100).toFixed(2) : 0, 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_emprunteur
            },
            {
              product: 'ASSURANCE HABITATION', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_mrh, 
              mer: data.getStatisticsMyGustaveProducts.mer_mrh, 
              taux_mer: data.getStatisticsMyGustaveProducts.affichage_mrh !=0 ? ((data.getStatisticsMyGustaveProducts.mer_mrh / data.getStatisticsMyGustaveProducts.affichage_mrh) * 100).toFixed(2) : 0, 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_mrh
            }, 
            {
              product: 'ASSURANCE SANTÉ', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_sante, 
              mer: data.getStatisticsMyGustaveProducts.mer_sante,
              taux_mer: ((data.getStatisticsMyGustaveProducts.mer_sante / data.getStatisticsMyGustaveProducts.affichage_sante) * 100).toFixed(2), 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_sante
            },
            {
              product: 'CRÉDIT CONSO', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_creditconso, 
              mer: data.getStatisticsMyGustaveProducts.mer_creditconso,
              taux_mer: data.getStatisticsMyGustaveProducts.affichage_creditconso !=0 ? ((data.getStatisticsMyGustaveProducts.mer_creditconso / data.getStatisticsMyGustaveProducts.affichage_creditconso) * 100).toFixed(2) : 0, 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_creditconso
            },
            {
              product: 'ASSURANCE VIE', 
              nb_cotation: data.getStatisticsMyGustaveProducts.affichage_per, 
              mer: data.getStatisticsMyGustaveProducts.mer_per,
              taux_mer: data.getStatisticsMyGustaveProducts.affichage_per !=0 ? ((data.getStatisticsMyGustaveProducts.mer_per / data.getStatisticsMyGustaveProducts.affichage_per) * 100).toFixed(2) : 0, 
              nb_leads_abandonment: data.getStatisticsMyGustaveProducts.ab_per
            }
          ]
          this.SkipQuery_Global = true
        },
        fetchPolicy: 'network-only',
        update: data => data.getStatisticsMyGustaveProducts,
      },
      getStatisticsMyGustaveAnimalsClients: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveAnimalsClients(date_debut: $date_debut, date_fin: $date_fin) {
              id_client
              name_client
              mer_number
              imp
              prix_lead
              produit
              interval
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Animaux
        },
        result({ data, loader, networkStatus }) {
          this.AnimauxTable = data.getStatisticsMyGustaveAnimalsClients.filter(item => { return item.imp != 0 })
          this.SkipQuery_Animaux = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustaveMRH: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveMRH(date_debut: $date_debut, date_fin: $date_fin) {
              force
              id_client
              imp
              interval
              mer_number
              name_client
              prix_lead
              produit
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Mrh
        },
        result({ data, loader, networkStatus }) {
          this.MrhTable = data.getStatisticsMyGustaveMRH.filter(item => { return item.imp != 0 })
          // this.MrhTable = data.getStatisticsMyGustaveMRH
          this.SkipQuery_Mrh = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustaveAUTO: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveAUTO(date_debut: $date_debut, date_fin: $date_fin) {
              force
              id_client
              imp
              interval
              mer_number
              name_client
              prix_lead
              produit
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Auto
        },
        result({ data, loader, networkStatus }) {
          this.AutoTable = data.getStatisticsMyGustaveAUTO.filter(item => { return item.imp != 0 })
          this.SkipQuery_Auto = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustaveEmprunteur: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveEmprunteur(date_debut: $date_debut, date_fin: $date_fin) {
              force
              id_client
              imp
              interval
              mer_number
              name_client
              prix_lead
              produit
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Emprunteur
        },
        result({ data, loader, networkStatus }) {
          this.EmprunteurTable = data.getStatisticsMyGustaveEmprunteur.filter(item => { return item.imp != 0 || item.mer_number != 0  })
          this.SkipQuery_Emprunteur = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustaveCreditConso: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveCreditConso(date_debut: $date_debut, date_fin: $date_fin) {
              force
              id_client
              imp
              interval
              mer_number
              name_client
              prix_lead
              produit
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_CreditConso
        },
        result({ data, loader, networkStatus }) {
          this.CreditConsoTable = data.getStatisticsMyGustaveCreditConso.filter(item => { return item.imp != 0 || item.mer_number != 0  })
          this.SkipQuery_CreditConso = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustavePer: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustavePer(date_debut: $date_debut, date_fin: $date_fin) {
              force
              id_client
              imp
              interval
              mer_number
              name_client
              prix_lead
              produit
              status
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Per
        },
        result({ data, loader, networkStatus }) {
          this.PerTable = data.getStatisticsMyGustavePer.filter(item => { return item.imp != 0 || item.mer_number != 0  })
          this.SkipQuery_Per = true
        },
        fetchPolicy: 'network-only',
      },
      getStatisticsMyGustaveHealthClients: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            getStatisticsMyGustaveHealthClients(date_debut: $date_debut, date_fin: $date_fin) {
              name_client
              type
              prix_lead
              imp
              mer_number
              id_client
              status_client
            }
          }
        `,
        variables () {
          return {
            date_debut: this.StartDate,
            date_fin: this.EndnDate
          }
        },
        skip() {
          return this.SkipQuery_Sante
        },
        result({ data, loader, networkStatus }) {
          this.SanteTable = data.getStatisticsMyGustaveHealthClients.filter(item => { return item.imp != 0 })
          let rmindex = -1;
          this.SanteTable.forEach((element,index) => {
            // if (element.id_client == '14' && element.type == 'salaries') {
            //   let find = this.SanteTable.find(x => x.type == 'seniors' && x.id_client == '14')
            //   if (find) {
            //     find.ca = element.mer_number * find.prix_lead + find.mer_number * find.prix_lead
            //     find.mer_number = element.mer_number + find.mer_number
            //     find.imp = element.imp + find.imp
            //   }
            //   rmindex = index
            // }
            // else {
              element.ca = element.mer_number * element.prix_lead
            // }
          });
          if (rmindex != -1) {
            this.SanteTable.splice(rmindex, 1);
          }
          this.SkipQuery_Sante = true
        },
        fetchPolicy: 'network-only',
      }
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      BaseCheckbox
    },
    data() {
      return {
        fullscreen: false,
        PerfectScrollbar: null,
        StartDate: null,
        EndnDate: null,
        searchQuery: '',
        GlobalTable: [],
        AnimauxTable: [],
        MrhTable: [],
        AutoTable: [],
        EmprunteurTable: [],
        CreditConsoTable: [],
        PerTable: [],
        SanteTable: [],
        total: 0,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 25, 50, 100],
          total: 0,
        },
        SkipQuery_Global: true,
        SkipQuery_Sante: true,
        SkipQuery_Animaux: true,
        SkipQuery_Mrh: true,
        SkipQuery_Auto: true,
        SkipQuery_Emprunteur: true,
        SkipQuery_CreditConso: true,
        SkipQuery_Per: true,
      }
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    created() {
      this.StartDate = today.getFullYear()+"-"+this.appendLeadingZeroes(today.getMonth() + 1)+"-"+this.appendLeadingZeroes(today.getDate())
      this.EndnDate = today.getFullYear()+"-"+this.appendLeadingZeroes(today.getMonth() + 1)+"-"+this.appendLeadingZeroes(today.getDate())
    },
    methods: {
      toggle (div) {
        this.$fullscreen.toggle(this.$el.querySelector(div), {
          wrap: false,
          callback: this.fullscreenChange
        })
      },
      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen
        if (fullscreen) {
          this.PerfectScrollbar = new PerfectScrollbar(".ListSanteTable");
        }
        else {
          this.PerfectScrollbar.destroy();
          this.PerfectScrollbar = null
        }
      },
      CustomSort_nameClient(a, b) {
        return a.name_client.localeCompare(b.name_client);
      },
      search() {
        this.SkipQuery_Global = false
        this.SkipQuery_Sante = false
        this.SkipQuery_Animaux = false
        this.SkipQuery_Mrh = false
        this.SkipQuery_Auto = false
        this.SkipQuery_Emprunteur = false
        this.SkipQuery_CreditConso = false
        this.SkipQuery_Per = false
      },
      statusAnnimauxChange(event, row) {
        if (row.status == 0) {
          row.status = 1
        } else if (row.status == 1) {
          row.status = 0
        } 
      },
      statusSanteChange(event, row) {
        if (row.status == 0) {
          row.status = 1
        } else if (row.status == 1) {
          row.status = 0
        } 
      },
      getSummaries(param) {
        const { columns, data } = param;
        let somme_cotation = 0
        let somme_mer = 0
        let somme_taux_mer = 0
        let somme_leads_abandonment = 0
        data.forEach(stats => {
          somme_cotation += stats.nb_cotation
          somme_mer += stats.mer
          somme_taux_mer += stats.taux_mer
          somme_leads_abandonment += stats.nb_leads_abandonment
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 1) {
            sums[index] = this.formatThounsends(somme_cotation);
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(somme_mer);
            return;
          } else if (index === 3) {
            sums[index] = ((somme_mer / somme_cotation) * 100).toFixed(2) +" %";
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(somme_leads_abandonment);
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      getSummaries_Animaux(param) {
        const { columns, data } = param;
        let somme_prix_lead = 0
        let somme_imp = 0
        let somme_mer = 0
        let somme_ca = 0
        data.forEach(stats => {
          somme_prix_lead += stats.prix_lead
          somme_imp += stats.imp
          somme_mer += stats.mer_number
          // somme_force += stats.force
          somme_ca += stats.mer_number * stats.prix_lead
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 3) {
            sums[index] = somme_prix_lead + " €";
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(somme_imp);
            return;
          } else if (index === 5) {
            sums[index] = this.formatThounsends(somme_mer);
            return;
          } else if (index === 6) {
            sums[index] = somme_imp !=0 ? ((somme_mer / somme_imp) * 100).toFixed(2) +" %" : "0.00 %";
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(somme_ca, true, 2) +" €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      getSummaries_Sante(param) {
        const { columns, data } = param;
        let somme_prix_lead = 0
        let somme_imp = 0
        let somme_mer = 0
        // let somme_force = 0
        let somme_ca = 0
        data.forEach(stats => {
          somme_prix_lead += stats.prix_lead
          somme_imp += stats.imp
          somme_mer += stats.mer_number
          // somme_force += stats.force
          somme_ca += stats.ca
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 3) {
            sums[index] = somme_prix_lead + " €";
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(somme_imp);
            return;
          } else if (index === 5) {
            sums[index] = this.formatThounsends(somme_mer);
            return;
          } else if (index === 6) {
            sums[index] = ((somme_mer / somme_imp) * 100).toFixed(2) +" %";
            return;
          } else if (index === 7) {
            sums[index] = this.formatThounsends(somme_ca, true, 2) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      getExtract(product, type) {
        let url = 'https://www.mongustave.fr/api/telecharge_stat?key=f0ac6ac387a9dbfef9801ef586663dbad154b166&dateDebut='+this.StartDate+'&dateFin='+this.EndnDate
        if (type == "mer" && product == 'ASSURANCE ANIMAUX') {
          url += '&produit=2&option=2'
        }
        else if (type == "mer" && product == 'ASSURANCE SANTÉ'){
          url += '&produit=1&option=2'
        }
        else if (type == "mer" && product == 'ASSURANCE HABITATION'){
          url = url.replace('telecharge_stat','telecharge_stat_mrh')
          url += '&option=2' 
        }
        else if (type == "mer" && product == 'ASSURANCE AUTO'){
          url = url.replace('telecharge_stat','telecharge_stat_auto')
          url += '&option=2' 
        }
        else if (type == "mer" && product == 'ASSURANCE EMPRUNTEUR'){
          url = url.replace('telecharge_stat','telecharge_stat_emprunteur')
          url += '&option=2' 
        }
        else if (type == "mer" && product == 'CRÉDIT CONSO'){
          url = url.replace('telecharge_stat','telecharge_stat_creditconso')
          url += '&option=2' 
        }
        else if (type == "mer" && product == 'ASSURANCE VIE'){
          url = url.replace('telecharge_stat','telecharge_stat_per')
          url += '&option=2' 
        }
        else if (type == "cotations" && product == 'ASSURANCE ANIMAUX'){
          url += '&produit=2&option=1'
        }
        else if (type == "cotations" && product == 'ASSURANCE SANTÉ'){
          url += '&produit=1&option=1'
        }
        else if (type == "cotations" && product == 'ASSURANCE HABITATION'){
          url = url.replace('telecharge_stat','telecharge_stat_mrh')
          url += '&option=1'
        }
        else if (type == "cotations" && product == 'ASSURANCE AUTO'){
          url = url.replace('telecharge_stat','telecharge_stat_auto')
          url += '&option=1'
        }
        else if (type == "cotations" && product == 'ASSURANCE EMPRUNTEUR'){
          url = url.replace('telecharge_stat','telecharge_stat_emprunteur')
          url += '&option=1'
        }
        else if (type == "cotations" && product == 'CRÉDIT CONSO'){
          url = url.replace('telecharge_stat','telecharge_stat_creditconso')
          url += '&option=1'
        }
        else if (type == "cotations" && product == 'ASSURANCE VIE'){
          url = url.replace('telecharge_stat','telecharge_stat_per')
          url += '&option=1'
        }
        else {
          return
        }
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', 'facture-'+mois+'-'+annee+'.pdf');
        document.body.appendChild(link);
        link.click();
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value :value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      appendLeadingZeroes(n){
        if(n <= 9){
          return "0" + n;
        }
        return n
      },
      CustomSort_tauxmer_sante(a, b) {
        let taux1 = (a.imp != 0) ? ( a.mer_number / a.imp) * 100 : 0
        let taux2 = (b.imp != 0) ? ( b.mer_number / b.imp) * 100 : 0
        return taux1 - taux2
      }
    },
  }
  </script>

<style>

  .booking-table-rows {
    text-transform: uppercase;
  }

</style>