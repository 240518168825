<template>
  <div class="content">
    <h3>{{ $t('table.columns.Categories').toUpperCase() }}</h3>
    <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
      <router-link
        :to="{ name: 'Ajoute Categorie LeadMarket Admin'}"
        class="btn btn-round btn-just-icon btn-market"
      >{{ $t('buttons.CreateNewCategory') }}</router-link>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <base-input class="col-md-4">
            <el-date-picker v-model="StartDate" :disabled="$apollo.queries.categories.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-4">
            <el-date-picker v-model="EndnDate" :disabled="$apollo.queries.categories.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-4">
            <el-select filterable class="select-primary"
              v-model="GameSelects.Game"
              :disabled="$apollo.queries.categories.loading">
              <el-option 
                v-for="option in GameSelects.ListGame"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xs-4 text-right">
          <base-button 
            v-if="$apollo.queries.categories.loading" 
            loading 
            class="btn btn-default" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-default" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div>
      </div>
    </div>    
    <div class="card listcategories">
      <div class="card-body">
        <base-button v-if="$fullscreen.support" style="float: right;" @click="toggle" class="btn-link" type="market" size="sm" icon>
          <i :class="[fullscreen ? 'fas fa-compress icon-large' : 'fas fa-expand icon-large']"></i>
        </base-button>
        <base-input class="col-lg-4 col-sm-12">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch2').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
        <el-table :data="searchCategorie" v-loading="$apollo.queries.categories.loading" row-class-name="booking-table-rows" show-summary :summary-method="getSummaries" class="customtable_cmd">
          <el-table-column
            prop='categorie'
            :label="$t('table.columns.Product')"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              <i :class="icons[scope.row.categorie.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.categorie.replace(/Ã©/g,'é').toUpperCase()"></i>
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_collectes'
            :label="$t('table.columns.Collected')"
            minWidth='115'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ formatThounsends(scope.row.leads_collectes) }}
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_nets'
            :label="$t('table.columns.CollectedNet')"
            minWidth='135'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              <base-button type="success" size="sm" @click="showLeadsDetails(scope.row, 'collecte')" :disabled="loadingLeadsDetails">
                {{ formatThounsends(scope.row.leads_nets) }}
              </base-button>
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_disponibles'
            :label="$t('table.columns.Available')"
            minWidth='120'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              <base-button type="success" size="sm" @click="showLeadsDetails(scope.row, 'dispo')" :disabled="loadingLeadsDetails">
                {{ formatThounsends(scope.row.leads_disponibles) }}
              </base-button>
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_vendus'
            :label="$t('table.columns.sold')"
            minWidth='150'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              <base-button type="notif" size="sm" @click="showLeadsDetails(scope.row, 'vendu')" :disabled="loadingLeadsDetails">
                {{ formatThounsends(scope.row.leads_vendus) }}
              </base-button>
              {{ (scope.row.leads_collectes != 0) ? formatThounsends((scope.row.leads_vendus * 100) / scope.row.leads_collectes, true, 2) : '0' }} %
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_vendus_trois_fois'
            :label="$t('table.columns.Sold3Times')"
            minWidth='135'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ formatThounsends(scope.row.leads_vendus_trois_fois) + " - " }}
              {{ (scope.row.leads_collectes != 0) ? formatThounsends((scope.row.leads_vendus_trois_fois * 100) / scope.row.leads_collectes, true, 2) : '0' }} %
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_invendus'
            :label="$t('table.columns.Unsold')"
            minWidth='115'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ formatThounsends(scope.row.leads_invendus) }}
            </template>
          </el-table-column>
          <el-table-column
            prop='leads_devalides'
            :label="$t('table.columns.InvalidationRate')"
            minWidth='140'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ (scope.row.leads_vendus != 0) ? formatThounsends((scope.row.leads_devalides * 100) / scope.row.leads_vendus, true, 2) : '0' }} %
            </template>
          </el-table-column>
          <el-table-column
            prop='ca_realise'
            :label="$t('table.columns.GrossRealizedTurnover') + ' (€ HT)'"
            minWidth='145'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ formatThounsends(scope.row.ca_realise, true, 2) }}
            </template>
          </el-table-column>
          <el-table-column
            prop='ca_realise_net'
            :label="$t('table.columns.NetTurnover') + ' (€ HT)'"
            minWidth='140'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              {{ formatThounsends(calculate_CaNet(scope.row), true, 2) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            minWidth='120'
          >
            <template scope="scope">
              <el-tooltip
                content="Editer"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click="OpenEdit(scope.row.categorie_id)"
                  class="btn-link"
                  type="warning"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil icon-large"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip
                content="Rapport"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button
                  @click="OpenRapport(scope.row)"
                  class="btn-link"
                  type="success"
                  size="sm"
                  icon
                >
                  <i class="fas fa-chart-line icon-large"></i>
                </base-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- modal Details Leads -->
    <modal
      :show.sync="ShowDetailsLeadsModal"
      footerClasses="justify-content-center"
      class="modal-showInfoLeads"
      body-classes="p-0"
    >
      <card class="border-0 mb-0" style="max-height:100vh;overflow:auto;">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="ShowDetailsLeadsModal = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </template>

        <h3>DÉTAILS</h3>
        <div class="table-responsive" v-if="ListDetailsLeads.length">
          <base-table :data="ListDetailsLeads" thead-classes="text-primary" >
            <template slot="columns" slot-scope="{ columns }">
              <th>TAG</th>
              <th>VOLUME LEADS NET DE DOUBLON</th>
            </template>
            <template slot-scope="{ row, index }">
              <td class="text-uppercase">{{ row.nom }}</td>
              <td>{{ formatThounsends(row.leads_number) }}</td>
            </template>
          </base-table>
        </div>
        <base-alert v-else class="col-12" type="warning">
            PAS DE LEAD COLLECTÉS.
        </base-alert>

      </card>
    </modal>
    <!-- modal Details Leads -->
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import Fuse from 'fuse.js';
import gql from 'graphql-tag'
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";
import { Modal, BaseTable, BaseAlert } from 'src/components';

let today = new Date()

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseTable,
    Modal,
    BaseAlert
  },
  apollo: {
    categories: {
      client: 'MARKET',
      query: gql` query ($date_debut: String, $date_fin: String, $gamme: String) {
        categoriesStats (date_debut: $date_debut, date_fin: $date_fin, gamme: $gamme) {
          taux_mer_sante
          taux_mer_animaux
          taux_mer_mrh
          taux_mer_auto
          taux_mer_emprunteur
          taux_mer_creditconso
          taux_mer_per
          resumes {
            categorie_id
            categorie
            leads_collectes
            leads_livres
            ca_paye
            ca_realise
            ca_realise_net
            leads_devalides
            leads_nets
            leads_vendus
            leads_vendus_trois_fois
            leads_invendus
            leads_disponibles
          }
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ListCategorie = this.searchCategorie = data.categoriesStats.resumes
        this.fuseSearch = new Fuse(this.ListCategorie, this.options);
        this.SkipQuery = true
      },
      variables () {
        return {
          date_debut: this.StartDate,
          date_fin: this.EndnDate,
          gamme: this.GameSelects.Game
        }
      },
      skip () {
        return this.SkipQuery
      },
      fetchPolicy: 'network-only',
      update: data => data.categoriesStats
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.ListCategorie;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchCategorie = result;
    }
  },
  data() {
    return {
      // StartDate: today.getFullYear()+"-"+(today.getMonth() + 1)+"-"+today.getDate(),
      // EndnDate: today.getFullYear()+"-"+(today.getMonth() + 1)+"-"+today.getDate(),
      loadingLeadsDetails: false,
      ShowDetailsLeadsModal: false,
      ListDetailsLeads: [],
      fullscreen: false,
      icons: Icons,
      SkipQuery: true,
      ListCategorie: [],
      searchCategorie: [],
      StartDate: null,
      EndnDate: null,
      GameSelects: {
        Game: '',
        ListGame: [
          { value: '', label: this.$t("labels.allRanges") },
          { value: 'exclusif', label: 'EXCLUSIF' },
          { value: 'duo', label: 'DUO' },
          { value: 'trio', label: 'TRIO' },
        ]
      },
      searchQuery: '',
      fuseSearch: null,
      options : {
        // findAllMatches: true,
        threshold: 0.2,
        keys: [
          "categorie",
          "leads_collectes",
          "leads_livres",
          "ca_paye",
          "ca_realise",
          "ca_realise_net",
          "leads_devalides",
          "leads_nets",
          "leads_vendus",
          "leads_vendus_trois_fois",
          "leads_invendus",
          "leads_disponibles",
        ]
      }
    }
  },
  methods: {
    toggle () {
      this.$fullscreen.toggle(this.$el.querySelector('.listcategories'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
    },
    search () {
      this.SkipQuery = false
    },
    calculate_CaNet(row) {
      let ca_net = 0
      if (row.categorie_id === '1') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_sante
      }
      else if (row.categorie_id === '4') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_animaux
      }
      else if (row.categorie_id === '3') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_mrh
      }
      else if (row.categorie_id === '2') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_auto
      }
      else if (row.categorie_id === '12') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_emprunteur
      }
      else if (row.categorie_id === '100') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_creditconso
      }
      else if (row.categorie_id === '14') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_per
      }
      else {
        ca_net = row.ca_realise_net
      }
      return ca_net
    },
    OpenEdit(categorie_id) {
      let routeData = this.$router.resolve({ name:'Edit Categorie LeadMarket Admin', params: { categorie_id: categorie_id } });
      window.open(routeData.href, '_blank');
    },
    OpenRapport(row) {
      let routeData = this.$router.resolve({ name:'Rapport Categorie LeadMarket Admin', params: { categorie_id: row.categorie_id, categorie: row.categorie.replace(/Ã©/g,"é") } });
      window.open(routeData.href, '_blank');
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
        let somme_leads_collectes = 0
        let somme_leads_nets = 0
        let somme_leads_disponibles = 0
        let somme_leads_vendus = 0
        let somme_leads_vendus_trois_fois = 0
        let somme_leads_invendus = 0
        let somme_leads_devalides = 0
        let somme_ca_realise = 0
        let somme_ca_realise_net = 0
        data.forEach(stats => {
          somme_leads_collectes += stats.leads_collectes
          somme_leads_nets += stats.leads_nets
          somme_leads_disponibles += stats.leads_disponibles
          somme_leads_vendus += stats.leads_vendus
          somme_leads_vendus_trois_fois += stats.leads_vendus_trois_fois
          somme_leads_invendus += stats.leads_invendus
          somme_leads_devalides += stats.leads_devalides
          somme_ca_realise += stats.ca_realise
          somme_ca_realise_net += stats.ca_realise_net
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 1) {
            sums[index] = this.formatThounsends(somme_leads_collectes);
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(somme_leads_nets);
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(somme_leads_disponibles);
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(somme_leads_vendus) + " - " + ((somme_leads_collectes != 0) ? this.formatThounsends((somme_leads_vendus * 100) / somme_leads_collectes, true, 2) : '0') + " %";
            return;
          } else if (index === 5) {
            sums[index] = this.formatThounsends(somme_leads_vendus_trois_fois) + " - " + ((somme_leads_collectes != 0) ? this.formatThounsends((somme_leads_vendus_trois_fois * 100) / somme_leads_collectes, true, 2) : '0') + " %";
            return;
          } else if (index === 6) {
            sums[index] = this.formatThounsends(somme_leads_invendus);
            return;
          } else if (index === 7) {
            sums[index] = ((somme_leads_vendus != 0) ? this.formatThounsends((somme_leads_devalides * 100) / somme_leads_vendus, true, 2) : '0') + " %";
            return;
          } else if (index === 8) {
            sums[index] = this.formatThounsends(somme_ca_realise, true, 2);
            return;
          } else if (index === 9 && this.categories) {
            sums[index] = this.formatThounsends(somme_ca_realise_net + this.categories.taux_mer_sante + this.categories.taux_mer_animaux + this.categories.taux_mer_mrh + this.categories.taux_mer_auto + this.categories.taux_mer_emprunteur + this.categories.taux_mer_creditconso + this.categories.taux_mer_per, true, 2);
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
    },
    async showLeadsDetails(row, type) {
      this.loadingLeadsDetails = true
      this.ListDetailsLeads = []
      if (type === "collecte") {
        await this.$apollo.query({
          client: 'MARKET',
          fetchPolicy: 'network-only',
          query: gql` query ($id_category: ID, $date_fin: String, $date_debut: String) {
            getLeadsDetailsByCategory(
              id_category: $id_category
              date_debut: $date_debut
              date_fin: $date_fin
            ){
              nom
              leads_number
            }
          }`,
          variables: {
            date_debut: this.StartDate,
            date_fin: this.EndnDate,
            id_category: row.categorie_id
          },
        }).then(data => {
          this.ListDetailsLeads = data.data.getLeadsDetailsByCategory
        }).catch((error) => {
          console.error(error)
        })
      }
      else if (type === "vendu"){
        await this.$apollo.query({
          client: 'MARKET',
          fetchPolicy: 'network-only',
          query: gql` query ($id_category: ID, $date_fin: String, $date_debut: String) {
            getLeadsDetailsByCategory_vendu(
              id_category: $id_category
              date_debut: $date_debut
              date_fin: $date_fin
            ){
              nom
              leads_number
            }
          }`,
          variables: {
            date_debut: this.StartDate,
            date_fin: this.EndnDate,
            id_category: row.categorie_id
          },
        }).then(data => {
          this.ListDetailsLeads = data.data.getLeadsDetailsByCategory_vendu
        }).catch((error) => {
          console.error(error)
        })
      }
      
      else {
        await this.$apollo.query({
          client: 'MARKET',
          fetchPolicy: 'network-only',
          query: gql` query ($id_category: ID, $date_fin: String, $date_debut: String) {
            getLeadsDetailsByCategory_dispo(
              id_category: $id_category
              date_debut: $date_debut
              date_fin: $date_fin
            ){
              nom
              leads_number
            }
          }`,
          variables: {
            date_debut: this.StartDate,
            date_fin: this.EndnDate,
            id_category: row.categorie_id
          },
        }).then(data => {
          this.ListDetailsLeads = data.data.getLeadsDetailsByCategory_dispo
        }).catch((error) => {
          console.error(error)
        })
      }
      if (this.ListDetailsLeads.length > 0) {
        let find = this.ListDetailsLeads.find(x => x.nom === "TOTAL")
        if (!find) {
          this.ListDetailsLeads.push({
            nom: "TOTAL",
            leads_number: this.ListDetailsLeads.reduce( function(a, b){
                return a + b.leads_number;
              }, 0)
          })
        }
      }
      this.loadingLeadsDetails = false
      this.ShowDetailsLeadsModal = true
    }
  },
}

</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell{
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>