<template>
  <div class="content">
    <div class="row mt-5">
      <h3>{{ $t("titlePages.webserviceMonitoring") }}</h3>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="fas fa-paw icon-large"></i>
          {{ $t('labels.Animals') }}
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_animaux" v-loading='$apollo.queries.Animaux .loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
             <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 60, 80, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 60, 80, scope.row.state)" @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 60, 80, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 2, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="fas fa-car icon-large"></i>
          AUTO
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_auto" v-loading='$apollo.queries.Auto .loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 10, 20, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 10, 20, scope.row.state)"  @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 10, 20, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 4, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="tim-icons icon-bank icon-large"></i>
          EMPRUNTEUR
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_emprunteur" v-loading='$apollo.queries.Emprunteur.loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 60, 70, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 60, 70, scope.row.state)"  @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 60, 70, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 5, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="fas fa-home icon-large"></i>
          HABITATION
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_mrh" v-loading='$apollo.queries.Mrh .loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 60, 70, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 60, 70, scope.row.state)"  @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 60, 70, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 3, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="fas fa-heartbeat icon-large"></i>
          {{ $t('labels.Health') }}
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_sante" v-loading='$apollo.queries.Sante .loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 1, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
          <i class="fas fa-credit-card"></i>
          CRÉDIT CONSO
        </span>
        <div class="card-body">
          <el-table class="customtable_cmd" row-class-name="booking-table-rows" :data="TTLwsCls_creditconso" v-loading='$apollo.queries.CreditConso.loading || laoding_extract'>
            <el-table-column
              prop='name_client'
              label="Clients"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
            </el-table-column>
            <el-table-column
              prop='state'
              label="Status"
              minWidth='85'
            >
              <template scope="scope">
                <template v-if="scope.row.state === '1'">
                  ON
                </template>
                <template v-else-if="scope.row.state === '0'">
                  OFF
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_month'
              :label="$t('table.columns.timeResponseWsOfTheMonth')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_month, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_month, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_yesterday'
              :label="$t('table.columns.TimeAnswerWsD_1')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_yesterday, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_yesterday, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='response_time_today'
              :label="$t('table.columns.TimeAnswerWsD')"
              minWidth='140'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label :style="Color_TimeWS(scope.row.response_time_today, scope.row.state)">
                  {{ formatThounsends(scope.row.response_time_today, true, 2) }}
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_month'
              :label="$t('table.columns.footprintRateOfTheMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_month, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('m', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_yesterday'
              :label="$t('table.columns.footprintRateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_yesterday, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('d-1', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_emprt_today'
              :label="$t('table.columns.footprintRateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxEmprt(scope.row.taux_emprt_today, 40, 60, scope.row.state)" @click="ExtractCsv_Tarif('d', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_emprt_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_month'
              :label="$t('table.columns.MERrateMonth')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_month, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('m', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_month, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_yesterday'
              :label="$t('table.columns.MERrateD_1')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_yesterday, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d-1', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_yesterday, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
            <el-table-column
              prop='taux_mer_today'
              :label="$t('table.columns.MERrateD')"
              minWidth='150'
              show-overflow-tooltip
              sortable
            >
              <template scope="scope">
                <label style="cursor: pointer;" :style="Color_TauxMer(scope.row.taux_mer_today, 20, 30, scope.row.state)" @click="ExtractCsv_Mer('d', scope.row.id_client, 6, scope.row.name_client)">
                  <u>
                    {{ formatThounsends(scope.row.taux_mer_today, true, 2) + " %" }}
                  </u>
                </label>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import { Table, TableColumn } from 'element-ui';
import gql from 'graphql-tag'
import XLSX from 'xlsx'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  apollo: {
    Sante: {
      client: 'MARKET',
      query: gql `
        query {
          Sante:getResponseStatisticsMyGustaveHealthClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.Sante.clients.forEach(clt => {
          // calculate MER & % emprunt Month
          clt.taux_mer_month = data.Sante.mer_total_month ? (clt.mer_month * 100) / data.Sante.mer_total_month : 0
          clt.taux_emprt_month = data.Sante.imp_total_month ? (clt.imp_month * 100) / data.Sante.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.Sante.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.Sante.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.Sante.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.Sante.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.Sante.mer_total_today ? (clt.mer_today * 100) / data.Sante.mer_total_today : 0
          clt.taux_emprt_today = data.Sante.imp_total_today ? (clt.imp_today * 100) / data.Sante.imp_total_today : 0

        });
        this.TTLwsCls_sante = data.Sante.clients
      },
      fetchPolicy: 'network-only'
    },
    Animaux: {
      client: 'MARKET',
      query: gql `
        query {
          Animaux:getResponseStatisticsMyGustaveAnimalsClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.Animaux.clients.forEach(clt => {
         // calculate MER & % emprunt Month
          clt.taux_mer_month = data.Animaux.mer_total_month ? (clt.mer_month * 100) / data.Animaux.mer_total_month : 0
          clt.taux_emprt_month = data.Animaux.imp_total_month ? (clt.imp_month * 100) / data.Animaux.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.Animaux.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.Animaux.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.Animaux.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.Animaux.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.Animaux.mer_total_today ? (clt.mer_today * 100) / data.Animaux.mer_total_today : 0
          clt.taux_emprt_today = data.Animaux.imp_total_today ? (clt.imp_today * 100) / data.Animaux.imp_total_today : 0
        });

        this.TTLwsCls_animaux = data.Animaux.clients
      },
      fetchPolicy: 'network-only'
    },
    Mrh: {
      client: 'MARKET',
      query: gql `
        query {
          Mrh:getResponseStatisticsMyGustaveMRHClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.Mrh.clients.forEach(clt => {
          // calculate MER & % emprunt Month
          clt.taux_mer_month = data.Mrh.mer_total_month ? (clt.mer_month * 100) / data.Mrh.mer_total_month : 0
          clt.taux_emprt_month = data.Mrh.imp_total_month ? (clt.imp_month * 100) / data.Mrh.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.Mrh.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.Mrh.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.Mrh.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.Mrh.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.Mrh.mer_total_today ? (clt.mer_today * 100) / data.Mrh.mer_total_today : 0
          clt.taux_emprt_today = data.Mrh.imp_total_today ? (clt.imp_today * 100) / data.Mrh.imp_total_today : 0
        });

        this.TTLwsCls_mrh = data.Mrh.clients
      },
      fetchPolicy: 'network-only'
    },
    Emprunteur: {
      client: 'MARKET',
      query: gql `
        query {
          Emprunteur:getResponseStatisticsMyGustaveEmprunteurClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.Emprunteur.clients.forEach(clt => {
          // calculate MER & % emprunt Month
          clt.taux_mer_month = data.Emprunteur.mer_total_month ? (clt.mer_month * 100) / data.Emprunteur.mer_total_month : 0
          clt.taux_emprt_month = data.Emprunteur.imp_total_month ? (clt.imp_month * 100) / data.Emprunteur.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.Emprunteur.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.Emprunteur.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.Emprunteur.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.Emprunteur.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.Emprunteur.mer_total_today ? (clt.mer_today * 100) / data.Emprunteur.mer_total_today : 0
          clt.taux_emprt_today = data.Emprunteur.imp_total_today ? (clt.imp_today * 100) / data.Emprunteur.imp_total_today : 0
        });

        this.TTLwsCls_emprunteur = data.Emprunteur.clients
      },
      fetchPolicy: 'network-only'
    },
    Auto: {
      client: 'MARKET',
      query: gql `
        query {
          Auto:getResponseStatisticsMyGustaveAUTOClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.Auto.clients.forEach(clt => {
          // calculate MER & % emprunt Month
          clt.taux_mer_month = data.Auto.mer_total_month ? (clt.mer_month * 100) / data.Auto.mer_total_month : 0
          clt.taux_emprt_month = data.Auto.imp_total_month ? (clt.imp_month * 100) / data.Auto.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.Auto.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.Auto.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.Auto.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.Auto.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.Auto.mer_total_today ? (clt.mer_today * 100) / data.Auto.mer_total_today : 0
          clt.taux_emprt_today = data.Auto.imp_total_today ? (clt.imp_today * 100) / data.Auto.imp_total_today : 0
        });

        this.TTLwsCls_auto = data.Auto.clients
      },
      fetchPolicy: 'network-only'
    },
    CreditConso: {
      client: 'MARKET',
      query: gql `
        query {
          CreditConso:getResponseStatisticsMyGustaveCreditConsoClients{
            imp_total_month
            imp_total_today
            imp_total_yesterday
            mer_total_month
            mer_total_today
            mer_total_yesterday
            clients{
              id_client
              name_client
              produit
              response_time_month
              response_time_today
              response_time_yesterday
              imp_month
              imp_today
              imp_yesterday
              mer_month
              mer_today
              mer_yesterday
              state
            }
          }
        }
      `,
      result({ data, loader, networkStatus }) {
        data.CreditConso.clients.forEach(clt => {
          // calculate MER & % emprunt Month
          clt.taux_mer_month = data.CreditConso.mer_total_month ? (clt.mer_month * 100) / data.CreditConso.mer_total_month : 0
          clt.taux_emprt_month = data.CreditConso.imp_total_month ? (clt.imp_month * 100) / data.CreditConso.imp_total_month : 0

          // calculate MER & % emprunt Yesterday
          clt.taux_mer_yesterday = data.CreditConso.mer_total_yesterday ? (clt.mer_yesterday * 100) / data.CreditConso.mer_total_yesterday : 0
          clt.taux_emprt_yesterday = data.CreditConso.imp_total_yesterday ? (clt.imp_yesterday * 100) / data.CreditConso.imp_total_yesterday : 0

          // calculate MER & % emprunt today
          clt.taux_mer_today = data.CreditConso.mer_total_today ? (clt.mer_today * 100) / data.CreditConso.mer_total_today : 0
          clt.taux_emprt_today = data.CreditConso.imp_total_today ? (clt.imp_today * 100) / data.CreditConso.imp_total_today : 0

        });
        this.TTLwsCls_creditconso = data.CreditConso.clients
      },
      fetchPolicy: 'network-only'
    },
  },
  data() {
    return {
      laoding_extract: false,
      TTLwsCls_sante: null,
      TTLwsCls_creditconso: null,
      TTLwsCls_animaux: null,
      TTLwsCls_mrh: null,
      TTLwsCls_emprunteur: null,
      TTLwsCls_auto: null,
      PeriodeSelects: {
        Period: 'd',
        ListPeriod: [
          { value: "d-1" , label: "HIER"},
          { value: "d" , label: "AUJOURD'HUI"},	
          { value: "m" , label: "MOIS EN COURS"},
        ]
      },
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value :value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    Color_TimeWS(time, state) {
      if (state == 0) {
        return 'color: #747474';
      }

      if (time > 5) {
        return 'color: red';
      } else if (time > 2 && time <= 5) {
        return 'color: orange';
      } else {
        return 'color: green';
      }
    },
    Color_TauxEmprt(taux, min, max, state) {
      if (state == 0) {
        return 'color: #747474';
      }

      if (taux < min) {
        return 'color: red';
      } else if (taux > min && taux < max) {
        return 'color: orange';
      } else {
        return 'color: green';
      }
    },
    Color_TauxMer(taux, min, max, state) {
      if (state == 0) {
        return 'color: #747474';
      }

      if (taux < min) {
        return 'color: red';
      } else if (taux > min && taux < max) {
        return 'color: orange';
      } else {
        return 'color: green';
      }
    },
    async ExtractCsv_Tarif(period, id_client, id_produit, client) {
      this.laoding_extract = true

      await this.$apollo.query({
          client: 'MARKET',
          query: gql `
            query ($dateType: String!, $id_client: Int!, $id_produit: Int!) {
              getMyGustaveClientLogs(
                dateType: $dateType,
                id_client: $id_client,
                id_produit: $id_produit
              ){
                id_client
                response
                created_at
              }
            }
          `,
          variables: {
            dateType: period,
            id_client: id_client,
            id_produit: id_produit
          },
          fetchPolicy: 'network-only'
        }).then(data => {
          this.JsonToCsv_Tarif(data.data.getMyGustaveClientLogs, client);
        }).catch(error => {
          console.log(error)
        })

      this.laoding_extract = false

    },
    async ExtractCsv_Mer(period, id_client, id_produit, client) {
      this.laoding_extract = true

      await this.$apollo.query({
          client: 'MARKET',
          query: gql `
            query ($dateType: String!, $id_client: Int!, $id_produit: Int!) {
              getMyGustaveMERLogs(
                dateType: $dateType,
                id_client: $id_client,
                id_produit: $id_produit
              ){
                id_client
                id_lead
                id_action
                response
                created_at
              }
            }
          `,
          variables: {
            dateType: period,
            id_client: id_client,
            id_produit: id_produit
          },
          fetchPolicy: 'network-only'
        }).then(data => {
          this.JsonToCsv_Mer(data.data.getMyGustaveMERLogs, client);
        }).catch(error => {
          console.log(error)
        })

      this.laoding_extract = false
    },
    JsonToCsv_Tarif(json, client) {

      let data = JSON.parse(JSON.stringify(json));

      data.forEach(element => {

        delete element.__typename;
        delete element.id_client;

        let response = JSON.parse(element.response)

        element.data = JSON.stringify(response.data)
        element.retour_client = JSON.stringify(response["retour-client"])

        delete element.response;

      })

      const fileName = `${client}.csv`

      var Stats = XLSX.utils.json_to_sheet(data) 
      Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

      Stats = Stats.replace("created_at","date")
      Stats = Stats.replace("data","data envoye")
      Stats = Stats.replace("retour_client","retour client")

      var blob = new Blob(["\ufeff", Stats]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },
    JsonToCsv_Mer(json, client) {

      let data = JSON.parse(JSON.stringify(json));

      data.forEach(element => {

        delete element.__typename;
        delete element.id_client;
        delete element.id_lead;

        let response = JSON.parse(element.response)

        if (element.id_action == 1) {
          element.id_action = "RAPPEL"
        } else if (element.id_action == 2) {
          element.id_action = "DEVIS"
        } else if (element.id_action == 3) {
          element.id_action = "SOUSCRIRE"
        } else if (element.id_action == 5) {
          element.id_action = "AGENDA"
        } 
        
        element.data = JSON.stringify(response.data)
        element.retour = JSON.stringify(response.retour)
        delete element.response;

      })

      const fileName = `${client}.csv`

      var Stats = XLSX.utils.json_to_sheet(data) 
      Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

      Stats = Stats.replace("created_at","date")
      Stats = Stats.replace("data","data envoye")
      Stats = Stats.replace("retour","retour client")
      Stats = Stats.replace("id_action","action")

      var blob = new Blob(["\ufeff", Stats]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
}
</script>

<style>
  .customtable_cmd .cell{
    word-break: break-word;
  }
</style>