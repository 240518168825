<template>
    <div class="content">
        <h3>{{ $t("titlePages.phoneNumberValidation") }}</h3>
        <card>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <vue-phone-number-input
                    name="mobile"
                    valid-color="#54347C"
                    :no-example="true"
                    :dark="true"
                    :border-radius="8"
                    dark-color="#000"
                    error-color="#ec250d"
                    :only-countries="this.countrysCode"
                    :translations="{
                        countrySelectorLabel: $t('inputs.CountryCode'),
                        phoneNumberLabel: $t('inputs.PhoneNumber'),
                    }"
                    color="#988fc3"
                    :error="showErrorMobile"
                    v-validate="'required'"
                    v-model="model.mobile"
                    @update="onUpdate"
                    >
                    </vue-phone-number-input>
                    <label class="error" style="color:#ec250d" v-show="showErrorMobile">{{ errorMessage }}</label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 text-center">
                    <base-button
                        v-if="isLoading"
                        loading
                        class="btn btn-just-icon btn-default d-block"
                    >
                    <!-- {{ $t("buttons.BtnLoad").toUpperCase() }}... -->
                    Recherche en cours
                    </base-button>
                    <button
                        v-else
                        class="btn btn-just-icon btn-default d-block"
                        @click="checkMobile"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                </div>
            </div>
        </card>
        <h3>{{ $t("titlePages.DetailsAboutNumber") }}</h3>
            <card class="fixed-height">
            <!-- <div class="row justify-content-center"> -->
                <div class="row" >
                    <div class="col-12">
                        <base-alert type="default" dismissible v-if="erreur" >
                            <span>
                                <template > {{ erreur }} </template>
                            </span>
                        </base-alert>
                    </div>
                </div>

                <div class="row" v-if="isSuccess">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td scope="row">Numéro</td>
                                <td>{{ result.formatted }}</td>
                            </tr>
                            <tr>
                                <td scope="row">Indicatif</td>
                                <td>{{ result.dialing_code }}</td>
                            </tr>
                            <tr>
                                <td scope="row">Forme locale</td>
                                <td>{{ result.local_format }}</td>
                            </tr>
                            <tr>
                                <td scope="row">Nom</td>
                                <td v-if="result.carrier && result.carrier != 'N/A'">{{ result.carrier }}</td>
                                <td v-else>inconnu</td>
                            </tr>
                            <tr>
                                <td scope="row">Ville</td>
                                <td v-if="result.city && result.city != 'N/A'">{{ result.city }}</td>
                                <td v-else>non disponible</td>
                            </tr>
                            <tr>
                                <td scope="row">Pays</td>
                                <td v-if="result.region && result.region != 'N/A'">{{ result.region }}</td>
                                <td v-else>non disponible</td>
                            </tr>
                            <tr>
                                <td scope="row">Type de ligne</td>
                                <td v-if="result.line_type && result.line_type != 'N/A'">{{ result.line_type }}</td>
                                <td v-else>non disponible</td>
                            </tr>
                            <tr>
                                <td scope="row">Degré de Risque</td>
                                <td class="danger" v-if="result.fraud_score>=90" >
                                    <b> Élevé  ({{ result.fraud_score }}%)</b>
                                </td>
                                <td class="danger" v-else-if="result.fraud_score>=85">
                                    <b> Risqué ({{ result.fraud_score }}%)</b>
                                </td>
                                <td class="warning" v-else-if="result.fraud_score>=50">
                                    <b> Suspect({{ result.fraud_score }}%)</b>
                                </td>
                                <td v-else>
                                    <b> Faible ({{ result.fraud_score }}%)</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </card>
    </div>


</template>

<script>

import { BaseAlert } from 'src/components'

export default {
    components: {
        BaseAlert
    },
    data() {
        return {
        countrysCode: ['FR', 'MA', 'IT', 'US', 'SE', 'DK', 'DE', 'NL', 'CH', 'LU', 'GB', 'BR', 'NO', 'AE', 'BE', 'PT', 'FI', 'ZA', 'ES', 'AU', 'PL', 'NZ','HK', 'MT', 'MG','MU', 'TN', 'DZ', 'IE', 'SC','AL','UA' ,'UY', 'EE', 'IL'],
        model: {
            mobile: null,
        },
        isSuccess: false,
        isLoading: false,
        showErrorMobile:false,
        errorMessage: '',
        formattedNumber: '',
        result:{},
        erreur:'',
        apiKey:'MV0xM1Bi7fsh8zcT5F4facZuqN6c5GPU',
        }
     },
    methods: {
        onUpdate (payload) {
            this.isValid = payload.isValid
            this.formattedNumber = payload.formattedNumber
        },
        search() {
            this.isLoading = true
            this.isSuccess = false
            this.erreur = ''
            let accessToken = localStorage.getItem("token_jwt")
            let formData = new FormData()
            formData.append('phone', this.formattedNumber)
            this.axios
           // .post("http://127.0.0.1:8000/api/validation_numeros_telephones",formData,{
            .post("https://apileadmarket.wee-do-it.net/api/validation_numeros_telephones",formData,{
                /* headers: {
                'authorization': `Bearer ${accessToken}`
                } */
                headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },

            })
            .then(response => {
                this.isLoading = false
                this.result = response.data
                if(response.data.success === true){
                    this.result = response.data
                    this.isSuccess = true;
                }else{
                    this.erreur = response.data.message
                }
            })
            .catch(error => {
                console.log(error)
                this.isLoading = false
                this.erreur = 'Une erreur est survenue. Veuillez réessayer plus tard.'
            });
        },
        checkMobile(){
            if(this.errors.items.length > 0){
                this.showErrorMobile = true
                this.errorMessage = this.errors.first('mobile');
            }else{
                if(!this.isValid){
                    this.showErrorMobile = true
                    this.errorMessage = 'Numéro invalid';
                }else{
                    this.showErrorMobile = false
                    this.search()
                }
            }
        },
    }
};
</script>
<style>
    .el-table thead.is-group th {
        background: transparent;
    }
    .input-phone-number label.input-tel__label, input#VuePhoneNumberInput_phone_number{
        cursor: auto;
    }
    #VuePhoneNumberInput_country_selector, .country-selector__toggle__arrow{
        cursor: default;
    }
    .custom-border-color {
        border-color: #ffffff1a !important;
    }
    .table tbody tr:first-child th, .table tbody tr:first-child td {
        border-top: none;
    }
    .card .card-body {
        padding: 30px;
    }
    .container {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

  .fixed-height {
    flex-grow: 1; /* Make the card expand to fill the remaining space */
    min-height: calc(100vh - 50vh);
  }

  .table  td.danger {
    color: red  !important;
  }
  .table  td.warning {
    color: #f0ad4e   !important;
  }
</style>



